import { gql } from '../../__generated__/gql';

export const GET_COLLECTION_FILTER = gql(`
  query getCollectionFilter ($handle: String!, $filters: [ProductFilter!]!, $first: Int, $last: Int, $after: String, $before: String, $sortKey: ProductCollectionSortKeys) {
    collection (handle: $handle) {
      id
      handle
      title
      description
      descriptionHtml
      image {
        url
        width
        height
        altText
      }
      products (filters: $filters, first: $first, last: $last, after: $after, before: $before, sortKey: $sortKey) {
        edges {
          cursor
          node {
            id
            handle
            title
            vendor
            availableForSale
            tags
            productType
            images (first: 6) {
              edges {
                node {
                  id
                  url
                  width
                  height
                  altText
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            variants (first: 6) {
              edges {
                node {
                  id
                  title
                  quantityAvailable
                  availableForSale
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          startCursor
          hasPreviousPage
          endCursor
        }
      }
    }
  }
`);
