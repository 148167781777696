import React from 'react';

export default function AccountMyDetailsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.875 15.2372C10.9375 15.0684 10.9375 14.8059 10.9375 14.6184V14.3372C11.1566 14.171 11.3302 13.9701 11.4472 13.7474C11.5642 13.5247 11.6221 13.285 11.6171 13.0434V12.1997C11.6141 12.0322 11.5698 11.8669 11.4869 11.7132C11.404 11.5594 11.284 11.4202 11.1338 11.3036C10.9837 11.1869 10.8062 11.0951 10.6117 11.0333C10.4171 10.9715 10.2093 10.9409 9.99996 10.9434C9.78871 10.9384 9.57833 10.9671 9.38107 11.0278C9.1838 11.0884 9.00359 11.1799 8.85091 11.2968C8.69824 11.4137 8.57615 11.5537 8.49176 11.7087C8.40736 11.8637 8.36235 12.0306 8.35933 12.1997V13.0434C8.35741 13.2864 8.41897 13.5269 8.54005 13.7497C8.66113 13.9725 8.83907 14.1726 9.06246 14.3372V14.6184C9.06246 14.7872 9.06246 15.0684 8.07808 15.2372C7.09371 15.4059 7.18746 16.1934 7.18746 16.1934H12.8125C12.819 15.9718 12.729 15.7553 12.558 15.5808C12.387 15.4064 12.1456 15.2849 11.875 15.2372Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2812 8.31836H19.375V17.6934H5.3125V8.31836H11.4062"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4062 7.19336H13.2812V9.44336H11.4062V7.19336Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 10.9434H17.5M13.75 12.4434H16.0938M13.75 13.9434H17.0312"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
