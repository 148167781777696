import React from 'react';

function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.1128 15.6054L16.6963 13.6264L16.6841 13.6207C16.4548 13.5227 16.2047 13.4833 15.9564 13.5062C15.708 13.5292 15.4693 13.6136 15.2619 13.752C15.2375 13.7681 15.214 13.7857 15.1916 13.8045L12.9097 15.7498C11.4641 15.0476 9.97157 13.5664 9.26938 12.1395L11.2175 9.82294C11.2363 9.7995 11.2541 9.77606 11.2709 9.75075C11.4063 9.54384 11.4885 9.30667 11.5101 9.06035C11.5317 8.81402 11.492 8.56618 11.3947 8.33887V8.32762L9.41001 3.90356C9.28133 3.60662 9.06006 3.35926 8.77925 3.19841C8.49843 3.03756 8.17312 2.97184 7.85188 3.01106C6.58154 3.17822 5.41549 3.80209 4.57151 4.76615C3.72753 5.73021 3.26334 6.96852 3.26563 8.24981C3.26563 15.6936 9.32188 21.7498 16.7656 21.7498C18.0469 21.7521 19.2852 21.2879 20.2493 20.4439C21.2134 19.6 21.8372 18.4339 22.0044 17.1636C22.0437 16.8424 21.9781 16.5172 21.8174 16.2364C21.6567 15.9556 21.4096 15.7343 21.1128 15.6054ZM16.7656 20.2498C13.5841 20.2463 10.5339 18.9809 8.28419 16.7313C6.03451 14.4816 4.76911 11.4313 4.76563 8.24981C4.76211 7.33433 5.09193 6.44887 5.69351 5.75879C6.29509 5.0687 7.12729 4.62118 8.0347 4.49981C8.03433 4.50355 8.03433 4.50732 8.0347 4.51106L10.0034 8.91731L8.06563 11.2367C8.04596 11.2593 8.0281 11.2835 8.0122 11.3089C7.87112 11.5254 7.78835 11.7746 7.77193 12.0325C7.7555 12.2904 7.80597 12.5481 7.91845 12.7807C8.76782 14.5179 10.5181 16.2551 12.2741 17.1036C12.5084 17.215 12.7677 17.2637 13.0265 17.245C13.2853 17.2262 13.5348 17.1407 13.7506 16.9967C13.7747 16.9805 13.7979 16.9629 13.82 16.9442L16.0991 14.9998L20.5053 16.9732C20.5053 16.9732 20.5128 16.9732 20.5156 16.9732C20.3957 17.8819 19.9489 18.7158 19.2587 19.3189C18.5685 19.922 17.6822 20.2529 16.7656 20.2498Z"
        fill="black"
      />
    </svg>
  );
}

export default PhoneIcon;
