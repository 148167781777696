import { gql } from '../../__generated__/gql';

export const GET_LOCALIZATIONS = gql(`
  query getLocalizations @inContext(language: EN) {
    localization {
      availableCountries {
        isoCode
        name
        currency {
          isoCode
          name
          symbol
        }
      }
    }
  }
`);
