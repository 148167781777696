import { useCallback, useState } from 'react';
import { CartInput } from '@repo/client-shopify/src/gql/storefront/__generated__/graphql';
import {
  CartBuyerIdentityUpdateMutationVariables,
  CartLineInput,
  CartLineUpdateInput,
} from '@client-shopify/gql/storefront/__generated__/graphql';
import {
  CartCreate,
  CartLinesAdd,
  CartLinesRemove,
  CartLinesUpdate,
  CartBuyerIdentityUpdate,
} from '@client-shopify/gql/storefront/api/mutations';

type CartBuyerIdentityUpdateArgs = CartBuyerIdentityUpdateMutationVariables & {
  buyerIP: string | undefined;
};

export function useCartCreation() {
  const [data, setData] = useState<Awaited<ReturnType<typeof CartCreate>>['cart']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const cartCreateMutation = useCallback(async ({ cartCreateInput }: { cartCreateInput: CartInput }) => {
    setIsLoading(true);
    setError(null);
    try {
      const { cart, error } = await CartCreate({
        buyerIP: '',
        cartCreateInput,
      });

      if (error) {
        throw new Error(String(error));
      }
      if (!cart) {
        throw new Error('Cart not found');
      }
      setData(cart);
      return { cart };
    } catch (err: unknown) {
      setError(err as Error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    cartCreateMutation,
    cartCreateData: data,
    cartCreateLoading: isLoading,
    cartCreateError: error,
  };
}

export function useCartAdd() {
  const [data, setData] = useState<Awaited<ReturnType<typeof CartLinesAdd>>['cart']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const cartLinesAddMutation = useCallback(
    async ({ cartId, cartLinesAddInput }: { cartId: string; cartLinesAddInput: CartLineInput[] }) => {
      setIsLoading(true);
      setError(null);
      try {
        const { cart, error } = await CartLinesAdd({
          cartId,
          cartLinesAddInput,
        });

        if (error) {
          throw new Error('Failed to fetch');
        }

        if (!cart) {
          throw new Error('Cart not found');
        }
        setData(cart);
        return { cart };
      } catch (err: unknown) {
        setError(err as Error);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    cartLinesAddMutation,
    cartAddData: data,
    cartAddLoading: isLoading,
    cartAddError: error,
  };
}

export function useCartUpdate() {
  const [data, setData] = useState<Awaited<ReturnType<typeof CartLinesUpdate>>['cart']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const cartLinesUpdateMutation = useCallback(
    async ({
      cartId,
      cartUpdateInput,
    }: {
      cartId: string;
      cartUpdateInput: Array<CartLineUpdateInput> | CartLineUpdateInput;
    }) => {
      setIsLoading(true);
      setError(null);
      try {
        const { cart, error } = await CartLinesUpdate({
          cartId,
          cartLinesUpdateInput: cartUpdateInput,
        });

        if (error) {
          throw new Error(String(error));
        }

        if (!cart) {
          throw new Error('Cart not found');
        }
        setData(cart);
        return { cart };
      } catch (err: unknown) {
        setError(err as Error);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    cartLinesUpdateMutation,
    cartLineUpdateData: data,
    cartLineUpdateLoading: isLoading,
    cartLineUpdateError: error,
  };
}

export function useCartRemove() {
  const [data, setData] = useState<Awaited<ReturnType<typeof CartLinesRemove>>['cart']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const cartLinesRemoveMutation = useCallback(
    async ({ cartId, cartLineIds }: { cartId: string; cartLineIds: string[] }) => {
      setIsLoading(true);
      setError(null);
      try {
        const { cart, error } = await CartLinesRemove({
          cartId,
          cartLineIds,
        });

        if (error) {
          throw new Error(String(error));
        }

        if (!cart) {
          throw new Error('Cart not found');
        }
        setData(cart);
        return { cart };
      } catch (err: unknown) {
        setError(err as Error);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    cartLinesRemoveMutation,
    cartRemoveData: data,
    cartRemoveLoading: isLoading,
    cartRemoveError: error,
  };
}

export function useCartBuyerUpdate() {
  const [data, setData] = useState<Awaited<ReturnType<typeof CartBuyerIdentityUpdate>>['cart']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const CartBuyerIdentityUpdateMutation = useCallback(
    async ({ cartId, buyerIdentity, buyerIP }: CartBuyerIdentityUpdateArgs) => {
      if (
        buyerIdentity.deliveryAddressPreferences?.[0]?.deliveryAddress &&
        !buyerIdentity.deliveryAddressPreferences?.[0].deliveryAddress.address1
      )
        buyerIdentity.deliveryAddressPreferences = [];
      setIsLoading(true);
      setError(null);
      try {
        const { cart, error } = await CartBuyerIdentityUpdate({
          buyerIP,
          cartId,
          buyerIdentity,
        });

        if (error) {
          throw new Error(String(error));
        }

        if (!cart) {
          throw new Error('Cart not found');
        }
        setData(cart);
        return { cart };
      } catch (err: unknown) {
        setError(err as Error);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    CartBuyerIdentityUpdateMutation,
    cartBuyerUpdateData: data,
    cartBuyerUpdateLoading: isLoading,
    cartBuyerUpdateError: error,
  };
}
