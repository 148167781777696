import React from 'react';

export default function AccountWishlistIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.562 9.13481C20.5622 9.79254 20.5624 11.2822 19.5763 13.1762C18.3557 15.5212 16.0818 17.6322 12.8124 19.4586C9.54303 17.6322 7.26929 15.5208 6.04849 13.1762C5.06263 11.2818 5.06281 9.79236 5.06299 9.15623C5.06299 6.81521 6.94224 4.92822 9.25224 4.92822C10.2459 4.92822 11.2091 5.27967 11.9643 5.91779L12.8122 6.63382L13.6601 5.91779C14.4157 5.27949 15.3789 4.92822 16.3729 4.92822C18.6828 4.92822 20.562 6.81521 20.562 9.13481Z"
        stroke="black"
        strokeWidth="0.8"
      />
    </svg>
  );
}
