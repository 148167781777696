import React from 'react';

type IconProps = {
  className?: string;
  height?: string;
  width?: string;
  viewBox?: string;
  fill?: string;
};

function WishList({ className, height, width, viewBox, fill }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMid meet"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8354 10.0923C20.8356 10.7713 20.8358 12.3091 19.8179 14.2643C18.5578 16.6851 16.2104 18.8644 12.8354 20.7498C9.4603 18.8644 7.11307 16.6847 5.8528 14.2643C4.83508 12.3087 4.83527 10.7711 4.83545 10.1144C4.83545 7.69774 6.77545 5.74976 9.16011 5.74976C10.1859 5.74976 11.1802 6.11256 11.9599 6.77131L12.8352 7.51048L13.7105 6.77131C14.4905 6.11238 15.4848 5.74976 16.511 5.74976C18.8955 5.74976 20.8354 7.69774 20.8354 10.0923Z"
        stroke="black"
      />
    </svg>
  );
}

export default WishList;

WishList.defaultProps = {
  viewBox: '0 0 25 25',
  className: '',
  height: 28,
  width: 28,
  fill: 'none',
};
