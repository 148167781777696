import { MultipassRouteData } from '@ui/types/apiRoutes/multipass';

export const fetchMultipassToken = async ({
  userToken,
  checkoutUrl,
}: {
  userToken: string;
  checkoutUrl: string;
}): Promise<MultipassRouteData | null> => {
  try {
    const response = await fetch('/api/multipass', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userToken, checkoutUrl }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};
