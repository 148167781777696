import React from 'react';
import { Accordion, Grid, Group, Stack, Prose } from '@ui/components/core';
import { deliverySuburb1, deliverySuburb2, deliverySuburb3 } from './FAQSydneySameDayDeliveryDictionary';
import FAQAccordion from '../FAQAccordion/FAQAccordion';

const FAQSydneySameDayDelivery = (): React.ReactElement => (
  <FAQAccordion handle="sydney-same-day-delivery" value="delivery-options">
    <Accordion.Item value="delivery-options">
      <Accordion.Control>What are same-day delivery options in Sydney</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            We offer evening (6pm-10pm) delivery to select postcodes in the metro Sydney area Monday to Friday
            (excluding public holidays). See below for a full list of suburbs where you can access this service. If you
            are eligible it will appear as a shipping option at check out once you put in your address details.
          </p>
          <p>Please call us on 02 9017 0300 if you are experiencing technical difficulties.</p>
          <Grid gutter="xl">
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb1.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb2.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} md={4} lg={4} xl={4}>
              <Stack spacing="1rem">
                {deliverySuburb3.map((deliverySuburb) => (
                  <Group key={`${deliverySuburb.suburb}-delivery-suburb-group`} position="apart">
                    <p>{deliverySuburb.suburb}</p>
                    <p>{deliverySuburb.postcode}</p>
                  </Group>
                ))}
              </Stack>
            </Grid.Col>
          </Grid>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
  </FAQAccordion>
);

export default FAQSydneySameDayDelivery;
