import {
  FaqDeliveryIcon,
  FaqExpressDeliveryIcon,
  FaqOrderIssuesIcon,
  FaqPaymentsIcon,
  FaqPopularQuestionIcon,
  FaqProductsIcon,
  FaqReturnsIcon,
  FaqTechnicalIcon,
} from '@ui/components/core';
import {
  FAQDelivery,
  FAQOrderIssues,
  FAQPaymentsAndPromos,
  FAQPopularQuestions,
  FAQProductAndStock,
  FAQReturnsAndRefunds,
  FAQSydneySameDayDelivery,
  FAQTechnical,
} from '@ui/components/features';

export const FaqMenuMappings = [
  {
    name: 'POPULAR QUESTIONS',
    handle: 'popular-questions',
    icon: FaqPopularQuestionIcon,
    content: FAQPopularQuestions,
  },
  {
    name: 'DELIVERY',
    handle: 'delivery',
    icon: FaqDeliveryIcon,
    content: FAQDelivery,
  },
  {
    name: 'SYDNEY - SAME DAY DELIVERY',
    handle: 'sydney-same-day-delivery',
    icon: FaqExpressDeliveryIcon,
    content: FAQSydneySameDayDelivery,
  },
  {
    name: 'ORDER ISSUES',
    handle: 'order-issues',
    icon: FaqOrderIssuesIcon,
    content: FAQOrderIssues,
  },
  {
    name: 'PAYMENTS & PROMOS',
    handle: 'payments-and-promos',
    icon: FaqPaymentsIcon,
    content: FAQPaymentsAndPromos,
  },
  {
    name: 'PRODUCT & STOCK',
    handle: 'product-and-stock',
    icon: FaqProductsIcon,
    content: FAQProductAndStock,
  },
  {
    name: 'RETURNS & REFUNDS',
    handle: 'returns-and-refunds',
    icon: FaqReturnsIcon,
    content: FAQReturnsAndRefunds,
  },
  {
    name: 'TECHNICAL',
    handle: 'technical',
    icon: FaqTechnicalIcon,
    content: FAQTechnical,
  },
];
