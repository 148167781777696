import { Wishlist, WishlistFrontEnd, WishlistProduct } from '@ui/types/contextObjects';

export type MapWishlistDataReturnType = Promise<WishlistFrontEnd>;

export const mapWishlistData = (wishlistData: Wishlist): WishlistFrontEnd => {
  const wishlistKingData = {
    id: wishlistData.id,
    permaId: wishlistData.permaId,
    products: [] as {
      productId: number;
      variantId: number;
      wishlistItemId: string;
    }[],
  };

  const wishlist = Object.fromEntries(
    wishlistData.products.map((product) => {
      const currentVariant =
        product.variants.length > 0
          ? product.variants.find((variant) => variant.id === product.selected_variant_id)
          : null;

      let msrp = (
        currentVariant
          ? currentVariant.compare_at_price
            ? currentVariant.compare_at_price
            : currentVariant.price
          : product.compare_at_price ||
            product.compare_at_price_min ||
            product.compare_at_price_max ||
            product.price_min
      ).toString();

      let price = (currentVariant ? currentVariant.price : product.price_min).toString();

      msrp = !msrp.includes('.') ? msrp.concat('.00') : msrp;
      price = !price.includes('.') ? price.concat('.00') : price;

      const variantSizes = product.variants.map((variant) => {
        return {
          id: variant.id.toString(),
          available: variant.available,
          sku: variant.sku,
          size: variant.option1,
        };
      });

      wishlistKingData.products.push({
        productId: product.id,
        variantId: product.selected_variant_id,
        wishlistItemId: product.wishlist_item_id,
      });

      return [
        [product.handle],
        {
          handle: product.handle,
          id: product.id,
          title: product.title,
          msrp,
          price,
          brand: product.vendor,
          tags: product.tags,
          imageSrc: product.images[0].src,
          imageAlt: product.images[0].alt || 'Hello Molly',
          images: product.images.map((img) => img.src),
          variantSizes,
        },
      ];
    }),
  );

  return { products: wishlist, wishlistKingData };
};

const wishlistProducts = (wishlistData: Wishlist) =>
  wishlistData.products.map((product) => {
    const currentVariant =
      product.variants.length > 0
        ? product.variants.find((variant) => variant.id === product.selected_variant_id)
        : null;

    let msrp = (
      currentVariant
        ? currentVariant.compare_at_price
          ? currentVariant.compare_at_price
          : currentVariant.price
        : product.compare_at_price || product.compare_at_price_min || product.compare_at_price_max || product.price_min
    ).toString();

    let price = (currentVariant ? currentVariant.price : product.price_min).toString();

    msrp = !msrp.includes('.') ? msrp.concat('.00') : msrp;
    price = !price.includes('.') ? price.concat('.00') : price;

    const variantSizes = product.variants.map((variant) => {
      return {
        id: variant.id.toString(),
        available: variant.available,
        sku: variant.sku,
        size: variant.option1,
      };
    });

    return {
      handle: product.handle,
      id: product.id,
      title: product.title,
      msrp,
      price,
      brand: product.vendor,
      tags: product.tags,
      imageSrc: product.images[0].src,
      imageAlt: product.images[0].alt || 'Hello Molly',
      images: product.images.map((img) => img.src),
      variantSizes,
    };
  });

const wishlistHashmap = (
  wishlstProducts: ReturnType<typeof wishlistProducts>,
  newProductObject: Record<string, WishlistProduct>,
  index: number,
): Record<string, WishlistProduct> => {
  if (index === wishlstProducts.length) return newProductObject;
  const product = wishlstProducts[index];
  newProductObject[product.handle] = product;
  return wishlistHashmap(wishlstProducts, newProductObject, index + 1);
};

export const wishlistData = (wishlistData: Wishlist) => ({
  products: wishlistHashmap(wishlistProducts(wishlistData), {}, 0),
  wishlistKingData: {
    id: wishlistData.id,
    permaId: wishlistData.permaId,
    products: wishlistData.products.map((product) => ({
      productId: product.id,
      variantId: product.selected_variant_id,
      wishlistItemId: product.wishlist_item_id,
    })),
  },
});
