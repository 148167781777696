export const clothingSizes = [
  ['SIZE (NZ/AU)', 'BUST (CM)', 'WAIST (CM)', 'HIPS (CM)'],
  ['XXS (NZ/AU4)', '76', '58', '86'],
  ['XS (NZ/AU6)', '81', '63', '91'],
  ['S (NZ/AU8)', '86', '68', '96'],
  ['M (NZ/AU10)', '91', '73', '101'],
  ['L (NZ/AU12)', '96', '78', '106'],
  ['XL (NZ/AU14)', '101', '83', '111'],
  ['XXL (NZ/AU16)', '106', '88', '116'],
];

export const sizeConversions = [
  ['SIZE', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
  ['NZ/AU', '4', '6', '8', '10', '12', '14', '16'],
  ['US', '0', '2', '4', '6', '8', '10', '12'],
  ['UK', '4', '6', '8', '10', '12', '14', '16'],
  ['EU', '32', '34', '36', '38', '40', '42', '44'],
];

export const shoeSizes = [
  ['NZ/AU', '4', '5', '6', '7', '8', '9', '10', '11'],
  ['US', '4', '5', '6', '7', '8', '9', '10', '11'],
  ['UK', '2', '3', '4', '5', '6', '7', '8', '9'],
  ['EU', '35', '36', '37', '38', '39', '40', '41', '42'],
];
