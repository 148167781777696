import Skeleton from '../../skeleton/Skeleton';

export default function CartUpsellCardSkeleton() {
  return (
    <div className="flex space-x-4">
      <div className="shrink-0">
        <Skeleton className="h-[117px] w-[77px]" />
      </div>
      <div className="w-full flex flex-col justify-between space-y-1">
        <div className="space-y-2">
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-2/4" />
        </div>
        <div className="flex items-center space-x-3">
          <Skeleton className="size-8 rounded-lg" />
          <Skeleton className="size-8 rounded-lg" />
          <Skeleton className="size-8 rounded-lg" />
        </div>
      </div>
    </div>
  );
}
