import React from 'react';
import Marquee from 'react-fast-marquee';
import { Container, Group } from '@ui/components/core';
import { BannerCurrencyDropdown, LocalCurrencyDropdown } from '@ui/components/features';
import Link from 'next/link';
import env from '@ui/env';
import Image from 'next/image';

const BannerBFCM = () => {
  return (
    <div
      className="border-b-[0.6px] border-b-black bg-[#fedfef] flex items-center relative h-[--banner-height] -z-20 "
      data-test="usp-banner"
    >
      <div className="md:block hidden absolute inset-0 -z-10">
        <Image
          src="https://cdn.shopify.com/s/files/1/0281/2071/1254/files/USP-Desktop.jpg?v=1733193822"
          alt="Desktop Background Image"
          width={1920}
          height={40}
          quality={100}
          className="h-full w-auto"
          priority
        />
      </div>
      <div className="md:hidden block absolute inset-0 -z-10">
        <Image
          src="https://cdn.shopify.com/s/files/1/0281/2071/1254/files/USP-Mobile.jpg?v=1733193827"
          alt="Mobile Background Image"
          width={375}
          height={40}
          quality={100}
          className="h-full w-auto"
          priority
        />
      </div>
      <Container className="px-4 pl-14 md:pr-8 md:pl-24 3xl:px-8">
        <div className="flex items-center space-x-2 md:space-x-4">
          <div className="flex-1 overflow-hidden">
            <Marquee>
              <Group spacing="4rem" mr="4rem">
                {Array.from(Array(2).keys()).map((iteration) => (
                  <React.Fragment key={iteration}>
                    <Link
                      href="/pages/competitions"
                      className="text-sm text-black tracking-[0.04em] hover:underline underline-offset-4"
                    >
                      Pick Your Holiday Vibe To WIN A Prize Of Your Choice!{' '}
                      <span className=" font-bold">ENTER NOW</span>
                    </Link>
                    <Link
                      href="/pages/shipping"
                      className="text-sm text-black tracking-[0.04em] hover:underline underline-offset-4"
                    >
                      Order In Time For The Holidays! View Shipping Cut-Off Dates
                    </Link>
                  </React.Fragment>
                ))}
              </Group>
            </Marquee>
          </div>
          {!env.MULTICURRENCY_FEATURE && <BannerCurrencyDropdown />}
          {env.MULTICURRENCY_FEATURE && <LocalCurrencyDropdown />}
        </div>
      </Container>
    </div>
  );
};

export default BannerBFCM;
