import cn from '@ui/utils/cn';
import { range } from 'lodash';
import React from 'react';

const MAX_BASE_DOTS = 3;

type Dot = 'base' | 'sm' | 'xs' | 'none';

type CarouselIndicatorsProps = {
  className?: string;
  activeSlide: number;
  totalSlides: number;
  direction?: 'horizontal' | 'vertical';
  onDotClick?: (slide: number) => void;
  classNames?: {
    root?: string;
    dot?: string;
  };
};

const CarouselIndicators: React.FC<CarouselIndicatorsProps> = ({
  className,
  activeSlide,
  totalSlides,
  direction = 'horizontal',
  classNames,
  onDotClick,
}) => {
  const slides = range(0, totalSlides);

  const [dots, setDots] = React.useState<Array<Dot>>(() => {
    return slides.map((_, i) => {
      if (slides.length <= MAX_BASE_DOTS + 2) return 'base';
      if (i <= 2) return 'base';
      if (i === 3) return 'sm';
      if (i === 4) return 'xs';
      return 'none';
    });
  });

  React.useEffect(() => {
    setDots((prevDots) => {
      if (prevDots.length <= MAX_BASE_DOTS + 2) return prevDots;

      let indexOfFirstBaseDot = prevDots.findIndex((dot) => dot === 'base');
      let indexOfLastBaseDot = prevDots.findLastIndex((dot) => dot === 'base');

      if (activeSlide > indexOfLastBaseDot) {
        indexOfFirstBaseDot += 1;
        indexOfLastBaseDot += 1;
      } else if (activeSlide < indexOfFirstBaseDot) {
        indexOfFirstBaseDot -= 1;
        indexOfLastBaseDot -= 1;
      }

      const isBetweenFirstAndLastBaseDot = (i: number) => i >= indexOfFirstBaseDot && i <= indexOfLastBaseDot;
      const getDistanceFromFirstBaseDot = (i: number) => Math.abs(indexOfFirstBaseDot - i);
      const getDistanceFromLastBaseDot = (i: number) => Math.abs(indexOfLastBaseDot - i);

      return prevDots.map((_, i) => {
        if (isBetweenFirstAndLastBaseDot(i)) return 'base';
        if (getDistanceFromFirstBaseDot(i) === 1) return 'sm';
        if (getDistanceFromFirstBaseDot(i) === 2) return 'xs';
        if (getDistanceFromLastBaseDot(i) === 1) return 'sm';
        if (getDistanceFromLastBaseDot(i) === 2) return 'xs';
        return 'none';
      });
    });
  }, [activeSlide]);

  return (
    <div
      className={cn(
        'flex items-center justify-center',
        direction === 'horizontal' && 'w-full flex-row',
        direction === 'vertical' && 'flex-col',
        classNames?.root,
        className,
      )}
    >
      {slides.map((slide, i) => (
        <div
          key={slide}
          data-active={i === activeSlide || undefined}
          data-size={dots[i]}
          data-previous={dots[i - 1]}
          onClick={() => onDotClick?.(i)}
          className={cn(
            'bg-white/70 border border-black rounded-full',
            'transition-[width,height] duration-200 ease-out',
            'size-[8px]',
            direction === 'horizontal' && 'ml-[4px] first:ml-0',
            direction === 'vertical' && 'mt-[4px] first:mt-0',
            'data-[size="sm"]:size-[6px]',
            'data-[size="xs"]:size-[4px]',
            'data-[size="none"]:size-[0px] data-[size="none"]:ml-0 data-[size="none"]:border-0',
            'data-[previous="none"]:ml-0',
            'data-[active]:bg-black',
            classNames?.dot,
          )}
        />
      ))}
    </div>
  );
};

export default CarouselIndicators;
