import { GetCart } from '@repo/client-shopify/src/gql/storefront/api/queries';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCookie } from './useCookie';

export function useGetCart({ cartId, refreshKey }: { cartId: string; refreshKey?: string }) {
  const buyerIp = useCookie('shopifyBuyerIP');
  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: [refreshKey ?? 'refreshkey', cartId ?? 'cartId', 'cart'],
    placeholderData: keepPreviousData,
    queryFn: async () =>
      await GetCart({
        cartId,
        buyerIp,
      }),
  });

  return { data, isLoading, error, isFetching };
}
