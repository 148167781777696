import { gql } from '../../__generated__/gql';

export const CUSTOMER_ACTIVATE = gql(`
  mutation customerActivate ($customerId: ID!, $input: CustomerActivateInput!) {
    customerActivate (id: $customerId, input: $input) {
      customer {
        id
        email
        displayName
        firstName
        lastName
        phone
        acceptsMarketing
        updatedAt
        createdAt
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
