import type { User } from '../../types/contextObjects';
import type { UserReducerPayload } from '../../types/actions';

export type UserReducerAction = {
  type: string;
  payload: UserReducerPayload;
};

export const UserReducer = (state: User, { type, payload }: UserReducerAction) => {
  switch (type) {
    case 'LOGIN':
      return {
        ...state,
        id: payload.id,
      };
    case 'LOGOUT':
      return {
        ...state,
        id: '',
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
