import React from 'react';
import { Menu } from '@mantine/core';
import {
  AccountIcon,
  Carousel,
  CompactCloseIcon,
  CompactRightChevronIcon,
  DownChevronIcon,
  Drawer,
  LeftArrowIcon,
  NextLink,
  AccountHelpCentreIcon,
  AccountMyOrdersIcon,
  AUFlagCircleIcon,
  NZFlagCircleIcon,
  USFlagCircleIcon,
} from '@ui/components/core';
import { Button } from '@ui/components/shared';
import { Embla } from '@mantine/carousel';
import Link from 'next/link';
import useModalStore from '@ui/store/modalStore';
import cn from '@ui/utils/cn';
import { useStaticContent } from '@ui/providers/static-content-provider';
import type { MenuLink } from '@ui/providers/static-content-provider';
import env from '@ui/env';

const slideRight = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(-100%)' },
  transitionProperty: 'transform',
};

type MegaMenuDrawerProps = {
  isGuest: boolean;
  opened: boolean;
  onClose: () => void;
};

type MenuLinkItem = {
  label: string;
  href?: string;
  children?: number;
};

const MegaMenuDrawer = ({ isGuest, opened, onClose }: MegaMenuDrawerProps) => {
  const openModal = useModalStore((state) => state.openModal);
  const mobileMenuLinks = useStaticContent('Menu.MobileMenuLinkTree');
  const MenuLinkTable = mobileMenuLinks.reduce(
    (previousValue, currentLink) => {
      const newValue = { ...previousValue };

      const flattenLinks = (menuLink: MenuLink, parent: string) => {
        newValue[parent] ??= [];

        newValue[parent].push({
          label: menuLink.label,
          href: menuLink.href,
          children: menuLink.children?.length,
        });

        menuLink.children?.forEach((childLink) => {
          flattenLinks(childLink, `${parent}.${menuLink.label}`);
        });
      };

      flattenLinks(currentLink, '*');

      return newValue;
    },
    {} as Record<string, MenuLinkItem[]>,
  );

  const menuPaths = MenuLinkTable;
  const [embla, setEmbla] = React.useState<Embla | null>(null);
  const [activePaths, setActivePaths] = React.useState<string[]>(['*']);

  const body = (
    <>
      <div className="p-4 flex justify-center">
        <button
          onClick={onClose}
          className="size-10 bg-white rounded-[4px] border-[0.6px] border-black flex items-center justify-center"
        >
          <CompactCloseIcon width={10} height={10} />
        </button>
      </div>
      <Carousel
        classNames={{
          root: 'h-full w-[280px] bg-white',
          viewport: 'h-full',
          container: 'h-full',
        }}
        withControls={false}
        draggable={false}
        getEmblaApi={setEmbla}
        speed={20}
      >
        {activePaths.map((activePath) => (
          <Carousel.Slide className="h-full relative" key={activePath}>
            <div className="h-full max-h-screen pb-16 overflow-y-hidden pl-4 relative">
              <div className="space-y-3 h-full overflow-y-auto pt-4 pr-4 pb-6">
                {!!(activePath !== '*') && (
                  <div className="flex items-center justify-center h-10 relative">
                    <button
                      className="flex items-center justify-center left-0 absolute"
                      onClick={() => embla?.scrollPrev()}
                    >
                      <LeftArrowIcon width={16} height={16} />
                    </button>
                    <div className="text-[13px] tracking-[0.04em]">{activePath.split('.').pop()?.toUpperCase()}</div>
                  </div>
                )}
                {menuPaths[activePath].map((menuLink) =>
                  menuLink.children ? (
                    <button
                      key={menuLink.label}
                      className="w-full flex items-center justify-between text-left p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-10"
                      onClick={() => {
                        const newPath = `${activePath}.${menuLink.label}`;

                        const newActivePaths = activePaths
                          .filter((path) => path.split('.').length < newPath.split('.').length)
                          .map((path) => path);

                        newActivePaths.push(newPath);

                        setActivePaths(newActivePaths);

                        setTimeout(() => embla?.scrollNext(), 1);
                      }}
                    >
                      <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{menuLink.label}</span>
                      <CompactRightChevronIcon width={12} height={12} />
                    </button>
                  ) : (
                    <NextLink
                      key={menuLink.label}
                      className="w-full flex items-center justify-between text-left p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-[38px]"
                      href={menuLink.href || ''}
                    >
                      <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{menuLink.label}</span>
                    </NextLink>
                  ),
                )}
              </div>
              {activePath === '*' && (
                <div className="absolute bottom-16 left-0 w-full bg-gradient-to-b from-transparent to-white to-[76.83%] h-6"></div>
              )}
            </div>
            {activePath === '*' && (
              <div
                className={cn(
                  'flex items-center justify-between',
                  'p-4 absolute bottom-0 inset-x-0',
                  isGuest && 'gap-x-4',
                )}
              >
                {isGuest ? (
                  <>
                    <Button className="!text-[10px] w-full h-[38px]" asChild>
                      <Link href="/account/login/"> LOGIN / SIGN UP</Link>
                    </Button>
                    <Menu
                      position="top"
                      zIndex={9999}
                      withinPortal
                      classNames={{
                        dropdown: 'py-2 px-0 rounded-lg shadow-none border-[0.5px] border-black bg-white !ml-0',
                        item: cn(
                          'px-3 py-1 text-[12px] text-center rounded-none underline-offset-[6px]',
                          'data-[hovered]:underline data-[hovered]:bg-white',
                        ),
                      }}
                    >
                      <Menu.Target>
                        <button
                          className={cn(
                            'flex items-center justify-center h-[38px] flex-shrink-0 gap-x-2',
                            'text-[0.625rem] font-bold group rounded-sm px-2',
                            'border border-black rounded-[4px]',
                            'focus:outline-none',
                          )}
                        >
                          <div className="flex items-center justify-center gap-x-[0.37rem]">
                            {env.NEXT_PUBLIC_REGION === 'NZ' && (
                              <>
                                <NZFlagCircleIcon width={16} height={16} className="flex-shrink-0" />
                                <span>$NZD</span>
                              </>
                            )}
                            {env.NEXT_PUBLIC_REGION === 'AU' && (
                              <>
                                <AUFlagCircleIcon width={16} height={16} className="flex-shrink-0" />
                                <span>$AUD</span>
                              </>
                            )}
                            {env.NEXT_PUBLIC_REGION === 'US' && (
                              <>
                                <USFlagCircleIcon width={16} height={16} className="flex-shrink-0" />
                                <span>$USD</span>
                              </>
                            )}
                          </div>
                          <DownChevronIcon
                            className="group-data-[expanded]:rotate-180 transition"
                            width={16}
                            height={16}
                          />
                        </button>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item className={env.NEXT_PUBLIC_REGION === 'AU' ? 'underline' : ''}>
                          {!(env.NEXT_PUBLIC_REGION === 'AU') ? (
                            <Link href="https://www.hellomolly.com.au/">
                              <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                                <AUFlagCircleIcon width={16} height={16} />
                                <span>$AUD</span>
                              </div>
                            </Link>
                          ) : (
                            <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                              <AUFlagCircleIcon width={16} height={16} />
                              <span>$AUD</span>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item className={env.NEXT_PUBLIC_REGION === 'NZ' ? 'underline' : ''}>
                          {!(env.NEXT_PUBLIC_REGION === 'NZ') ? (
                            <Link href="https://www.hellomolly.co.nz/">
                              <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                                <NZFlagCircleIcon width={16} height={16} />
                                <span>$NZD</span>
                              </div>
                            </Link>
                          ) : (
                            <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                              <NZFlagCircleIcon width={16} height={16} />
                              <span>$NZD</span>
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item className={env.NEXT_PUBLIC_REGION === 'US' ? 'underline' : ''}>
                          {!(env.NEXT_PUBLIC_REGION === 'US') ? (
                            <Link href="https://www.hellomolly.com/">
                              <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                                <USFlagCircleIcon width={16} height={16} />
                                <span>$USD</span>
                              </div>
                            </Link>
                          ) : (
                            <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
                              <USFlagCircleIcon width={16} height={16} />
                              <span>$USD</span>
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </>
                ) : (
                  <>
                    <Link href="/account/" className="flex flex-col gap-y-1 items-center">
                      <Button className="py-[0.44rem] px-[1.27rem]">
                        <AccountIcon width={24} height={24} className="text-white" />
                      </Button>
                      <span className="text-[0.625rem]">Account</span>
                    </Link>
                    <Link href="/account/orders/" className="flex flex-col gap-y-1 items-center">
                      <Button className="py-[0.44rem] px-[1.27rem]">
                        <AccountMyOrdersIcon width={24} height={24} className="text-white" />
                      </Button>
                      <span className="text-[0.625rem]">My Orders</span>
                    </Link>
                    <Link href="/pages/contact-us/" className="flex flex-col gap-y-1 items-center">
                      <Button className="py-[0.44rem] px-[1.27rem]">
                        <AccountHelpCentreIcon width={24} height={24} className="text-white" />
                      </Button>
                      <span className="text-[0.625rem]">Help Centre</span>
                    </Link>
                  </>
                )}
              </div>
            )}
          </Carousel.Slide>
        ))}
      </Carousel>
    </>
  );

  return (
    <Drawer
      withOverlay
      body={body}
      opened={opened}
      onClose={onClose}
      transitionProps={{
        duration: 200,
        timingFunction: 'ease',
        transition: slideRight,
      }}
      classNames={{
        root: 'lg:hidden',
        overlay: 'bg-black/20',
        inner: 'w-[352px]',
        content: 'flex flex-col h-full mt-0 bg-transparent shadow-none',
        body: 'h-full p-0 flex flex-row-reverse',
      }}
    />
  );
};

export default MegaMenuDrawer;
