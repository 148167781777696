import { MantineTransition } from '@mantine/core';

export const slideUpAfter = (transitionDelay: string): MantineTransition => ({
  in: {
    opacity: 1,
    transform: 'translateY(0)',
    transitionDuration: '300ms',
    transitionDelay,
  },
  out: {
    opacity: 0,
    transform: 'translateY(1rem)',
    transitionDuration: '150ms',
  },
  transitionProperty: 'opacity, transform',
});
