import { gql } from '../../__generated__/gql';

export const CUSTOMER_ADDRESS_DELETE = gql(`
  mutation customerAddressDelete ($customerAccessToken: String!, $addressId: ID!) {
    customerAddressDelete (customerAccessToken: $customerAccessToken, id: $addressId) {
      deletedCustomerAddressId
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
