import { gql } from '../../__generated__/gql';

export const GET_PRODUCT = gql(`
  query getProduct($handle: String!) {
    product(handle: $handle) {
      id
      handle
      title
      vendor
      availableForSale
      tags
      description
      descriptionHtml
      productType
      siblings: metafield(namespace: "categorisation", key: "siblings") {
        id
        value
      }
      styleWith: metafield(namespace: "recommendation", key: "style_with") {
        id
        value
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants(first: 50) {
        edges {
          cursor
          node {
            id
            title
            sku
            quantityAvailable
            availableForSale
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
      images(first: 12) {
        edges {
          node {
            id
            url
            width
            height
            altText
          }
        }
      }
    }
  }
`);
