export const deliverySuburb1 = [
  { suburb: 'Barangaroo	', postcode: '2000' },
  { suburb: 'Dawes Point	', postcode: '2000' },
  { suburb: 'Haymarket	', postcode: '2000' },
  { suburb: 'Millers Point	', postcode: '2000' },
  { suburb: 'Parliament House	', postcode: '2000' },
  { suburb: 'Sydney	', postcode: '2000' },
  { suburb: 'Sydney South	', postcode: '2000' },
  { suburb: 'The Rocks	', postcode: '2000' },
  { suburb: 'The University Of Sydney	', postcode: '2006' },
  { suburb: 'Broadway	', postcode: '2007' },
  { suburb: 'Ultimo	', postcode: '2007' },
  { suburb: 'Chippendale	', postcode: '2008' },
  { suburb: 'Darlington	', postcode: '2008' },
  { suburb: 'Pyrmont	', postcode: '2009' },
  { suburb: 'Darlinghurst	', postcode: '2010' },
  { suburb: 'Surry Hills	', postcode: '2010' },
  { suburb: 'Elizabeth Bay	', postcode: '2011' },
  { suburb: 'Hmas Kuttabul	', postcode: '2011' },
  { suburb: 'Potts Point	', postcode: '2011' },
  { suburb: 'Rushcutters Bay	', postcode: '2011' },
  { suburb: 'Woolloomooloo	', postcode: '2011' },
  { suburb: 'Alexandria	', postcode: '2015' },
  { suburb: 'Beaconsfield	', postcode: '2015' },
  { suburb: 'Eveleigh	', postcode: '2015' },
  { suburb: 'Redfern	', postcode: '2016' },
  { suburb: 'Waterloo	', postcode: '2017' },
  { suburb: 'Zetland	', postcode: '2017' },
  { suburb: 'Eastlakes	', postcode: '2018' },
  { suburb: 'Rosebery	', postcode: '2018' },
  { suburb: 'Banksmeadow	', postcode: '2019' },
  { suburb: 'Botany	', postcode: '2019' },
  { suburb: 'Mascot	', postcode: '2020' },
  { suburb: 'Sydney Domestic Airport	', postcode: '2020' },
  { suburb: 'Sydney International Airport	', postcode: '2020' },
  { suburb: 'Centennial Park	', postcode: '2021' },
  { suburb: 'Moore Park	', postcode: '2021' },
  { suburb: 'Paddington	', postcode: '2021' },
  { suburb: 'Bondi Junction	', postcode: '2022' },
  { suburb: 'Queens Park	', postcode: '2022' },
  { suburb: 'Bellevue Hill	', postcode: '2023' },
  { suburb: 'Bronte	', postcode: '2024' },
  { suburb: 'Waverley	', postcode: '2024' },
  { suburb: 'Woollahra	', postcode: '2025' },
  { suburb: 'Bondi	', postcode: '2026' },
  { suburb: 'Bondi Beach	', postcode: '2026' },
  { suburb: 'North Bondi	', postcode: '2026' },
  { suburb: 'Tamarama	', postcode: '2026' },
  { suburb: 'Darling Point	', postcode: '2027' },
  { suburb: 'Edgecliff	', postcode: '2027' },
  { suburb: 'Hmas Rushcutters	', postcode: '2027' },
  { suburb: 'Point Piper	', postcode: '2027' },
  { suburb: 'Double Bay	', postcode: '2028' },
  { suburb: 'Rose Bay	', postcode: '2029' },
  { suburb: 'Dover Heights	', postcode: '2030' },
  { suburb: 'Hmas Watson	', postcode: '2030' },
  { suburb: 'Rose Bay North	', postcode: '2030' },
  { suburb: 'Vaucluse	', postcode: '2030' },
  { suburb: 'Watsons Bay	', postcode: '2030' },
  { suburb: 'Clovelly	', postcode: '2031' },
  { suburb: 'Clovelly West	', postcode: '2031' },
  { suburb: 'Randwick	', postcode: '2031' },
  { suburb: 'St Pauls	', postcode: '2031' },
  { suburb: 'Daceyville	', postcode: '2032' },
  { suburb: 'Kingsford	', postcode: '2032' },
  { suburb: 'Kensington	', postcode: '2033' },
  { suburb: 'Coogee	', postcode: '2034' },
  { suburb: 'South Coogee	', postcode: '2034' },
  { suburb: 'Maroubra	', postcode: '2035' },
  { suburb: 'Maroubra South	', postcode: '2035' },
  { suburb: 'Pagewood	', postcode: '2035' },
  { suburb: 'Chifley	', postcode: '2036' },
  { suburb: 'Eastgardens	', postcode: '2036' },
  { suburb: 'Hillsdale	', postcode: '2036' },
  { suburb: 'La Perouse	', postcode: '2036' },
  { suburb: 'Little Bay	', postcode: '2036' },
  { suburb: 'Malabar	', postcode: '2036' },
  { suburb: 'Matraville	', postcode: '2036' },
  { suburb: 'Phillip Bay	', postcode: '2036' },
  { suburb: 'Port Botany	', postcode: '2036' },
  { suburb: 'Forest Lodge	', postcode: '2037' },
  { suburb: 'Glebe	', postcode: '2037' },
  { suburb: 'Annandale	', postcode: '2038' },
  { suburb: 'Rozelle	', postcode: '2039' },
  { suburb: 'Leichhardt	', postcode: '2040' },
  { suburb: 'Lilyfield	', postcode: '2040' },
  { suburb: 'Balmain	', postcode: '2041' },
  { suburb: 'Balmain East	', postcode: '2041' },
  { suburb: 'Birchgrove	', postcode: '2041' },
  { suburb: 'Enmore	', postcode: '2042' },
  { suburb: 'Newtown	', postcode: '2042' },
  { suburb: 'Erskineville	', postcode: '2043' },
  { suburb: 'St Peters	', postcode: '2044' },
  { suburb: 'Sydenham	', postcode: '2044' },
  { suburb: 'Tempe	', postcode: '2044' },
  { suburb: 'Haberfield	', postcode: '2045' },
  { suburb: 'Abbotsford	', postcode: '2046' },
  { suburb: 'Canada Bay	', postcode: '2046' },
  { suburb: 'Chiswick	', postcode: '2046' },
  { suburb: 'Five Dock	', postcode: '2046' },
  { suburb: 'Rodd Point	', postcode: '2046' },
  { suburb: 'Russell Lea	', postcode: '2046' },
  { suburb: 'Wareemba	', postcode: '2046' },
  { suburb: 'Drummoyne	', postcode: '2047' },
  { suburb: 'Stanmore	', postcode: '2048' },
  { suburb: 'Westgate	', postcode: '2048' },
  { suburb: 'Lewisham	', postcode: '2049' },
  { suburb: 'Petersham	', postcode: '2049' },
  { suburb: 'Petersham North	', postcode: '2049' },
  { suburb: 'Camperdown	', postcode: '2050' },
  { suburb: 'Missenden Road	', postcode: '2050' },
  { suburb: 'Unsw Sydney	', postcode: '2052' },
  { suburb: 'Hmas Platypus	', postcode: '2060' },
  { suburb: 'Hmas Waterhen	', postcode: '2060' },
  { suburb: 'Lavender Bay	', postcode: '2060' },
  { suburb: 'Mcmahons Point	', postcode: '2060' },
  { suburb: 'North Sydney	', postcode: '2060' },
  { suburb: 'North Sydney Shoppingworld	', postcode: '2060' },
  { suburb: 'Waverton	', postcode: '2060' },
  { suburb: 'Kirribilli	', postcode: '2061' },
  { suburb: 'Milsons Point	', postcode: '2061' },
  { suburb: 'Cammeray	', postcode: '2062' },
  { suburb: 'Northbridge	', postcode: '2063' },
  { suburb: 'Artarmon	', postcode: '2064' },
  { suburb: 'Crows Nest	', postcode: '2065' },
  { suburb: 'Greenwich	', postcode: '2065' },
  { suburb: 'Naremburn	', postcode: '2065' },
  { suburb: 'Royal North Shore Hospital	', postcode: '2065' },
  { suburb: 'St Leonards	', postcode: '2065' },
  { suburb: 'Wollstonecraft	', postcode: '2065' },
  { suburb: 'Lane Cove	', postcode: '2066' },
  { suburb: 'Lane Cove North	', postcode: '2066' },
  { suburb: 'Lane Cove West	', postcode: '2066' },
  { suburb: 'Linley Point	', postcode: '2066' },
  { suburb: 'Longueville	', postcode: '2066' },
  { suburb: 'Northwood	', postcode: '2066' },
  { suburb: 'Riverview	', postcode: '2066' },
  { suburb: 'Chatswood	', postcode: '2067' },
  { suburb: 'Chatswood West	', postcode: '2067' },
  { suburb: 'Castlecrag	', postcode: '2068' },
  { suburb: 'Middle Cove	', postcode: '2068' },
  { suburb: 'North Willoughby	', postcode: '2068' },
  { suburb: 'Willoughby	', postcode: '2068' },
  { suburb: 'Willoughby East	', postcode: '2068' },
  { suburb: 'Willoughby North	', postcode: '2068' },
  { suburb: 'Castle Cove	', postcode: '2069' },
  { suburb: 'Roseville	', postcode: '2069' },
  { suburb: 'Roseville Chase	', postcode: '2069' },
  { suburb: 'East Lindfield	', postcode: '2070' },
  { suburb: 'Lindfield	', postcode: '2070' },
  { suburb: 'Lindfield West	', postcode: '2070' },
  { suburb: 'East Killara	', postcode: '2071' },
  { suburb: 'Killara	', postcode: '2071' },
  { suburb: 'Gordon	', postcode: '2072' },
  { suburb: 'Pymble	', postcode: '2073' },
  { suburb: 'West Pymble	', postcode: '2073' },
  { suburb: 'North Turramurra	', postcode: '2074' },
  { suburb: 'South Turramurra	', postcode: '2074' },
  { suburb: 'Turramurra	', postcode: '2074' },
  { suburb: 'Warrawee	', postcode: '2074' },
  { suburb: 'St Ives	', postcode: '2075' },
  { suburb: 'St Ives Chase	', postcode: '2075' },
  { suburb: 'Normanhurst	', postcode: '2076' },
  { suburb: 'North Wahroonga	', postcode: '2076' },
  { suburb: 'Wahroonga	', postcode: '2076' },
  { suburb: 'Asquith	', postcode: '2077' },
  { suburb: 'Hornsby	', postcode: '2077' },
  { suburb: 'Hornsby Heights	', postcode: '2077' },
  { suburb: 'Waitara	', postcode: '2077' },
  { suburb: 'Terrey Hills	', postcode: '2084' },
  { suburb: 'Belrose	', postcode: '2085' },
  { suburb: 'Belrose West	', postcode: '2085' },
  { suburb: 'Davidson	', postcode: '2085' },
  { suburb: 'Frenchs Forest	', postcode: '2086' },
  { suburb: 'Frenchs Forest East	', postcode: '2086' },
  { suburb: 'Forestville	', postcode: '2087' },
  { suburb: 'Killarney Heights	', postcode: '2087' },
  { suburb: 'Mosman	', postcode: '2088' },
  { suburb: 'Spit Junction	', postcode: '2088' },
  { suburb: 'Kurraba Point	', postcode: '2089' },
  { suburb: 'Neutral Bay	', postcode: '2089' },
  { suburb: 'Neutral Bay Junction	', postcode: '2089' },
  { suburb: 'Cremorne	', postcode: '2090' },
  { suburb: 'Cremorne Junction	', postcode: '2090' },
  { suburb: 'Cremorne Point	', postcode: '2090' },
  { suburb: 'Seaforth	', postcode: '2092' },
  { suburb: 'Balgowlah	', postcode: '2093' },
  { suburb: 'Balgowlah Heights	', postcode: '2093' },
  { suburb: 'Clontarf	', postcode: '2093' },
  { suburb: 'Manly Vale	', postcode: '2093' },
  { suburb: 'North Balgowlah	', postcode: '2093' },
  { suburb: 'Fairlight	', postcode: '2094' },
  { suburb: 'Manly	', postcode: '2095' },
  { suburb: 'Manly East	', postcode: '2095' },
  { suburb: 'Curl Curl	', postcode: '2096' },
  { suburb: 'Freshwater	', postcode: '2096' },
  { suburb: 'Queenscliff	', postcode: '2096' },
  { suburb: 'Collaroy	', postcode: '2097' },
  { suburb: 'Collaroy Beach	', postcode: '2097' },
  { suburb: 'Collaroy Plateau	', postcode: '2097' },
  { suburb: 'Wheeler Heights	', postcode: '2097' },
  { suburb: 'Cromer	', postcode: '2099' },
  { suburb: 'Dee Why	', postcode: '2099' },
  { suburb: 'Narraweena	', postcode: '2099' },
  { suburb: 'North Curl Curl	', postcode: '2099' },
  { suburb: 'Allambie Heights	', postcode: '2100' },
  { suburb: 'Beacon Hill	', postcode: '2100' },
  { suburb: 'Brookvale	', postcode: '2100' },
  { suburb: 'North Manly	', postcode: '2100' },
  { suburb: 'Oxford Falls	', postcode: '2100' },
  { suburb: 'Warringah Mall	', postcode: '2100' },
  { suburb: 'Elanora Heights	', postcode: '2101' },
];

export const deliverySuburb2 = [
  { suburb: 'Ingleside	', postcode: '2101' },
  { suburb: 'Narrabeen	', postcode: '2101' },
  { suburb: 'North Narrabeen	', postcode: '2101' },
  { suburb: 'Strawberry Hills	', postcode: '2102' },
  { suburb: 'Warriewood	', postcode: '2102' },
  { suburb: 'Mona Vale	', postcode: '2103' },
  { suburb: 'Macquarie University	', postcode: '2109' },
  { suburb: 'Hunters Hill	', postcode: '2110' },
  { suburb: 'Woolwich	', postcode: '2110' },
  { suburb: 'Boronia Park	', postcode: '2111' },
  { suburb: 'Gladesville	', postcode: '2111' },
  { suburb: 'Henley	', postcode: '2111' },
  { suburb: 'Huntleys Cove	', postcode: '2111' },
  { suburb: 'Huntleys Point	', postcode: '2111' },
  { suburb: 'Monash Park	', postcode: '2111' },
  { suburb: 'Tennyson Point	', postcode: '2111' },
  { suburb: 'Denistone East	', postcode: '2112' },
  { suburb: 'Putney	', postcode: '2112' },
  { suburb: 'Ryde	', postcode: '2112' },
  { suburb: 'Blenheim Road	', postcode: '2113' },
  { suburb: 'East Ryde	', postcode: '2113' },
  { suburb: 'Macquarie Centre	', postcode: '2113' },
  { suburb: 'Macquarie Park	', postcode: '2113' },
  { suburb: 'North Ryde	', postcode: '2113' },
  { suburb: 'Denistone	', postcode: '2114' },
  { suburb: 'Denistone West	', postcode: '2114' },
  { suburb: 'Meadowbank	', postcode: '2114' },
  { suburb: 'Melrose Park	', postcode: '2114' },
  { suburb: 'West Ryde	', postcode: '2114' },
  { suburb: 'Ermington	', postcode: '2115' },
  { suburb: 'Rydalmere	', postcode: '2116' },
  { suburb: 'Dundas	', postcode: '2117' },
  { suburb: 'Dundas Valley	', postcode: '2117' },
  { suburb: 'Oatlands	', postcode: '2117' },
  { suburb: 'Telopea	', postcode: '2117' },
  { suburb: 'Carlingford	', postcode: '2118' },
  { suburb: 'Carlingford Court	', postcode: '2118' },
  { suburb: 'Carlingford North	', postcode: '2118' },
  { suburb: 'Kingsdene	', postcode: '2118' },
  { suburb: 'Beecroft	', postcode: '2119' },
  { suburb: 'Cheltenham	', postcode: '2119' },
  { suburb: 'Pennant Hills	', postcode: '2120' },
  { suburb: 'Thornleigh	', postcode: '2120' },
  { suburb: 'Westleigh	', postcode: '2120' },
  { suburb: 'Epping	', postcode: '2121' },
  { suburb: 'North Epping	', postcode: '2121' },
  { suburb: 'Eastwood	', postcode: '2122' },
  { suburb: 'Marsfield	', postcode: '2122' },
  { suburb: 'West Pennant Hills	', postcode: '2125' },
  { suburb: 'Cherrybrook	', postcode: '2126' },
  { suburb: 'Newington	', postcode: '2127' },
  { suburb: 'Sydney Olympic Park	', postcode: '2127' },
  { suburb: 'Wentworth Point	', postcode: '2127' },
  { suburb: 'Silverwater	', postcode: '2128' },
  { suburb: 'Summer Hill	', postcode: '2130' },
  { suburb: 'Ashfield	', postcode: '2131' },
  { suburb: 'Croydon	', postcode: '2132' },
  { suburb: 'Croydon Park	', postcode: '2133' },
  { suburb: 'Enfield South	', postcode: '2133' },
  { suburb: 'Burwood	', postcode: '2134' },
  { suburb: 'Burwood North	', postcode: '2134' },
  { suburb: 'Strathfield	', postcode: '2135' },
  { suburb: 'Burwood Heights	', postcode: '2136' },
  { suburb: 'Enfield	', postcode: '2136' },
  { suburb: 'Strathfield South	', postcode: '2136' },
  { suburb: 'Breakfast Point	', postcode: '2137' },
  { suburb: 'Cabarita	', postcode: '2137' },
  { suburb: 'Concord	', postcode: '2137' },
  { suburb: 'Mortlake	', postcode: '2137' },
  { suburb: 'North Strathfield	', postcode: '2137' },
  { suburb: 'Concord West	', postcode: '2138' },
  { suburb: 'Liberty Grove	', postcode: '2138' },
  { suburb: 'Rhodes	', postcode: '2138' },
  { suburb: 'Homebush	', postcode: '2140' },
  { suburb: 'Homebush South	', postcode: '2140' },
  { suburb: 'Homebush West	', postcode: '2140' },
  { suburb: 'Berala	', postcode: '2141' },
  { suburb: 'Lidcombe	', postcode: '2141' },
  { suburb: 'Lidcombe North	', postcode: '2141' },
  { suburb: 'Rookwood	', postcode: '2141' },
  { suburb: 'Blaxcell	', postcode: '2142' },
  { suburb: 'Camellia	', postcode: '2142' },
  { suburb: 'Clyde	', postcode: '2142' },
  { suburb: 'Granville	', postcode: '2142' },
  { suburb: 'Holroyd	', postcode: '2142' },
  { suburb: 'Rosehill	', postcode: '2142' },
  { suburb: 'South Granville	', postcode: '2142' },
  { suburb: 'Birrong	', postcode: '2143' },
  { suburb: 'Potts Hill	', postcode: '2143' },
  { suburb: 'Regents Park	', postcode: '2143' },
  { suburb: 'Auburn	', postcode: '2144' },
  { suburb: 'Constitution Hill	', postcode: '2145' },
  { suburb: 'Girraween	', postcode: '2145' },
  { suburb: 'Greystanes	', postcode: '2145' },
  { suburb: 'Mays Hill	', postcode: '2145' },
  { suburb: 'Pemulwuy	', postcode: '2145' },
  { suburb: 'Pendle Hill	', postcode: '2145' },
  { suburb: 'South Wentworthville	', postcode: '2145' },
  { suburb: 'Wentworthville	', postcode: '2145' },
  { suburb: 'Westmead	', postcode: '2145' },
  { suburb: 'Old Toongabbie	', postcode: '2146' },
  { suburb: 'Toongabbie	', postcode: '2146' },
  { suburb: 'Toongabbie East	', postcode: '2146' },
  { suburb: 'Kings Langley	', postcode: '2147' },
  { suburb: 'Lalor Park	', postcode: '2147' },
  { suburb: 'Seven Hills	', postcode: '2147' },
  { suburb: 'Seven Hills West	', postcode: '2147' },
  { suburb: 'Arndell Park	', postcode: '2148' },
  { suburb: 'Blacktown	', postcode: '2148' },
  { suburb: 'Blacktown Westpoint	', postcode: '2148' },
  { suburb: 'Huntingwood	', postcode: '2148' },
  { suburb: 'Kings Park	', postcode: '2148' },
  { suburb: 'Marayong	', postcode: '2148' },
  { suburb: 'Prospect	', postcode: '2148' },
  { suburb: 'Harris Park	', postcode: '2150' },
  { suburb: 'Parramatta	', postcode: '2150' },
  { suburb: 'Parramatta Westfield	', postcode: '2150' },
  { suburb: 'North Parramatta	', postcode: '2151' },
  { suburb: 'North Rocks	', postcode: '2151' },
  { suburb: 'Northmead	', postcode: '2152' },
  { suburb: 'Baulkham Hills	', postcode: '2153' },
  { suburb: 'Bella Vista	', postcode: '2153' },
  { suburb: 'Winston Hills	', postcode: '2153' },
  { suburb: 'Castle Hill	', postcode: '2154' },
  { suburb: 'Beaumont Hills	', postcode: '2155' },
  { suburb: 'Kellyville	', postcode: '2155' },
  { suburb: 'Kellyville Ridge	', postcode: '2155' },
  { suburb: 'Rouse Hill	', postcode: '2155' },
  { suburb: 'Merrylands	', postcode: '2160' },
  { suburb: 'Merrylands West	', postcode: '2160' },
  { suburb: 'Guildford	', postcode: '2161' },
  { suburb: 'Guildford West	', postcode: '2161' },
  { suburb: 'Old Guildford	', postcode: '2161' },
  { suburb: 'Yennora	', postcode: '2161' },
  { suburb: 'Chester Hill	', postcode: '2162' },
  { suburb: 'Sefton	', postcode: '2162' },
  { suburb: 'Carramar	', postcode: '2163' },
  { suburb: 'Lansdowne	', postcode: '2163' },
  { suburb: 'Villawood	', postcode: '2163' },
  { suburb: 'Smithfield	', postcode: '2164' },
  { suburb: 'Smithfield West	', postcode: '2164' },
  { suburb: 'Wetherill Park	', postcode: '2164' },
  { suburb: 'Woodpark	', postcode: '2164' },
  { suburb: 'Fairfield	', postcode: '2165' },
  { suburb: 'Fairfield East	', postcode: '2165' },
  { suburb: 'Fairfield Heights	', postcode: '2165' },
  { suburb: 'Fairfield West	', postcode: '2165' },
  { suburb: 'Cabramatta	', postcode: '2166' },
  { suburb: 'Cabramatta West	', postcode: '2166' },
  { suburb: 'Canley Heights	', postcode: '2166' },
  { suburb: 'Canley Vale	', postcode: '2166' },
  { suburb: 'Lansvale	', postcode: '2166' },
  { suburb: 'Glenfield	', postcode: '2167' },
  { suburb: 'Ashcroft	', postcode: '2168' },
  { suburb: 'Busby	', postcode: '2168' },
  { suburb: 'Cartwright	', postcode: '2168' },
  { suburb: 'Green Valley	', postcode: '2168' },
  { suburb: 'Heckenberg	', postcode: '2168' },
  { suburb: 'Hinchinbrook	', postcode: '2168' },
  { suburb: 'Miller	', postcode: '2168' },
  { suburb: 'Sadleir	', postcode: '2168' },
  { suburb: 'Casula	', postcode: '2170' },
  { suburb: 'Casula Mall	', postcode: '2170' },
  { suburb: 'Chipping Norton	', postcode: '2170' },
  { suburb: 'Hammondville	', postcode: '2170' },
  { suburb: 'Liverpool	', postcode: '2170' },
  { suburb: 'Liverpool South	', postcode: '2170' },
  { suburb: 'Liverpool Westfield	', postcode: '2170' },
  { suburb: 'Lurnea	', postcode: '2170' },
  { suburb: 'Moorebank	', postcode: '2170' },
  { suburb: 'Mount Pritchard	', postcode: '2170' },
  { suburb: 'Prestons	', postcode: '2170' },
  { suburb: 'Warwick Farm	', postcode: '2170' },
  { suburb: 'Carnes Hill	', postcode: '2171' },
  { suburb: 'Cecil Hills	', postcode: '2171' },
  { suburb: 'Elizabeth Hills	', postcode: '2171' },
  { suburb: 'Horningsea Park	', postcode: '2171' },
  { suburb: 'Hoxton Park	', postcode: '2171' },
  { suburb: 'Len Waters Estate	', postcode: '2171' },
  { suburb: 'Middleton Grange	', postcode: '2171' },
  { suburb: 'West Hoxton	', postcode: '2171' },
  { suburb: 'Pleasure Point	', postcode: '2172' },
  { suburb: 'Sandy Point	', postcode: '2172' },
  { suburb: 'Voyager Point	', postcode: '2172' },
  { suburb: 'Holsworthy	', postcode: '2173' },
  { suburb: 'Wattle Grove	', postcode: '2173' },
  { suburb: 'Edmondson Park	', postcode: '2174' },
  { suburb: 'Horsley Park	', postcode: '2175' },
  { suburb: 'Abbotsbury	', postcode: '2176' },
  { suburb: 'Bossley Park	', postcode: '2176' },
  { suburb: 'Edensor Park	', postcode: '2176' },
  { suburb: 'Greenfield Park	', postcode: '2176' },
  { suburb: 'Prairiewood	', postcode: '2176' },
  { suburb: 'St Johns Park	', postcode: '2176' },
  { suburb: 'Wakeley	', postcode: '2176' },
  { suburb: 'Bonnyrigg	', postcode: '2177' },
  { suburb: 'Bonnyrigg Heights	', postcode: '2177' },
  { suburb: 'Cecil Park	', postcode: '2178' },
  { suburb: 'Kemps Creek	', postcode: '2178' },
  { suburb: 'Mount Vernon	', postcode: '2178' },
  { suburb: 'Austral	', postcode: '2179' },
  { suburb: 'Leppington	', postcode: '2179' },
  { suburb: 'Chullora	', postcode: '2190' },
  { suburb: 'Greenacre	', postcode: '2190' },
  { suburb: 'Mount Lewis	', postcode: '2190' },
  { suburb: 'Belfield	', postcode: '2191' },
  { suburb: 'Belmore	', postcode: '2192' },
  { suburb: 'Ashbury	', postcode: '2193' },
  { suburb: 'Canterbury	', postcode: '2193' },
  { suburb: 'Hurlstone Park	', postcode: '2193' },
  { suburb: 'Campsie	', postcode: '2194' },
  { suburb: 'Lakemba	', postcode: '2195' },
];

export const deliverySuburb3 = [
  { suburb: 'Wiley Park	', postcode: '2195' },
  { suburb: 'Punchbowl	', postcode: '2196' },
  { suburb: 'Roselands	', postcode: '2196' },
  { suburb: 'Bass Hill	', postcode: '2197' },
  { suburb: 'Georges Hall	', postcode: '2198' },
  { suburb: 'Yagoona	', postcode: '2199' },
  { suburb: 'Yagoona West	', postcode: '2199' },
  { suburb: 'Bankstown	', postcode: '2200' },
  { suburb: 'Bankstown Aerodrome	', postcode: '2200' },
  { suburb: 'Bankstown North	', postcode: '2200' },
  { suburb: 'Bankstown Square	', postcode: '2200' },
  { suburb: 'Condell Park	', postcode: '2200' },
  { suburb: 'Manahan	', postcode: '2200' },
  { suburb: 'Mount Lewis	', postcode: '2200' },
  { suburb: 'Dulwich Hill	', postcode: '2203' },
  { suburb: 'Marrickville	', postcode: '2204' },
  { suburb: 'Marrickville Metro	', postcode: '2204' },
  { suburb: 'Marrickville South	', postcode: '2204' },
  { suburb: 'Arncliffe	', postcode: '2205' },
  { suburb: 'Turrella	', postcode: '2205' },
  { suburb: 'Wolli Creek	', postcode: '2205' },
  { suburb: 'Clemton Park	', postcode: '2206' },
  { suburb: 'Earlwood	', postcode: '2206' },
  { suburb: 'Bardwell Park	', postcode: '2207' },
  { suburb: 'Bardwell Valley	', postcode: '2207' },
  { suburb: 'Bexley	', postcode: '2207' },
  { suburb: 'Bexley North	', postcode: '2207' },
  { suburb: 'Bexley South	', postcode: '2207' },
  { suburb: 'Kingsgrove	', postcode: '2208' },
  { suburb: 'Kingsway West	', postcode: '2208' },
  { suburb: 'Beverly Hills	', postcode: '2209' },
  { suburb: 'Narwee	', postcode: '2209' },
  { suburb: 'Lugarno	', postcode: '2210' },
  { suburb: 'Peakhurst	', postcode: '2210' },
  { suburb: 'Peakhurst Heights	', postcode: '2210' },
  { suburb: 'Riverwood	', postcode: '2210' },
  { suburb: 'Padstow	', postcode: '2211' },
  { suburb: 'Padstow Heights	', postcode: '2211' },
  { suburb: 'Revesby	', postcode: '2212' },
  { suburb: 'Revesby Heights	', postcode: '2212' },
  { suburb: 'Revesby North	', postcode: '2212' },
  { suburb: 'East Hills	', postcode: '2213' },
  { suburb: 'Panania	', postcode: '2213' },
  { suburb: 'Picnic Point	', postcode: '2213' },
  { suburb: 'Milperra	', postcode: '2214' },
  { suburb: 'Banksia	', postcode: '2216' },
  { suburb: 'Brighton-Le-Sands	', postcode: '2216' },
  { suburb: 'Kyeemagh	', postcode: '2216' },
  { suburb: 'Rockdale	', postcode: '2216' },
  { suburb: 'Beverley Park	', postcode: '2217' },
  { suburb: 'Kogarah	', postcode: '2217' },
  { suburb: 'Kogarah Bay	', postcode: '2217' },
  { suburb: 'Monterey	', postcode: '2217' },
  { suburb: 'Ramsgate	', postcode: '2217' },
  { suburb: 'Ramsgate Beach	', postcode: '2217' },
  { suburb: 'Allawah	', postcode: '2218' },
  { suburb: 'Carlton	', postcode: '2218' },
  { suburb: 'Dolls Point	', postcode: '2219' },
  { suburb: 'Sandringham	', postcode: '2219' },
  { suburb: 'Sans Souci	', postcode: '2219' },
  { suburb: 'Hurstville	', postcode: '2220' },
  { suburb: 'Hurstville Grove	', postcode: '2220' },
  { suburb: 'Hurstville Westfield	', postcode: '2220' },
  { suburb: 'Blakehurst	', postcode: '2221' },
  { suburb: 'Carss Park	', postcode: '2221' },
  { suburb: 'Connells Point	', postcode: '2221' },
  { suburb: 'Kyle Bay	', postcode: '2221' },
  { suburb: 'South Hurstville	', postcode: '2221' },
  { suburb: 'Penshurst	', postcode: '2222' },
  { suburb: 'Mortdale	', postcode: '2223' },
  { suburb: 'Oatley	', postcode: '2223' },
  { suburb: 'Kangaroo Point	', postcode: '2224' },
  { suburb: 'Sylvania	', postcode: '2224' },
  { suburb: 'Sylvania Southgate	', postcode: '2224' },
  { suburb: 'Sylvania Waters	', postcode: '2224' },
  { suburb: 'Oyster Bay	', postcode: '2225' },
  { suburb: 'Bonnet Bay	', postcode: '2226' },
  { suburb: 'Como	', postcode: '2226' },
  { suburb: 'Jannali	', postcode: '2226' },
  { suburb: 'Gymea	', postcode: '2227' },
  { suburb: 'Gymea Bay	', postcode: '2227' },
  { suburb: 'Miranda	', postcode: '2228' },
  { suburb: 'Yowie Bay	', postcode: '2228' },
  { suburb: 'Caringbah	', postcode: '2229' },
  { suburb: 'Caringbah South	', postcode: '2229' },
  { suburb: 'Dolans Bay	', postcode: '2229' },
  { suburb: 'Lilli Pilli	', postcode: '2229' },
  { suburb: 'Port Hacking	', postcode: '2229' },
  { suburb: 'Taren Point	', postcode: '2229' },
  { suburb: 'Cronulla	', postcode: '2230' },
  { suburb: 'Greenhills Beach	', postcode: '2230' },
  { suburb: 'Woolooware	', postcode: '2230' },
  { suburb: 'Kurnell	', postcode: '2231' },
  { suburb: 'Grays Point	', postcode: '2232' },
  { suburb: 'Kareela	', postcode: '2232' },
  { suburb: 'Kirrawee	', postcode: '2232' },
  { suburb: 'Loftus	', postcode: '2232' },
  { suburb: 'Sutherland	', postcode: '2232' },
  { suburb: 'Woronora	', postcode: '2232' },
  { suburb: 'Engadine	', postcode: '2233' },
  { suburb: 'Alfords Point	', postcode: '2234' },
  { suburb: 'Bangor	', postcode: '2234' },
  { suburb: 'Barden Ridge	', postcode: '2234' },
  { suburb: 'Illawong	', postcode: '2234' },
  { suburb: 'Lucas Heights	', postcode: '2234' },
  { suburb: 'Menai	', postcode: '2234' },
  { suburb: 'Menai Central	', postcode: '2234' },
  { suburb: 'Catherine Field	', postcode: '2557' },
  { suburb: 'Gledswood Hills	', postcode: '2557' },
  { suburb: 'Gregory Hills	', postcode: '2557' },
  { suburb: 'Rossmore	', postcode: '2557' },
  { suburb: 'Eagle Vale	', postcode: '2558' },
  { suburb: 'Eschol Park	', postcode: '2558' },
  { suburb: 'Kearns	', postcode: '2558' },
  { suburb: 'Blairmount	', postcode: '2559' },
  { suburb: 'Claymore	', postcode: '2559' },
  { suburb: 'Airds	', postcode: '2560' },
  { suburb: 'Ambarvale	', postcode: '2560' },
  { suburb: 'Blair Athol	', postcode: '2560' },
  { suburb: 'Campbelltown	', postcode: '2560' },
  { suburb: 'Campbelltown North	', postcode: '2560' },
  { suburb: 'Leumeah	', postcode: '2560' },
  { suburb: 'Macarthur Square	', postcode: '2560' },
  { suburb: 'Rosemeadow	', postcode: '2560' },
  { suburb: 'Ruse	', postcode: '2560' },
  { suburb: 'St Helens Park	', postcode: '2560' },
  { suburb: 'Wedderburn	', postcode: '2560' },
  { suburb: 'Woodbine	', postcode: '2560' },
  { suburb: 'Macquarie Fields	', postcode: '2564' },
  { suburb: 'Denham Court	', postcode: '2565' },
  { suburb: 'Ingleburn	', postcode: '2565' },
  { suburb: 'Macquarie Links	', postcode: '2565' },
  { suburb: 'Bow Bowing	', postcode: '2566' },
  { suburb: 'Minto	', postcode: '2566' },
  { suburb: 'Minto Dc	', postcode: '2566' },
  { suburb: 'Minto Heights	', postcode: '2566' },
  { suburb: 'Raby	', postcode: '2566' },
  { suburb: 'St Andrews	', postcode: '2566' },
  { suburb: 'Varroville	', postcode: '2566' },
  { suburb: 'Currans Hill	', postcode: '2567' },
  { suburb: 'Harrington Park	', postcode: '2567' },
  { suburb: 'Narellan	', postcode: '2567' },
  { suburb: 'Narellan Dc	', postcode: '2567' },
  { suburb: 'Narellan Vale	', postcode: '2567' },
  { suburb: 'Smeaton Grange	', postcode: '2567' },
  { suburb: 'Cambridge Park	', postcode: '2747' },
  { suburb: 'Claremont Meadows	', postcode: '2747' },
  { suburb: 'Jordan Springs	', postcode: '2747' },
  { suburb: 'Kingswood	', postcode: '2747' },
  { suburb: 'Llandilo	', postcode: '2747' },
  { suburb: 'Shanes Park	', postcode: '2747' },
  { suburb: 'Werrington	', postcode: '2747' },
  { suburb: 'Werrington County	', postcode: '2747' },
  { suburb: 'Werrington Downs	', postcode: '2747' },
  { suburb: 'Orchard Hills	', postcode: '2748' },
  { suburb: 'Castlereagh	', postcode: '2749' },
  { suburb: 'Emu Heights	', postcode: '2750' },
  { suburb: 'Emu Plains	', postcode: '2750' },
  { suburb: 'Jamisontown	', postcode: '2750' },
  { suburb: 'Leonay	', postcode: '2750' },
  { suburb: 'Penrith	', postcode: '2750' },
  { suburb: 'Penrith Plaza	', postcode: '2750' },
  { suburb: 'Penrith South	', postcode: '2750' },
  { suburb: 'South Penrith	', postcode: '2750' },
  { suburb: 'Erskine Park	', postcode: '2759' },
  { suburb: 'St Clair	', postcode: '2759' },
  { suburb: 'Colyton	', postcode: '2760' },
  { suburb: 'North St Marys	', postcode: '2760' },
  { suburb: 'Oxley Park	', postcode: '2760' },
  { suburb: 'Ropes Crossing	', postcode: '2760' },
  { suburb: 'St Marys	', postcode: '2760' },
  { suburb: 'St Marys East	', postcode: '2760' },
  { suburb: 'St Marys South	', postcode: '2760' },
  { suburb: 'Colebee	', postcode: '2761' },
  { suburb: 'Dean Park	', postcode: '2761' },
  { suburb: 'Glendenning	', postcode: '2761' },
  { suburb: 'Hassall Grove	', postcode: '2761' },
  { suburb: 'Oakhurst	', postcode: '2761' },
  { suburb: 'Plumpton	', postcode: '2761' },
  { suburb: 'Schofields	', postcode: '2762' },
  { suburb: 'Acacia Gardens	', postcode: '2763' },
  { suburb: 'Quakers Hill	', postcode: '2763' },
  { suburb: 'Berkshire Park	', postcode: '2765' },
  { suburb: 'Box Hill	', postcode: '2765' },
  { suburb: 'Maraylya	', postcode: '2765' },
  { suburb: 'Marsden Park	', postcode: '2765' },
  { suburb: 'Nelson	', postcode: '2765' },
  { suburb: 'Oakville	', postcode: '2765' },
  { suburb: 'Riverstone	', postcode: '2765' },
  { suburb: 'Vineyard	', postcode: '2765' },
  { suburb: 'Eastern Creek	', postcode: '2766' },
  { suburb: 'Rooty Hill	', postcode: '2766' },
  { suburb: 'Bungarribee	', postcode: '2767' },
  { suburb: 'Doonside	', postcode: '2767' },
  { suburb: 'Woodcroft	', postcode: '2767' },
  { suburb: 'Glenwood	', postcode: '2768' },
  { suburb: 'Parklea	', postcode: '2768' },
  { suburb: 'Stanhope Gardens	', postcode: '2768' },
  { suburb: 'The Ponds	', postcode: '2769' },
  { suburb: 'Bidwill	', postcode: '2770' },
  { suburb: 'Blackett	', postcode: '2770' },
  { suburb: 'Dharruk	', postcode: '2770' },
  { suburb: 'Emerton	', postcode: '2770' },
  { suburb: 'Hebersham	', postcode: '2770' },
  { suburb: 'Lethbridge Park	', postcode: '2770' },
  { suburb: 'Minchinbury	', postcode: '2770' },
  { suburb: 'Mount Druitt	', postcode: '2770' },
  { suburb: 'Mount Druitt Village	', postcode: '2770' },
  { suburb: 'Shalvey	', postcode: '2770' },
  { suburb: 'Tregear	', postcode: '2770' },
  { suburb: 'Whalan	', postcode: '2770' },
  { suburb: 'Willmot	', postcode: '2770' },
];
