import { gql } from '../../__generated__/gql';

export const GET_COLLECTION_IMAGE = gql(`
  query getCollectionImage ($handle: String!) {
    collection (handle: $handle) {
      image {
        url
        width
        height
        altText
      }
    }
  }
`);
