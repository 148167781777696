const chip = {
  styles: {
    root: {
      borderRadius: '0.13rem',
    },
    label: {
      fontSize: '0.8rem',
      fontWeight: 400,
      // letterSpacing: '0.05rem',
    },
  },
};

export default chip;
