import { gql } from '../../__generated__/gql';

export const CUSTOMER_ADDRESS_UPDATE = gql(`
  mutation customerAddressUpdate ($addressId: ID!, $customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressUpdate (id: $addressId, customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
        firstName
        lastName
        phone
        company
        address1
        address2
        city
        country
        province
        zip
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
