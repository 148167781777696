import { gql } from '../../__generated__/gql';

export const GET_ARTICLE = gql(`
  query getArticle($handle: String!) {
    blog(handle: "moments") {
      articleByHandle(handle: $handle) {
        id
        handle
        title
        contentHtml
        content
        publishedAt
        image {
          url
          altText
        }
        seo {
          title
          description
        }
        tags
      }
    }
  }
`);
