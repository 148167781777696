import React from 'react';

export default function OrderIssuesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68229 4.27734H17.349L20.0156 8.27734V20.2773H4.01562V8.27734L6.68229 4.27734Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0146 4.27734V8.27734" stroke="#0D0005" strokeWidth="0.8" />
      <path d="M4.01562 8.27734H20.0156" stroke="#0D0005" strokeWidth="0.8" />
      <path
        d="M16.6908 21.2773L14.0547 18.6412V14.9133L16.6908 12.2773H20.4189L23.0547 14.9133V18.6412L20.4189 21.2773H16.6908Z"
        fill="white"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7793 14.6055V17.6055"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8047 19.0234C18.9428 19.0234 19.0547 18.9115 19.0547 18.7734C19.0547 18.6354 18.9428 18.5234 18.8047 18.5234C18.6666 18.5234 18.5547 18.6354 18.5547 18.7734C18.5547 18.9115 18.6666 19.0234 18.8047 19.0234Z"
        fill="#0D0005"
        stroke="#0D0005"
        strokeWidth="0.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
