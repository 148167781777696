import { MantineTheme } from '@mantine/core';

const hovercard = {
  styles: (theme: MantineTheme) => ({
    dropdown: {
      border: 'none',
      borderBottom: `0.0625rem solid ${theme.black}`,
      borderRadius: 0,
    },
  }),
};

export default hovercard;
