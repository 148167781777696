import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_CUSTOMER_ORDER } from '../../documents/queries';
import type { GetCustomerOrderQuery, GetCustomerOrderQueryVariables } from '../../__generated__/graphql';

export async function GetCustomerOrder({ customerAccessToken, orderId }: GetCustomerOrderQueryVariables): Promise<{
  data: GetCustomerOrderQuery | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunQuery(GET_CUSTOMER_ORDER, {
    customerAccessToken: customerAccessToken,
    orderId: orderId,
  });

  return { data, error };
}
