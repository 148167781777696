import cn from '@ui/utils/cn';
import Money from '../../formatters/Money/Money';

type ProductCardPriceProps = {
  className?: string;
  price: string;
  compareAtPrice?: string;
  currency?: string;
};

const ProductCardPrice = ({ className, price, compareAtPrice, currency }: ProductCardPriceProps) => (
  <div className={cn('flex gap-1', className)}>
    {!!compareAtPrice && +compareAtPrice > 0 ? (
      <div className="text-xs leading-[22px] text-[#FF0D00]">
        <Money value={parseFloat(price).toFixed(2)} currency={currency} />
      </div>
    ) : (
      <div className="text-xs leading-[22px]">
        <Money value={parseFloat(price).toFixed(2)} currency={currency} />
      </div>
    )}
    {!!compareAtPrice && +compareAtPrice > 0 && (
      <div className="text-xs leading-[22px] text-[#727272] line-through">
        <Money value={parseFloat(compareAtPrice).toFixed(2)} currency={currency} />
      </div>
    )}
  </div>
);

export default ProductCardPrice;
