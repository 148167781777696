import React from 'react';

const HistoryClockIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.03192 8.90008C4.01067 8.71088 4.00001 8.52065 4 8.33027C4.00061 7.34149 4.29437 6.3751 4.84415 5.55326C5.39392 4.73142 6.17503 4.09103 7.08871 3.71307C8.0024 3.3351 9.00763 3.23652 9.97734 3.42979C10.947 3.62307 11.8377 4.09951 12.5366 4.7989C13.2356 5.49828 13.7115 6.3892 13.9041 7.35902C14.0968 8.32884 13.9976 9.33402 13.6191 10.2475C13.2406 11.1609 12.5997 11.9416 11.7775 12.4909C10.9554 13.0402 9.98877 13.3333 9 13.3333"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.6665 7.33334L4.09914 9.33334L5.99984 7.66721"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6665 5.33334V8.66668H11.3332"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HistoryClockIcon;
