import createSubscriber from '@ui/axios/yotpo/createSubscriber';
import axios from 'axios';
import { useCallback, useState } from 'react';

type NewsletterSignupResponse = { message: string };

type NewsletterSignupPayload = {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  smsBump?: {
    account: string;
    listId: number;
    source: string;
  };
};

export default function useNewsLetterSignup() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NewsletterSignupResponse>();
  const [error, setError] = useState<string>();

  const submit = useCallback(async (payload: NewsletterSignupPayload) => {
    setIsLoading(true);
    setData(undefined);
    setError(undefined);

    // If the newsletter signup was initiated with a "phone" field, use the Yotpo's Create Subscriber API instead.
    // This will sync the customer data to Shopify so there is no need to call the "/api/customers/newsletter" endpoint.
    // @see https://core-api.yotpo.com/reference/create-subscriber

    if (payload.phone?.length && payload.smsBump) {
      await createSubscriber({
        listId: payload.smsBump.listId,
        account: payload.smsBump.account,
        source: payload.smsBump.source,
        email: payload.email,
        phone: payload.phone,
        firstName: payload.firstName,
        lastName: payload.lastName,
      });

      setData({ message: 'Thanks for subscribing.' });

      setIsLoading(false);

      return;
    }

    try {
      const res = await axios.post<NewsletterSignupResponse>('/api/customers/newsletter', payload);
      setData({ message: res.data.message });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        setError(error.response.data.message as string);
      } else {
        setError('Failed to join the newsletter');
      }
    }

    setIsLoading(false);
  }, []);

  return {
    isLoading,
    data,
    error,
    submit,
  };
}
