import { createStyles, getStylesRef } from '@mantine/core';
import { NO_HOVER_CLASS_NAME } from '../../../utils/constants';

const ProductCardSlider = createStyles((theme) => ({
  slideWrapper: {
    ref: getStylesRef('slideWrapper'),
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    [theme.fn.largerThan('sm')]: {
      [`&:not(.${NO_HOVER_CLASS_NAME}):hover .${getStylesRef('slideContent')}`]: {
        height: 'var(--slide-content-height)',
        opacity: 1,
      },
    },
  },
  visibleSlideWrapper: {
    opacity: 1,
    height: 'var(--slide-content-height)',
  },
  slideContent: {
    // The carousel is z-index 1. So put this one above it
    zIndex: 2,
    ref: getStylesRef('slideContent'),
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.8)',
    width: '100%',
    bottom: 0,
    height: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .4s',
    opacity: 0,
  },
  addedToBagContent: {
    zIndex: 2,
    ref: getStylesRef('slideContent'),
    position: 'absolute',
    background: theme.colors.brand[7],
    width: '100%',
    padding: '1rem 0.5rem 1rem 0.5rem',
    bottom: 0,
    transform: 'translateY(100%)',
    transitionDuration: '250ms',
  },
  singleButton: {
    height: '2rem',
    width: 'auto',
    [theme.fn.largerThan('xs')]: {
      height: '2.5rem',
    },
  },
  button: {
    width: '2rem',
    height: '2rem',

    [theme.fn.largerThan('xs')]: {
      width: '2.063rem',
      height: '2.063rem',
    },
  },
}));

export default ProductCardSlider;
