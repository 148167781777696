import { MantineTheme } from '@mantine/core';

const tabs = {
  styles: (theme: MantineTheme) => ({
    tabsList: {
      borderBottom: 'none',
      // justifyContent: 'center',
    },
    tab: {
      color: theme.colors.black[4],
      height: '2rem',
      padding: 0,
      ':hover': {
        background: 'transparent',
        border: 'none',
        // borderBottom: `0.0625rem solid ${theme.colors.black[4]}`,
      },
      '&[data-active]': {
        color: theme.black,
        border: 'none',
        // borderBottom: `0.0625rem solid ${theme.black}`,
      },
      '&[data-active]:hover': {
        color: theme.black,
        // borderBottom: `0.0625rem solid ${theme.black}`,
      },
    },
    tabLabel: {
      fontSize: '1rem',
      fontWeight: 600,
      // textTransform: 'uppercase',
    },
  }),
};

export default tabs;
