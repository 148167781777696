import React from 'react';

export default function SearchDeliveryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.33328 12C5.88557 12 6.33328 11.5523 6.33328 11C6.33328 10.4477 5.88557 10 5.33328 10C4.781 10 4.33328 10.4477 4.33328 11C4.33328 11.5523 4.781 12 5.33328 12Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 12C11.8856 12 12.3333 11.5523 12.3333 11C12.3333 10.4477 11.8856 10 11.3333 10C10.781 10 10.3333 10.4477 10.3333 11C10.3333 11.5523 10.781 12 11.3333 12Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 10.6667H6.33328M13.3333 9.66666V11H12.3333"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99995 4H3.33328V11H3.99995C3.99995 9.66667 5.33328 9.66667 5.33328 9.66667H9.99995V4Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6666 5.66666H9.99997V9.66666H13.3333V7.33332C13.3333 6.8913 13.1577 6.46737 12.8451 6.15481C12.5326 5.84225 12.1087 5.66666 11.6666 5.66666Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
