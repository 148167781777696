import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_ACCESS_TOKEN_DELETE } from '../../documents/mutations';
import type {
  CustomerAccessTokenDeleteMutation,
  CustomerAccessTokenDeleteMutationVariables,
} from '../../__generated__/graphql';

export async function CustomerAccessTokenDelete({
  customerAccessToken,
}: CustomerAccessTokenDeleteMutationVariables): Promise<{
  data: CustomerAccessTokenDeleteMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_ACCESS_TOKEN_DELETE, { customerAccessToken: customerAccessToken });
  return { data, error };
}
