import { gql } from '../../__generated__/gql';

export const GET_PRODUCT_PRICING = gql(`
  query getProductPricing($handle: String!, $country: CountryCode!) @inContext(country: $country) {
    product(handle: $handle) {
      id
      handle
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`);
