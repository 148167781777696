import React from 'react';

const ShippingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="37" height="48" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5424 15.3291C25.5425 15.9197 25.5427 17.2573 24.6573 18.958C23.5613 21.0636 21.5195 22.9591 18.5838 24.599C15.6482 22.9591 13.6066 21.0632 12.5104 18.958C11.6252 17.257 11.6253 15.9195 11.6255 15.3483C11.6255 13.2463 13.3129 11.5519 15.3871 11.5519C16.2793 11.5519 17.1442 11.8675 17.8223 12.4405L18.5837 13.0834L19.345 12.4405C20.0235 11.8674 20.8884 11.5519 21.7809 11.5519C23.8549 11.5519 25.5424 13.2463 25.5424 15.3291Z"
      fill="#F5BDC6"
      stroke="black"
    />
    <mask id="path-2-inside-1_3151_13545" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3191 18.2635C36.3191 8.51996 28.3704 0.571259 18.5414 0.571259C8.79782 0.571259 0.849121 8.51996 0.849121 18.2635C0.849121 25.1232 7.89604 34.6804 13.1528 41.8099C14.4459 43.5636 15.6307 45.1704 16.5756 46.5541C17.5157 47.9216 19.6525 47.9216 20.5927 46.5541C21.5376 45.1704 22.7223 43.5636 24.0154 41.8099C29.2722 34.6804 36.3191 25.1232 36.3191 18.2635ZM18.584 30.5797C25.5089 30.5797 31.1227 24.9659 31.1227 18.041C31.1227 11.1161 25.5089 5.5023 18.584 5.5023C11.6591 5.5023 6.04529 11.1161 6.04529 18.041C6.04529 24.9659 11.6591 30.5797 18.584 30.5797Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.3191 18.2635C36.3191 8.51996 28.3704 0.571259 18.5414 0.571259C8.79782 0.571259 0.849121 8.51996 0.849121 18.2635C0.849121 25.1232 7.89604 34.6804 13.1528 41.8099C14.4459 43.5636 15.6307 45.1704 16.5756 46.5541C17.5157 47.9216 19.6525 47.9216 20.5927 46.5541C21.5376 45.1704 22.7223 43.5636 24.0154 41.8099C29.2722 34.6804 36.3191 25.1232 36.3191 18.2635ZM18.584 30.5797C25.5089 30.5797 31.1227 24.9659 31.1227 18.041C31.1227 11.1161 25.5089 5.5023 18.584 5.5023C11.6591 5.5023 6.04529 11.1161 6.04529 18.041C6.04529 24.9659 11.6591 30.5797 18.584 30.5797Z"
      fill="#F5BDC6"
    />
    <path
      d="M13.1528 41.8099L12.348 42.4033L13.1528 41.8099ZM16.5756 46.5541L15.7498 47.118L15.7515 47.1206L16.5756 46.5541ZM20.5927 46.5541L21.4167 47.1206L21.4185 47.118L20.5927 46.5541ZM24.0154 41.8099L23.2106 41.2164L24.0154 41.8099ZM18.5414 1.57126C27.8213 1.57126 35.3191 9.07538 35.3191 18.2635H37.3191C37.3191 7.96454 28.9196 -0.428741 18.5414 -0.428741V1.57126ZM1.84912 18.2635C1.84912 9.07224 9.35011 1.57126 18.5414 1.57126V-0.428741C8.24554 -0.428741 -0.150879 7.96767 -0.150879 18.2635H1.84912ZM13.9577 41.2164C11.3233 37.6436 8.28029 33.5149 5.893 29.4309C3.48526 25.3119 1.84912 21.4073 1.84912 18.2635H-0.150879C-0.150879 21.9794 1.73644 26.2833 4.16635 30.4402C6.61671 34.6321 9.72554 38.8467 12.348 42.4033L13.9577 41.2164ZM17.4014 45.9901C16.4441 44.5884 15.2474 42.9655 13.9577 41.2164L12.348 42.4033C13.6444 44.1617 14.8172 45.7525 15.7498 47.118L17.4014 45.9901ZM19.7686 45.9875C19.2258 46.7771 17.9424 46.7771 17.3996 45.9875L15.7515 47.1206C17.0891 49.0661 20.0792 49.0661 21.4167 47.1206L19.7686 45.9875ZM23.2106 41.2164C21.9209 42.9655 20.7241 44.5884 19.7669 45.9901L21.4185 47.118C22.351 45.7525 23.5238 44.1617 24.8203 42.4033L23.2106 41.2164ZM35.3191 18.2635C35.3191 21.4073 33.683 25.3119 31.2752 29.4309C28.8879 33.5149 25.8449 37.6436 23.2106 41.2164L24.8203 42.4033C27.4427 38.8467 30.5515 34.6321 33.0019 30.4402C35.4318 26.2833 37.3191 21.9794 37.3191 18.2635H35.3191ZM30.1227 18.041C30.1227 24.4136 24.9566 29.5797 18.584 29.5797V31.5797C26.0612 31.5797 32.1227 25.5182 32.1227 18.041H30.1227ZM18.584 6.5023C24.9566 6.5023 30.1227 11.6684 30.1227 18.041H32.1227C32.1227 10.5638 26.0612 4.5023 18.584 4.5023V6.5023ZM7.04529 18.041C7.04529 11.6684 12.2113 6.5023 18.584 6.5023V4.5023C11.1068 4.5023 5.04529 10.5638 5.04529 18.041H7.04529ZM18.584 29.5797C12.2113 29.5797 7.04529 24.4136 7.04529 18.041H5.04529C5.04529 25.5182 11.1068 31.5797 18.584 31.5797V29.5797Z"
      fill="black"
      mask="url(#path-2-inside-1_3151_13545)"
    />
  </svg>
);

export default ShippingIcon;
