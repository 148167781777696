import track from '@ui/axios/searchSpring/track';
import env from '@ui/env';
import { AttributeInput } from '@client-shopify/gql/storefront/__generated__/graphql';
import useCartStore from '@ui/store/cartStore';
import { useRouter } from 'next/router';
import { useGetCart } from './useCartQuery';
import { useCartCreation } from './useCartMutations';
import { useCartUpdate, useCartAdd } from './useCartMutations';
import { getCustomerCountry } from './useCustomerCountry';
import React from 'react';

type AddToCartArgs = {
  onSuccess?: () => void;
};

export default function useAddToCart({ onSuccess }: AddToCartArgs = {}) {
  const router = useRouter();
  const cartId = useCartStore((state) => state?.cartId);
  const setCartId = useCartStore((state) => state?.setCartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);
  const { cartLinesAddMutation, cartAddError, cartAddLoading } = useCartAdd();
  const { data: cartData } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  const cartLines = cartData?.cart?.cartLines;
  const { cartCreateMutation, cartCreateError, cartCreateLoading } = useCartCreation();
  const { cartLineUpdateError, cartLineUpdateLoading, cartLinesUpdateMutation } = useCartUpdate();

  const [recentlyAddedToCart, setRecentlyAddedToCart] = React.useState(false);

  React.useEffect(() => {
    if (!recentlyAddedToCart) return;
    const timeoutId = setTimeout(() => setRecentlyAddedToCart(false), 3000)
    return () => clearTimeout(timeoutId);
  }, [recentlyAddedToCart]);

  function handleAddedToCart() {
    setCartUpdatedKey?.();
    onSuccess?.();
    setRecentlyAddedToCart(true);
  }

  async function addToCart({
    productVariantId,
    intellisuggestData,
    intellisuggestSignature,
    attributes,
  }: {
    productVariantId: string;
    intellisuggestData?: string;
    intellisuggestSignature?: string;
    attributes?: Array<AttributeInput>;
  }) {
    if (intellisuggestData && intellisuggestSignature) {
      track({
        data: intellisuggestData,
        signature: intellisuggestSignature,
        url: router.asPath,
        referrer: env.NEXT_PUBLIC_BASE_URL,
      });
    }

    if (!cartLines) {
      cartCreateMutation({
        cartCreateInput: {
          lines: [{ merchandiseId: productVariantId, quantity: 1, attributes }],
          buyerIdentity: {
            countryCode: getCustomerCountry(),
          },
        },
      }).then((result) => {
        if (cartCreateError) {
          throw new Error(cartCreateError.message);
        }

        if (!result?.cart?.cartLines?.length) {
          return;
        }

        setCartId?.(result.cart.id);

        handleAddedToCart();
      });
    }

    if (cartLines) {
      if (cartLines.length > 0) {
        const cartUpdateProduct = cartLines.find((line) => line.variantId === productVariantId);

        if (cartUpdateProduct) {
          cartLinesUpdateMutation({
            cartId: cartId || '',
            cartUpdateInput: {
              id: cartUpdateProduct.id,
              merchandiseId: cartUpdateProduct.variantId,
              quantity: cartUpdateProduct.quantity + 1,
            },
          }).then((result) => {
            if (cartLineUpdateError) {
              throw new Error(cartLineUpdateError.message);
            }

            if (!result?.cart?.cartLines?.length) {
              return;
            }

            handleAddedToCart();
          });
        } else {
          cartLinesAddMutation({
            cartId: cartId || '',
            cartLinesAddInput: [
              {
                merchandiseId: productVariantId,
                quantity: 1,
                attributes,
              },
            ],
          }).then((result) => {
            if (cartAddError) {
              throw new Error(cartAddError.message);
            }

            const cartLinesAdded = result?.cart?.cartLines;
            const cartLineAdded = cartLinesAdded?.find((line) => line.variantId === productVariantId);
            if (!cartLineAdded) return;

            handleAddedToCart();
          });
        }
      } else {
        cartLinesAddMutation({
          cartId: cartId || '',
          cartLinesAddInput: [
            {
              merchandiseId: productVariantId,
              quantity: 1,
              attributes,
            },
          ],
        }).then((result) => {
          if (cartAddError) {
            throw new Error(cartAddError.message);
          }
          const cartLinesAdded = result?.cart?.cartLines;
          const cartLineAdded = cartLinesAdded?.find((line) => line.variantId === productVariantId);
          if (!cartLineAdded) return;

          handleAddedToCart();
        });
      }
    }
  }

  return {
    addToCart,
    recentlyAddedToCart,
    cartLines: cartLines || [],
    isLoading: cartCreateLoading || cartLineUpdateLoading || cartAddLoading,
  };
}
