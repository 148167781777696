export const emarsysInnerScarabScriptFunc = `
  (function(id) {
    if (document.getElementById(id)) return;
    var js = document.createElement('script'); js.id = id;
    js.src = '//cdn.scarabresearch.com/js/17309B80C7260A5C/scarab-v2.js';
    var fs = document.getElementsByTagName('script')[0];
    fs.parentNode.insertBefore(js, fs);
  })('scarab-js-api');
`;

export const emarsysInnerScriptFunc = `var WebEmarsysSdk = WebEmarsysSdk || [];
      WebEmarsysSdk.push(['init', {
        enableLogging: true,
        applicationCode: 'EMS21-B5A60',
        safariWebsitePushID: 'web.com.hellomolly.au',
        defaultNotificationTitle: 'Hello Molly',
        defaultNotificationIcon: 'https://link-au.hellomolly.com/custloads/1042716357/md_20356.png',
        autoSubscribe: false,
        serviceWorker: {
          url: 'apps/ems/web-emarsys-sdk/4.3.1/service-worker.js',
          applicationServerPublicKey: 'BHRReaNxUTa3BH4Ibm--ihyOjjlCLUPMXuRkQJIkEndK8Mvld6eJ7PzqWnsAILAzL1sYkd2B8MR2gXFMlcrIHjg'
        }
      }])

`;
