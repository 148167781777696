import React from 'react';

export default function SearchReturnsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11106 2.66666H12.2222L13.9999 5.33332V13.3333H3.33328V5.33332L5.11106 2.66666Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.6662 2.66666V5.33332" stroke="#0D0005" strokeWidth="0.8" />
      <path d="M3.33328 5.33313H13.9999" stroke="#0D0005" strokeWidth="0.8" />
      <path
        d="M9.57086 11.6294L7.9819 10.0402L9.57086 8.44907"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9818 10.0392L11.0078 10.0392C11.4446 10.0392 11.8635 10.2127 12.1724 10.5216C12.4813 10.8305 12.6548 11.2494 12.6548 11.6862C12.6547 12.123 12.4811 12.5419 12.1722 12.8507C11.8634 13.1596 11.4445 13.3332 11.0078 13.3333"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
