import { useCallback, useEffect } from 'react';
import env from '@ui/env';

declare global {
  interface Window {
    wtba: any[];
    smsbump: any;
  }
}

function useYotpoDataLayer() {
  useEffect(() => {
    if (!window) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'config',
      options: {
        store_id: env.NEXT_PUBLIC_YOTPO_APP_KEY,
      },
    });
  }, []);

  const yotpoCustomerIdentifcation = useCallback((customerEmail: string, customerPhone?: string) => {
    if (!window) return;
    if (!customerEmail) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'identify',
      phone: customerPhone ?? '',
      email: customerEmail,
    });
    window.wtba.push({
      type: 'home',
    });
    if (window.smsbump && typeof window.smsbump.track === 'function') {
      window.smsbump
        .identify({ phone: customerPhone ?? '' })
        .then(() => {
          console.log('Visitor identified by phone.');
        })
        .catch(() => {
          console.log('Could not identify by phone.');
        });

      window.smsbump
        .identify({ email: customerEmail ?? '' })
        .then(() => {
          console.log('Visitor identified by email.');
        })
        .catch(() => {
          console.log('Could not identify by email.');
        });
      window.smsbump.subscribe({
        phone: customerPhone,
      });
    }
  }, []);

  const yotpoProductView = useCallback((productId: string) => {
    if (!window) return;
    if (!productId) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'product',
      id: productId.split('/').at(-1),
    });
    // Ensure smsbump is available and properly initialized
    if (window.smsbump && typeof window.smsbump.track === 'function') {
      // Track the product view event
      window.smsbump
        .track('pageview')
        .then(() => {})
        .catch(() => {});
    } else {
      console.warn('smsbump is not available or not properly initialized');
    }
  }, []);

  const yotpoAddedToCart = useCallback((productId: string, quantity: number) => {
    if (!window) return;
    if (!productId) return;
    window.wtba = window.wtba || [];
    window.wtba.push({
      type: 'event',
      event: 'product_added_to_cart',
      id: productId.split('/').at(-1),
      quantity,
    });
  }, []);

  return { yotpoCustomerIdentifcation, yotpoProductView, yotpoAddedToCart };
}

export default useYotpoDataLayer;
