import { gql } from '../../__generated__/gql';

export const CUSTOMER_ADDRESS_CREATE = gql(`
  mutation customerAddressCreate ($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate (customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
        firstName
        lastName
        phone
        company
        address1
        address2
        city
        country
        province
        zip
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
