import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_RECOVER } from '../../documents/mutations';
import type { CustomerRecoverMutation, CustomerRecoverMutationVariables } from '../../__generated__/graphql';

export async function CustomerRecover({ email }: CustomerRecoverMutationVariables): Promise<{
  data: CustomerRecoverMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_RECOVER, {
    email: email,
  });

  return { data, error };
}
