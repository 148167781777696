import { Sheet } from '@ui/components/core';
import { OutOfStockForm } from '@ui/components/shared';
import type { Product } from '@ui/types/models/Product';
import React from 'react';

export default function WhereIsMySizeSheet({
  open,
  onClose,
  product,
  initialVariantId,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  product: Product;
  initialVariantId?: string;
}) {
  const [successful, setSuccessful] = React.useState(false);

  const outOfStockVariants = product.variants
    ?.filter((v) => Number(v.quantityAvailable) < 1 || !v.availableForSale)
    .map((v) => ({ label: v.size, value: v.id }));

  return (
    <Sheet.Root open={open} onOpenChange={onClose}>
      <Sheet.Content side="dynamic" withCloseButton={false} className="isolate p-4 md:px-8 md:py-6 md:max-w-[493px]">
        <div className="pb-4 flex items-center justify-between border-b border-[#B3B3B3]">
          <Sheet.Title>{"Where's my size?"}</Sheet.Title>
          <Sheet.Close />
        </div>
        {!successful ? (
          <div className="mt-5">
            <div className="text-base text-black">
              <div className="font-semibold uppercase tracking-[0.004em]">{"Sorry! we're out of stock"}</div>
              <div className="mt-3.5 text-[14px] tracking-[0.6px] text-gray-700">
                {`Enter your email address below and we'll notify you when ${product.title} is available in your size again!`}
              </div>
            </div>
            <div className="mt-5">
              <OutOfStockForm
                onSuccess={() => setSuccessful(true)}
                sizeOptions={outOfStockVariants}
                initialSize={initialVariantId}
              />
            </div>
          </div>
        ) : (
          <div className="mt-5 min-h-[300px]">
            <div className="font-semibold uppercase tracking-[0.004em]">Thanks!</div>
            <div className="mt-3.5 text-[14px] tracking-[0.6px] text-gray-700">
              We will notify you when this product becomes available!
            </div>
          </div>
        )}
      </Sheet.Content>
    </Sheet.Root>
  );
}
