import axios from 'axios';
import env from '@ui/env';
import { Wishlist } from '@ui/types/contextObjects';

type CreateWishlistQueryReturnType = {
  success: boolean;
  data: Wishlist;
};

type CreateWishlistArgs = {
  customerId?: string;
  sessionId: string;
};

export const createWishlist = async ({ customerId, sessionId }: CreateWishlistArgs) => {
  const { data } = await axios.request<CreateWishlistQueryReturnType>({
    method: 'POST',
    url: `https://api.appmate.io/v1/shop/${env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/wishlist`,
    headers: {
      'content-type': 'application/json',
      'x-appmate-cid': `${customerId ? customerId : false}`,
      'x-appmate-afk': 'undefined',
      'x-appmate-sid': sessionId,
    },
    data: { name: 'My Wish List' },
  });

  if (!data.success) throw new Error('wishlist could not be created');

  const { data: wishlistResponse } = data;

  return wishlistResponse;
};
