import axios from 'axios';
import env from '@ui/env';

type preflightArgs = {
  method: 'GET' | 'POST';
  userId: string | null;
  shopper: string | null;
  cart: string | null;
  lastViewed: string | null;
  signal?: AbortSignal;
};

const preflight = async ({ method, userId, shopper, cart, lastViewed, signal }: preflightArgs) => {
  let requestUrl = `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}api/personalization/preflightCache?siteId=${env.NEXT_PUBLIC_SEARCHSPRING_SITEID}&userId=${userId}`;

  if (shopper) {
    requestUrl += `&shopper=${shopper}`;
  }

  if (cart) {
    requestUrl += `&cart=${cart}`;
  }

  if (lastViewed) {
    requestUrl += `&lastViewed=${lastViewed}`;
  }

  const { data } = await axios.request({
    method,
    url: requestUrl,
    signal,
    headers: {
      accept: 'application/json',
    },
  });

  return { data };
};

export default preflight;
