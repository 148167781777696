import React from 'react';

export default function DeliveryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6841_89310)">
        <path
          d="M7.51562 18.2773C8.34405 18.2773 9.01562 17.6058 9.01562 16.7773C9.01562 15.9489 8.34405 15.2773 7.51562 15.2773C6.6872 15.2773 6.01562 15.9489 6.01562 16.7773C6.01562 17.6058 6.6872 18.2773 7.51562 18.2773Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5156 18.2773C17.3441 18.2773 18.0156 17.6058 18.0156 16.7773C18.0156 15.9489 17.3441 15.2773 16.5156 15.2773C15.6872 15.2773 15.0156 15.9489 15.0156 16.7773C15.0156 17.6058 15.6872 18.2773 16.5156 18.2773Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0156 16.2773H9.01562M19.5156 14.7773V16.7773H18.0156"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5156 6.27734H4.51562V16.7773H5.51562C5.51562 14.7773 7.51562 14.7773 7.51562 14.7773H14.5156V6.27734Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0156 8.77734H14.5156V14.7773H19.5156V11.2773C19.5156 10.6143 19.2522 9.97842 18.7834 9.50958C18.3146 9.04074 17.6787 8.77734 17.0156 8.77734Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_89310">
          <rect width="16" height="16" fill="white" transform="translate(4.01562 4.27734)" />
        </clipPath>
      </defs>
    </svg>
  );
}
