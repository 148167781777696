import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_RESET_BY_URL } from '../../documents/mutations';
import type { CustomerResetByUrlMutation, CustomerResetByUrlMutationVariables } from '../../__generated__/graphql';

export async function CustomerResetByUrl({ password, resetUrl }: CustomerResetByUrlMutationVariables): Promise<{
  data: CustomerResetByUrlMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_RESET_BY_URL, {
    password: password,
    resetUrl: resetUrl,
  });

  return { data, error };
}
