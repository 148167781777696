import React from 'react';

const DeliveryIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_8551_19782)">
      <path
        d="M39.875 13.6063L23 6.125L6.125 13.6063V32.3938L23 39.875L39.875 32.3938V13.6063Z"
        stroke="#0D0005"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 13.6063L23 21.0875M23 21.0875L39.875 13.6063M23 21.0875V39.875M15.35 9.5L32.225 16.9812"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.375 22.7734L28.625 25.7547V32.1109L35.375 29.1297V22.7734Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8551_19782">
        <rect width="36" height="36" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default DeliveryIcon;
