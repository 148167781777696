import { gql } from '../../__generated__/gql';

export const CART_BUYER_IDENTITY_UPDATE = gql(`
  mutation cartBuyerIdentityUpdate ($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        id
        checkoutUrl
        buyerIdentity {
          countryCode
          email
          phone
          customer {
            id
          }
        }
        createdAt
        updatedAt
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`);
