import axios from 'axios';
import env from '@ui/env';

type trackArgs = {
  data: string;
  signature: string;
  url: string;
  referrer: string;
};

const track = async ({ data, signature, url, referrer }: trackArgs) => {
  axios.request({
    method: 'POST',
    url: `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}api/track/track.json?d=${data}&s=${signature}&u=${url}&r=${referrer}`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
  });
};

export default track;
