/* eslint-disable no-nested-ternary */
import { createStyles, MantineTheme } from '@mantine/core';

const linkStyles = createStyles(
  (theme: MantineTheme, { color, bold, uppercase }: { color?: string; bold?: boolean; uppercase?: boolean }) => ({
    uppercase: {
      textTransform: 'uppercase',
    },
    noDecoration: {
      fontSize: '0.875rem',
      fontWeight: !bold ? 400 : 600,
      textTransform: !uppercase ? 'none' : 'uppercase',
      color: color || theme.black,
      textDecoration: 'none',
    },
    highlight: {
      color: color || theme.black,
      fontSize: '0.875rem',
      letterSpacing: '0.05rem',
      textDecoration: 'none',
      transition: 'background-color .3s',
      ':focus': {
        boxShadow: 'none',
      },
      ':hover': {
        backgroundColor: theme.colors.brand[5],
      },
    },
    underline: {
      position: 'relative',
      color: color || theme.black,
      fontSize: '0.875rem',
      fontWeight: !bold ? 400 : 600,
      textTransform: !uppercase ? 'none' : 'uppercase',
      letterSpacing: '0.05rem',
      textDecoration: 'none',
      ':before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '0.0938rem',
        backgroundColor: color || theme.black,
        bottom: '-5px',
        left: 0,
        opacity: 0,
        transition: 'opacity .3s',
      },
      ':hover::before': {
        opacity: 1,
      },
    },
    underlineStatic: {
      '::before': {
        opacity: 1,
      },
    },
    titleLink: {
      position: 'relative',
      color: color || theme.black,
      fontSize: '1.2rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
      textDecoration: 'none',
      ':before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '1px',
        backgroundColor: theme.black,
        bottom: 0,
        left: 0,
        transformOrigin: 'right',
        transform: 'scaleX(0)',
        transition: 'transform .3s ease-in-out',
      },
      ':hover::before': {
        transformOrigin: 'left',
        transform: 'scaleX(1)',
        opacity: 1,
      },
    },
  }),
);

export default linkStyles;
