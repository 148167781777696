import { Carousel, LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import env from '@ui/env';
import useBeacon from '@ui/hooks/useBeacon';
import { CartProduct } from '@ui/store/cartStore';
import { useQuery } from '@tanstack/react-query';
import { CarouselProps, useAnimationOffsetEffect } from '@mantine/carousel';
import CartUpsellCard from './CartUpsellCard';
import cn from '@ui/utils/cn';
import CartUpsellCardSkeleton from './CartUpsellCardSkeleton';
import { GetCollection, GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import { ProductCollectionSortKeys } from '@client-shopify/gql/storefront/__generated__/graphql';

export type CartUpsellAddToCartItem = Omit<CartProduct, 'price'>;

type CartUpsellCarouselProps = {
  className?: string;
  classNames?: CarouselProps['classNames'];
  beaconPlacement: 'cart' | 'product-page';
};

const CartUpsellCarousel = ({ className, classNames, beaconPlacement }: CartUpsellCarouselProps) => {
  const { data: cartUpsellProducts, isLoading } = useQuery({
    queryKey: ['cartUpsellProducts'],
    queryFn: async () => {
      const result = await GetCollection({
        handle: 'promoted-products',
        sortKey: ProductCollectionSortKeys.BestSelling,
        first: 20,
      });
      const edges = result.data?.collection?.products.edges;
      if (!edges || edges.length === 0) return [];

      const products = edges.map((edge) => {
        const product = {
          imageUrl: edge.node.images.edges[0].node.url,
          title: edge.node.title,
          handle: edge.node.handle,
          price: edge.node.priceRange.maxVariantPrice.amount,
          currency: edge.node.priceRange.maxVariantPrice.currencyCode,
          id: edge.node.id.split('/').pop()!,
          sizes: edge.node.variants.edges.map((variant) => ({
            id: variant.node.id.split('/').pop()!,
            size: variant.node.title,
          })),
        };
        return product;
      });

      if (env.MULTICURRENCY_FEATURE && products.length > 0) {
        const productsPricing = await GetProductsPricing({
          first: products.length,
          query: products.map((p) => `id:${p.id}`).join(' OR '),
          country: getCustomerCountry(),
        });
        products.forEach((product) => {
          const productEdges = productsPricing.data?.products.edges || [];
          const productPrice = productEdges.find((edge) => edge.node.id.split('/').pop() === product.id);
          if (!productPrice) return;
          product.price = productPrice.node.priceRange.maxVariantPrice.amount;
          product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
        });
      }

      return products;
    },
  });

  const { ref, embla, setEmbla } = useBeacon({
    tag: 'cart-upsell',
    placement: beaconPlacement,
    products: (cartUpsellProducts || []).map((product) => ({
      id: product.id,
    })),
  });

  useAnimationOffsetEffect(embla as any, 500);

  if (!env.CART_UPSELL_FEATURE) {
    return null;
  }

  if (!isLoading && !cartUpsellProducts?.length) {
    return null;
  }

  return (
    <Carousel
      ref={ref}
      withControls
      loop
      align="start"
      getEmblaApi={setEmbla as any}
      nextControlIcon={<RightChevronIcon height={28} width={28} />}
      previousControlIcon={<LeftChevronIcon height={28} width={28} />}
      className={className}
      classNames={{
        ...classNames,
        controls: cn('flex', classNames?.controls),
        control: cn('bg-transparent', classNames?.control),
        slide: cn('basis-auto w-full border-[0.6px] p-3 border-black bg-white mx-2', classNames?.slide),
      }}
    >
      {isLoading ? (
        <Carousel.Slide>
          <CartUpsellCardSkeleton />
        </Carousel.Slide>
      ) : (
        cartUpsellProducts?.map((cartUpsellProduct) => (
          <Carousel.Slide key={cartUpsellProduct.id}>
            <CartUpsellCard
              cartUpsellProduct={cartUpsellProduct}
              showAddIcon={beaconPlacement === 'product-page'}
              embla={embla}
            />
          </Carousel.Slide>
        ))
      )}
    </Carousel>
  );
};

export default CartUpsellCarousel;
