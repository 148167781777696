import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CART_LINES_REMOVE } from '../../documents/mutations';
import { type CartLinesRemoveMutation, type CartLinesRemoveMutationVariables } from '../../__generated__/graphql';
import { mapCartData } from '../../utils/MapCartData.ts';

export async function CartLinesRemove({ cartId, cartLineIds }: CartLinesRemoveMutationVariables) {
  const { data, error } = await RunMutation<CartLinesRemoveMutation>(CART_LINES_REMOVE, {
    cartId: cartId,
    cartLineIds: cartLineIds,
  });
  if (error) {
    return { cart: undefined, error };
  }

  if (!data?.cartLinesRemove?.cart) {
    return { cart: undefined, error: { message: 'Cart not found' } };
  }

  const cartData = data.cartLinesRemove?.cart;

  const cart = mapCartData(cartData);

  return { cart, error };
}
