export enum FooterMenuRoutes {
  'ABOUT US' = '/pages/about-us/',
  'CAREERS' = '/pages/careers/',
  'REVIEWS' = '/pages/reviews/',
  'BLOG' = '/blogs/moments/',
  'BRANDS' = '/pages/brands/',
  'COMPETITIONS' = '/pages/competitions/',
  'CONTACT US' = '/pages/contact-us/',
  'FAQ' = '/pages/faq/',
  'SHIPPING' = '/pages/shipping/',
  'RETURNS' = '/pages/returns/',
  'SIZE GUIDE' = '/pages/size-guide/',
  'WEAR NOW PAY LATER' = '/pages/wear-now-pay-later/',
  'UNIDAYS' = '/pages/unidays/',
}
