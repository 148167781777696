import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useCustomerLocation() {
  const { data } = useQuery({
    queryKey: ['customerLocation'],
    queryFn: async () => (await axios.get<{ country: string }>('/api/location')).data,
  });

  return data;
}
