import { gql } from '../../__generated__/gql';

export const CUSTOMER_CREATE = gql(`
  mutation customerCreate ($customerCreateInput: CustomerCreateInput!) {
    customerCreate (input: $customerCreateInput) {
      customer {
        id
        email
        displayName
        firstName
        lastName
        phone
        acceptsMarketing
        updatedAt
        createdAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
