import React from 'react';

const AccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.7642 11.4683C19.957 12.9126 20.3067 17.283 20.2553 19.1523C20.2417 19.6495 19.824 20.0154 19.3266 20.0154H5.41661C4.89474 20.0154 4.46631 19.6157 4.48848 19.0943C4.5676 17.2336 5.20538 13.0757 9.21507 11.5612"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <circle cx="12.3125" cy="9.11621" r="4.10303" stroke="currentColor" strokeWidth="0.8" />
  </svg>
);

AccountIcon.defaultProps = {
  onClick: undefined,
};

export default AccountIcon;
