import { gql } from '../../__generated__/gql';

export const CUSTOMER_RESET = gql(`
  mutation customerReset ($customerId: ID!, $input: CustomerResetInput!) {
    customerReset (id: $customerId, input: $input) {
      customer {
        id
        email
        displayName
        firstName
        lastName
        phone
        acceptsMarketing
        updatedAt
        createdAt
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
