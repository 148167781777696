import React from 'react';
import { Accordion, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQOrderIssues = (): React.ReactElement => (
  <FAQAccordion handle="order-issues">
    {useStaticContent('FAQ.OrderIssues.GiftVouchersCancel') && (
      <Accordion.Item value="gift-vouchers-cancel">
        <Accordion.Control>Cancelling gift vouchers</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.GiftVouchersCancel" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.OrderCancel') && (
      <Accordion.Item value="order-cancel">
        <Accordion.Control>Cancelling your order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.OrderCancel" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.OrderChange') && (
      <Accordion.Item value="order-change">
        <Accordion.Control>Changing your order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.OrderChange" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.Customs') && (
      <Accordion.Item value="customs">
        <Accordion.Control>Customs</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.Customs" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.IncorrectItem') && (
      <Accordion.Item value="incorrect-item">
        <Accordion.Control>Incorrect item in your order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.IncorrectItem" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.MissingItem') && (
      <Accordion.Item value="missing-item">
        <Accordion.Control>Missing an item from your order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.MissingItem" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.OrderVerification') && (
      <Accordion.Item value="order-verification">
        <Accordion.Control>Order verification and fraud checks</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.OrderVerification" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.OutOfStock') && (
      <Accordion.Item value="out-of-stock">
        <Accordion.Control>Out of stock items post-order</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.OutOfStock" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.FaultyItem') && (
      <Accordion.Item value="faulty-item">
        <Accordion.Control>Received a faulty item</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.FaultyItem" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.PriceDifferenceRefund') && (
      <Accordion.Item value="price-difference-refund">
        <Accordion.Control>Refunding the price difference</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.PriceDifferenceRefund" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.OrderIssues.ScamAndFraud') && (
      <Accordion.Item value="scam-and-fraud">
        <Accordion.Control>Scam websites & fraud</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.OrderIssues.ScamAndFraud" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQOrderIssues;
