import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_CUSTOMER } from '../../documents/queries';
import type { GetCustomerQuery, GetCustomerQueryVariables } from '../../__generated__/graphql';

export async function GetCustomer({ customerAccessToken }: GetCustomerQueryVariables): Promise<{
  data: GetCustomerQuery | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunQuery(GET_CUSTOMER, { customerAccessToken: customerAccessToken });
  return { data, error };
}
