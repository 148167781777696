import React from 'react';
import { Badge, BadgeProps, Box } from '../../../core';
import NotificationBadgeStyles from '../../../styles/Badge/NotificationBadge.styles';

type NotificationBadgeProps = BadgeProps & {
  children: React.ReactNode;
  label?: React.ReactNode;
  show: boolean;
  animate: boolean;
};

const NotificationBadge = React.forwardRef<HTMLDivElement | null, NotificationBadgeProps>(
  ({ show, animate, label, children, ...props }, ref): React.ReactElement => {
    const { classes } = NotificationBadgeStyles({ show, animate });

    return (
      <Box ref={ref} pos="relative">
        {children}
        <Badge {...props} variant="filled" classNames={classes}>
          {label}
        </Badge>
      </Box>
    );
  },
);

NotificationBadge.displayName = 'NotificationBadge';

NotificationBadge.defaultProps = {
  label: undefined,
};

export default NotificationBadge;
