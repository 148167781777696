import React from 'react';

const CompactCloseIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.126173 9.87363C0.294403 10.0419 0.567158 10.0419 0.735389 9.87363L4.99984 5.60917L9.2645 9.87383C9.43273 10.0421 9.70548 10.0421 9.87371 9.87383C10.0419 9.7056 10.0419 9.43284 9.87371 9.26461L5.60906 4.99996L9.87363 0.735389C10.0419 0.567158 10.0419 0.294403 9.87363 0.126173C9.7054 -0.0420576 9.43264 -0.0420576 9.26441 0.126173L4.99984 4.39074L0.735474 0.126374C0.567243 -0.0418562 0.294488 -0.0418561 0.126258 0.126374C-0.0419724 0.294604 -0.0419724 0.56736 0.126258 0.73559L4.39063 4.99996L0.126173 9.26441C-0.0420576 9.43264 -0.0420575 9.7054 0.126173 9.87363Z"
      fill="black"
    />
  </svg>
);

export default CompactCloseIcon;
