import { gql } from '../../__generated__/gql';

export const GET_PRODUCTS_FILTER = gql(`
  query getProductsFilter ($query: String!, $first: Int, $last: Int, $after: String, $before: String, $sortKey: ProductSortKeys) {
    products (query: $query, first: $first, last: $last, after: $after, before: $before, sortKey: $sortKey) {
      edges {
        cursor
        node {
          id
          handle
          title
          vendor
          productType
          availableForSale
          tags
          images (first: 6) {
            edges {
              node {
                id
                url
                width
                height
                altText
              }
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          variants (first: 6) {
            edges {
              node {
                id
                title
                quantityAvailable
                availableForSale
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
              }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        hasPreviousPage
        endCursor
      }
    }
  }
`);
