const notification = {
  styles: () => ({
    root: {
      padding: '0.625rem',
      borderRadius: 0,
      '::before': {
        display: 'none',
      },
    },

    description: {
      fontWeight: 900,
      fontSize: '0.875rem',
      textAlign: 'center' as const,
      letterSpacing: '0.04em',
    },

    closeButton: {
      svg: {
        width: '1.5rem !important',
        height: '1.5rem !important',
      },
    },
  }),

  variants: {
    success: () => ({
      root: {
        backgroundColor: '#539a64',
      },

      description: {
        color: '#fff',
      },

      closeButton: {
        color: '#fff',
        ':hover': {
          color: '#fff',
          backgroundColor: '#539a64',
        },
      },
    }),

    danger: () => ({
      root: {
        backgroundColor: '#FB3434',
      },

      description: {
        color: '#fff',
      },

      closeButton: {
        color: '#fff',
        ':hover': {
          color: '#fff',
          backgroundColor: '#FB3434',
        },
      },
    }),

    warning: () => ({
      root: {
        backgroundColor: '#FB9F34',
      },

      description: {
        color: '#fff',
      },

      closeButton: {
        color: '#fff',
        ':hover': {
          color: '#fff',
          backgroundColor: '#FB9F34',
        },
      },
    }),
  },
};

export default notification;
