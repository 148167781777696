import type { SSViewedProductPayload } from '../../types/actions';

export type SSViewedProductAction = {
  type: string;
  payload: SSViewedProductPayload;
};

export const SSViewedProductReducer = (state: any, { type, payload }: SSViewedProductAction) => {
  switch (type) {
    case 'SS_VIEWED_PRODUCT_SET':
      return {
        id: payload.id,
        uid: payload.uid,
        sku: payload.sku,
        intellisuggestData: payload.intellisuggestData,
        intellisuggestSignature: payload.intellisuggestSignature,
        variantSkus: payload.variantSkus,
        ss_sizes: payload.ss_sizes,
        published_at: payload.published_at,
      };
    default:
      return state;
  }
};
