import React from 'react';

const PayingIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.43388 21.1253L8.05888 17.3128C7.98867 17.1282 7.95563 16.9315 7.96163 16.734C7.96764 16.5366 8.01258 16.3423 8.09388 16.1623C8.17518 15.9822 8.29124 15.82 8.43539 15.685C8.57954 15.5499 8.74895 15.4447 8.93388 15.3753L34.3089 6.18779C34.4935 6.11758 34.6902 6.08453 34.8876 6.09054C35.0851 6.09654 35.2794 6.14149 35.4594 6.22279C35.6394 6.30409 35.8016 6.42015 35.9367 6.5643C36.0717 6.70845 36.177 6.87785 36.2464 7.06279L41.6839 22.0628C41.7541 22.2474 41.7871 22.4441 41.7811 22.6415C41.7751 22.839 41.7302 23.0333 41.6489 23.2133C41.5676 23.3933 41.4515 23.5555 41.3074 23.6906C41.1632 23.8256 40.9938 23.9309 40.8089 24.0003L34.2464 26.3753M9.30888 20.6253L37.3714 10.3753M32.1839 16.5003L34.9964 15.5003"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.75 21.125H5.75C4.92157 21.125 4.25 21.7966 4.25 22.625V38.375C4.25 39.2034 4.92157 39.875 5.75 39.875H32.75C33.5784 39.875 34.25 39.2034 34.25 38.375V22.625C34.25 21.7966 33.5784 21.125 32.75 21.125Z"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 29.875H18M25.5 26.125H29.25M9.25 34.875H10.5M15.5 34.875H16.75M21.75 34.875H23M28 34.875H29.25"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PayingIcon;
