import React from 'react';

export default function AccountStoreCreditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2847 10.6938C14.2059 10.2278 13.9646 9.80474 13.6036 9.49968C13.2426 9.19462 12.7852 9.02728 12.3125 9.02734C11.208 9.02734 10.3125 9.75584 10.3125 10.8606C10.3125 11.9653 11.3125 12.4656 12.3125 12.6941C13.3125 12.9191 14.3125 13.4233 14.3125 14.5276C14.3125 15.6318 13.417 16.3608 12.3125 16.3608C11.7821 16.3608 11.2734 16.1501 10.8983 15.7751C10.5232 15.4 10.3125 14.8913 10.3125 14.3608"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3125 9.02711V7.69336M12.3125 17.6941V16.3604M12.3125 5.81136L13.8042 5.19336L14.9462 6.33511L16.561 6.33536L17.1788 7.82711L18.671 8.44536V10.0599L19.8125 11.2016L19.1947 12.6934L19.8125 14.1854L18.671 15.3274V16.9419L17.1788 17.5599L16.561 19.0516L14.9462 19.0519L13.8042 20.1939L12.3125 19.5759L10.8208 20.1939L9.67875 19.0519L8.064 19.0516L7.44625 17.5599L5.954 16.9419V15.3274L4.8125 14.1854L5.43025 12.6934L4.8125 11.2016L5.954 10.0599V8.44536L7.44625 7.82711L8.064 6.33536L9.67875 6.33511L10.8208 5.19336L12.3125 5.81136Z"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
