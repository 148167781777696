import React from 'react';
import {
  CloseButtonProps,
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalOverlayProps,
} from '@mantine/core';
import { Box } from '../Box/Box';

type ModalProps = Omit<MantineModalProps, 'title'> & {
  header?: React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
  overlayProps?: ModalOverlayProps;
  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  closeButtonProps?: CloseButtonProps;
  bodyProps?: ModalBodyProps;
};

const Modal = ({
  header,
  body,
  footer,
  withOverlay,
  withCloseButton,
  overlayProps,
  contentProps,
  headerProps,
  closeButtonProps,
  bodyProps,
  ...props
}: ModalProps): React.ReactElement => (
  <MantineModal.Root {...props}>
    {withOverlay && <MantineModal.Overlay {...overlayProps} />}
    <MantineModal.Content {...contentProps}>
      {header ||
        (withCloseButton && (
          <MantineModal.Header {...headerProps}>
            {header && <MantineModal.Title>{header}</MantineModal.Title>}
            {withCloseButton && <MantineModal.CloseButton {...closeButtonProps} />}
          </MantineModal.Header>
        ))}
      <MantineModal.Body {...bodyProps}>{body}</MantineModal.Body>
      {footer && (
        <Box pos="sticky" bottom={0} bg="brand" px="lg" py="lg">
          {footer}
        </Box>
      )}
    </MantineModal.Content>
  </MantineModal.Root>
);

Modal.defaultProps = {
  header: undefined,
  footer: undefined,
  overlayProps: undefined,
  contentProps: undefined,
  headerProps: undefined,
  closeButtonProps: undefined,
  bodyProps: undefined,
};

export default Modal;
