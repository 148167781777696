import { gql } from '../../__generated__/gql';

export const CUSTOMER_ACCESS_TOKEN_RENEW = gql(`
  mutation customerAccessTokenRenew ($customerAccessToken: String!) {
    customerAccessTokenRenew (customerAccessToken: $customerAccessToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      userErrors {
        field
        message
      }
    }
  }
`);
