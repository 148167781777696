import type { ActionMap } from './ActionMap.type';
import { SSProduct } from '../contextObjects';

export enum SSViewedProductAction {
  Set = 'SS_VIEWED_PRODUCT_SET',
}

export type SSViewedProductPayload = SSProduct;

export type SSViewedProductReducer = {
  [SSViewedProductAction.Set]: SSViewedProductPayload;
};

export type SSViewedProductActions = ActionMap<SSViewedProductReducer>[keyof ActionMap<SSViewedProductReducer>];
