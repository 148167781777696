import axios, { AxiosResponse } from 'axios';
import env from '@ui/env';

type personalizedRecommendationsArgs = {
  tags: string;
  shopper: string | null;
  productId?: string;
  categories?: string;
  brands?: string;
  cart: string | null;
  lastViewed: string | null;
  limits?: number;
};

const personalizedRecommendations = async ({
  tags,
  shopper,
  productId,
  categories,
  brands,
  cart,
  lastViewed,
  limits,
}: personalizedRecommendationsArgs) => {
  let requestUrl = `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}boost/${env.NEXT_PUBLIC_SEARCHSPRING_SITEID}/recommend?tags=${tags}`;

  if (productId) {
    requestUrl += `&product=${productId}`;
  }

  if (categories) {
    requestUrl += `&categories=${categories}`;
  }

  if (brands) {
    requestUrl += `&brands=${brands}`;
  }

  if (shopper) {
    requestUrl += `&shopper=${shopper}`;
  }

  if (cart) {
    requestUrl += `&cart=${cart}`;
  }

  if (lastViewed) {
    requestUrl += `&lastViewed=${lastViewed}`;
  }

  const { data } = await axios.request({
    method: 'GET',
    url: `${requestUrl}&limits=${limits || 24}`,
    headers: {
      accept: 'application/json',
    },
  });

  return { data } as AxiosResponse;
};

export default personalizedRecommendations;
