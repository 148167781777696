import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_PRODUCTS_FILTER } from '../../documents/queries';
import { GetProductsFilterQuery, GetProductsFilterQueryVariables } from '../../__generated__/graphql';

export async function GetProductsFilter({
  query,
  first,
  last,
  after,
  before,
  sortKey,
}: GetProductsFilterQueryVariables): Promise<{ data: GetProductsFilterQuery; error: CombinedError | undefined }> {
  const { data, error } = await RunQuery(GET_PRODUCTS_FILTER, {
    query: query,
    first: first,
    last: last,
    after: after,
    before: before,
    sortKey: sortKey,
  });

  return { data, error };
}
