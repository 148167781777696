import React from 'react';

export default function TechnicalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6841_89338)">
        <path
          d="M11.857 12.1183C12.7356 11.2397 12.7356 9.81503 11.857 8.93635C10.9783 8.05767 9.55365 8.05767 8.67497 8.93635C7.79629 9.81503 7.79629 11.2397 8.67497 12.1183C9.55365 12.997 10.9783 12.997 11.857 12.1183Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8211 11.7774L16.0041 11.0941C16.0243 11.0197 16.0189 10.9406 15.9889 10.8696C15.9588 10.7986 15.9058 10.7397 15.8384 10.7024L14.6346 10.0341C14.5819 9.6018 14.4646 9.17985 14.2866 8.78236L15.0036 7.58761C15.0432 7.52165 15.0596 7.44436 15.0501 7.36803C15.0407 7.2917 15.006 7.22071 14.9516 7.16636L13.6266 5.84136C13.5722 5.78698 13.5012 5.75235 13.4249 5.74297C13.3486 5.7336 13.2713 5.75001 13.2054 5.78961L11.9659 6.53311C11.5932 6.38052 11.2013 6.28032 10.8011 6.23536L10.0909 4.95486C10.0536 4.8876 9.99483 4.83475 9.92403 4.80472C9.85322 4.77468 9.77441 4.76919 9.70012 4.78911L7.88762 5.27461C7.81332 5.29451 7.74781 5.33869 7.70151 5.40012C7.65521 5.46155 7.63078 5.53669 7.63212 5.61361L7.65712 7.10436C7.34997 7.33788 7.07589 7.61196 6.84237 7.91911L5.35162 7.89411C5.27471 7.89277 5.19956 7.91719 5.13813 7.96349C5.0767 8.00979 5.03252 8.0753 5.01262 8.14961L4.52762 9.96061C4.50746 10.035 4.51283 10.1141 4.54287 10.1851C4.57292 10.2561 4.62591 10.315 4.69337 10.3524L5.97387 11.0631C6.01887 11.4631 6.11907 11.8549 6.27162 12.2274L5.52812 13.4661C5.48853 13.532 5.47211 13.6093 5.48149 13.6856C5.49087 13.762 5.5255 13.833 5.57987 13.8874L6.90487 15.2124C6.95922 15.2668 7.03021 15.3014 7.10654 15.3109C7.18288 15.3203 7.26017 15.3039 7.32612 15.2644L8.52087 14.5474C8.91845 14.7253 9.34048 14.8426 9.77287 14.8954L10.4409 16.0999C10.4782 16.1671 10.5369 16.22 10.6077 16.25C10.6785 16.28 10.7573 16.2855 10.8316 16.2656L12.6429 15.7804L12.6616 15.7746"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7656 17.2773C16.5941 17.2773 17.2656 16.6058 17.2656 15.7773C17.2656 14.9489 16.5941 14.2773 15.7656 14.2773C14.9372 14.2773 14.2656 14.9489 14.2656 15.7773C14.2656 16.6058 14.9372 17.2773 15.7656 17.2773Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7602 16.2523C18.8092 15.9376 18.8092 15.6171 18.7602 15.3023L19.442 14.6431C19.4808 14.6055 19.506 14.556 19.5136 14.5025C19.5211 14.4489 19.5105 14.3944 19.4835 14.3476L18.8242 13.2058C18.7972 13.159 18.7552 13.1226 18.7051 13.1024C18.6549 13.0821 18.5995 13.0792 18.5475 13.0941L17.617 13.3603C17.3759 13.1714 17.1078 13.0197 16.8217 12.9103L16.5842 11.9603C16.571 11.908 16.5406 11.8616 16.498 11.8284C16.4554 11.7953 16.403 11.7773 16.349 11.7773H15.0307C14.9766 11.7773 14.9241 11.7954 14.8815 11.8287C14.8388 11.862 14.8086 11.9086 14.7955 11.9611L14.549 12.9468C14.2879 13.0561 14.0433 13.201 13.822 13.3773L12.832 13.0941C12.78 13.0793 12.7246 13.0822 12.6745 13.1024C12.6244 13.1227 12.5825 13.1591 12.5555 13.2058L11.8962 14.3476C11.8692 14.3944 11.8586 14.4489 11.8661 14.5025C11.8737 14.556 11.8989 14.6055 11.9377 14.6431L12.6907 15.3723C12.6541 15.6411 12.6541 15.9136 12.6907 16.1823L11.9372 16.9108C11.8984 16.9484 11.8732 16.9979 11.8656 17.0515C11.8581 17.105 11.8687 17.1595 11.8957 17.2063L12.555 18.3481C12.582 18.3949 12.6239 18.4313 12.674 18.4515C12.7241 18.4717 12.7795 18.4747 12.8315 18.4598L13.8215 18.1766C14.0428 18.3532 14.2875 18.4982 14.5487 18.6076L14.7952 19.5933C14.8083 19.6459 14.8386 19.6926 14.8813 19.7259C14.9239 19.7593 14.9766 19.7774 15.0307 19.7773H16.3492C16.4033 19.7774 16.4558 19.7593 16.4984 19.7261C16.541 19.6928 16.5713 19.6463 16.5845 19.5938L16.822 18.6438C17.1081 18.5345 17.3762 18.3828 17.6172 18.1938L18.5477 18.4601C18.5997 18.475 18.6552 18.4721 18.7053 18.4518C18.7555 18.4316 18.7974 18.3952 18.8245 18.3483L19.4837 17.2066C19.5107 17.1598 19.5213 17.1052 19.5138 17.0517C19.5063 16.9982 19.4811 16.9487 19.4422 16.9111L18.7602 16.2523Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_89338">
          <rect width="16" height="16" fill="white" transform="translate(4.01562 4.27734)" />
        </clipPath>
      </defs>
    </svg>
  );
}
