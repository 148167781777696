import { gql } from '../../__generated__/gql';

export const CUSTOMER_RESET_BY_URL = gql(`
  mutation customerResetByUrl ($password: String!, $resetUrl: URL!) {
    customerResetByUrl (password: $password, resetUrl: $resetUrl) {
      customer {
        id
        email
        displayName
        firstName
        lastName
        phone
        acceptsMarketing
        updatedAt
        createdAt
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
