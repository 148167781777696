import { createStyles, MantineTheme } from '@mantine/core';

const responsiveStyles = createStyles((theme: MantineTheme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
}));

export default responsiveStyles;
