export const collectionHandleMappings = {
  US: [
    ['mini-dress', 'mini-dresses'],
    ['midi-dresses', 'midi-dress'],
    ['maxi-dresses', 'maxi-dress'],
    ['playsuits', 'rompers-jumpsuits'],
    ['category_playsuits', 'category_rompers'],
  ],
  GB: [
    ['mini-dress', 'mini-dresses'],
    ['midi-dresses', 'midi-dress'],
    ['maxi-dresses', 'maxi-dress'],
    ['playsuits', 'rompers-jumpsuits'],
    ['category_playsuits', 'category_rompers'],
  ],
  AU: [
    ['mini-dresses', 'mini-dress'],
    ['midi-dresses', 'midi-dress'],
    ['maxi-dresses', 'maxi-dress'],
    ['rompers-jumpsuits', 'playsuits'],
    ['category_rompers', 'category_playsuits'],
  ],
  NZ: [
    ['mini-dress', 'mini-dresses'],
    ['midi-dress', 'midi-dresses'],
    ['maxi-dress', 'maxi-dresses'],
    ['rompers-jumpsuits', 'playsuits'],
    ['category_rompers', 'category_playsuits'],
  ],
};

export const getUpdatedPath = (path: string, region: keyof typeof collectionHandleMappings) => {
  let updatedPath = path.split('/');
  const collectionHandle = updatedPath[updatedPath.length - 1];
  const mappings = collectionHandleMappings[region];

  mappings.forEach(([oldHandle, newHandle]) => {
    if (collectionHandle === oldHandle) {
      updatedPath[updatedPath.length - 1] = newHandle;
    }
  });
  return updatedPath.join('/');
};
