import React from 'react';

const CircleCheckIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="20" height="20" rx="10" fill="white" />
    <path
      d="M5 10.3125L8.4375 13.75L15 6.875"
      stroke="#23A047"
      strokeWidth="0.89"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheckIcon;
