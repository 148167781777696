import {
  BeaconProduct,
  createBeaconProfile,
  sendBeaconClickImpressionEvent,
  sendBeaconImpressionEvent,
  sendBeaconRenderEvent,
} from '@ui/axios/searchSpring/beacon';
import { EmblaCarouselType } from 'embla-carousel';
import { useIntersection } from '@mantine/hooks';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export default function useBeacon({
  tag,
  placement,
  products,
}: {
  tag: string;
  placement: string;
  products: Array<BeaconProduct>;
}): {
  ref: (element: any) => void;
  embla: EmblaCarouselType | undefined;
  setEmbla: Dispatch<SetStateAction<EmblaCarouselType | undefined>>;
} {
  const beaconProfile = useRef(createBeaconProfile({ tag, placement }));

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) return;
    if (!products.length) return;

    isMounted.current = true;

    sendBeaconRenderEvent(beaconProfile.current, products);
  }, [products]);

  const { ref, entry } = useIntersection({ threshold: 1 });

  const [embla, setEmbla] = useState<EmblaCarouselType>();

  const hasEnteredViewport = useRef(false);

  const impressionsHistory = useRef<Array<string>>([]);

  useEffect(() => {
    if (hasEnteredViewport.current) return;
    if (entry?.isIntersecting) return;
    if (!embla) return;

    const productsInView = products.filter((_, i) => embla.slidesInView().includes(i));

    if (!productsInView.length) return;

    hasEnteredViewport.current = true;

    impressionsHistory.current.push(...productsInView.map((p) => p.id));

    sendBeaconImpressionEvent(beaconProfile.current, productsInView);
  }, [entry, embla, products]);

  useEffect(() => {
    if (!embla) return;

    const handleScroll = debounce(() => {
      const newProductsInView = products
        .filter((_, i) => embla.slidesInView().includes(i))
        .filter((p) => !impressionsHistory.current.includes(p.id));

      if (!newProductsInView.length) return;

      impressionsHistory.current.push(...newProductsInView.map((p) => p.id));

      sendBeaconClickImpressionEvent(beaconProfile.current, newProductsInView);
    }, 100);

    embla.on('scroll', handleScroll);

    return () => {
      embla.off('scroll', handleScroll);
    };
  }, [embla, products]);

  return {
    ref,
    embla,
    setEmbla,
  };
}
