import { gql } from '../../__generated__/gql';

export const CUSTOMER_ACCESS_TOKEN_CREATE = gql(`
  mutation customerAccessTokenCreate ($customerAccessTokenCreateInput: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate (input: $customerAccessTokenCreateInput) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
