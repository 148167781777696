import React from 'react';

export default function PaymentsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6841_89324)">
        <path
          d="M6.59015 11.5259L6.04015 10.0009C6.01207 9.92704 5.99885 9.84837 6.00125 9.7694C6.00366 9.69042 6.02163 9.6127 6.05415 9.54069C6.08667 9.46868 6.1331 9.4038 6.19076 9.34978C6.24842 9.29576 6.31618 9.25366 6.39015 9.2259L16.5402 5.5509C16.614 5.52281 16.6927 5.50959 16.7717 5.512C16.8506 5.5144 16.9284 5.53238 17.0004 5.5649C17.0724 5.59742 17.1373 5.64384 17.1913 5.7015C17.2453 5.75916 17.2874 5.82692 17.3152 5.9009L19.4902 11.9009C19.5182 11.9748 19.5315 12.0534 19.5291 12.1324C19.5267 12.2114 19.5087 12.2891 19.4762 12.3611C19.4436 12.4331 19.3972 12.498 19.3396 12.552C19.2819 12.606 19.2141 12.6481 19.1402 12.6759L16.5152 13.6259M6.54015 11.3259L17.7652 7.2259M15.6902 9.6759L16.8152 9.2759"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9156 11.5273H5.11563C4.78425 11.5273 4.51562 11.796 4.51562 12.1273V18.4273C4.51562 18.7587 4.78425 19.0273 5.11563 19.0273H15.9156C16.247 19.0273 16.5156 18.7587 16.5156 18.4273V12.1273C16.5156 11.796 16.247 11.5273 15.9156 11.5273Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.51562 15.0273H10.0156M13.0156 13.5273H14.5156M6.51562 17.0273H7.01562M9.01562 17.0273H9.51562M11.5156 17.0273H12.0156M14.0156 17.0273H14.5156"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_89324">
          <rect width="16" height="16" fill="white" transform="translate(4.01562 4.27734)" />
        </clipPath>
      </defs>
    </svg>
  );
}
