import { MantineTheme, createStyles, keyframes } from '@mantine/core';

const floatLeft = keyframes({
  from: {
    transition: 'ease-in 0.5s',
    transform: 'translate(0, 0px) rotate(-45deg)',
    right: 0,
    opacity: 1,
  },
  to: {
    transition: 'ease-in 0.5s',
    transform: 'translate(0, -50px) rotate(45deg)',
    right: -15,
    opacity: 0,
  },
});

const floatRight = keyframes({
  from: {
    transition: 'ease-in 0.5s',
    transform: 'translate(0, 0px) rotate(-45deg)',
    left: 0,
    opacity: 1,
  },
  to: {
    transition: 'ease-in 0.5s',
    transform: 'translate(0, -50px) rotate(45deg)',
    left: -15,
    opacity: 0,
  },
});

const rotate = keyframes({
  '25%': {
    transform: 'rotate(-45deg)',
  },
  '50%': {
    transform: 'rotate(45deg)',
  },
});

const grow = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '50%': {
    transform: 'scale(1.5)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const AnimatedIcon = createStyles((theme: MantineTheme, { animate }: { animate: boolean }) => ({
  flutter: {
    position: 'absolute',
    zIndex: 1,
    display: animate ? 'block' : 'none',
    transition: 'all ease-in 0.5s',
    fill: theme.colors.brand[4],
    animation: `${floatLeft} 2s ease-in-out`,
    ':nth-of-type(1)': {
      animation: `${floatRight} 2s ease-in-out`,
      animationDelay: '0.25s',
    },
    ':nth-of-type(2)': {
      animation: `${floatLeft} 2s ease-in-out`,
      animationDelay: '0.75s',
    },
    ':nth-of-type(3)': {
      animation: `${floatRight} 2s ease-in-out`,
      animationDelay: '0.75s',
    },
  },
  wiggle: {
    transition: 'all ease-in 0.5s',
    animation: `${rotate} 2s  ease-in-out`,
  },
  grow: {
    transition: 'all ease-in 0.5s',
    animation: animate ? `${grow} 2s ease-in-out` : 'none',
  },
}));

export default AnimatedIcon;
