import React from 'react';

const TrendingIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 4.66667C9.85 5.68334 10 7.33334 10 7.33334C10 7.33334 8.3 4.75001 9 2.66667C8.31667 4.06667 5.33333 4.43334 6.66667 8.33334C4.98333 7.70001 5 5.66667 5 5.66667C5 5.66667 4 6.66667 4 8.66667C4 9.72754 4.42143 10.745 5.17157 11.4951C5.92172 12.2452 6.93913 12.6667 8 12.6667C9.06087 12.6667 10.0783 12.2452 10.8284 11.4951C11.5786 10.745 12 9.72754 12 8.66667C12 6.63334 10.55 6.65001 11 4.66667Z"
      stroke="#0D0005"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrendingIcon;
