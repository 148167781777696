import React from 'react';
import { Accordion, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQReturnsAndRefunds = (): React.ReactElement => (
  <FAQAccordion handle="returns-and-refunds">
    {useStaticContent('FAQ.ReturnsAndRefunds.Exchanges') && (
      <Accordion.Item value="exchanges">
        <Accordion.Control>Exchanges</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.Exchanges" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.Returns') && (
      <Accordion.Item value="returns">
        <Accordion.Control>Making a return</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.Returns" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.Refunds') && (
      <Accordion.Item value="refunds">
        <Accordion.Control>Refunds</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.Refunds" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.AfterpayReturns') && (
      <Accordion.Item value="afterpay-returns">
        <Accordion.Control>Returning something purchased with AfterPay</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.AfterpayReturns" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.StoreCredit') && (
      <Accordion.Item value="store-credit">
        <Accordion.Control>Store Credit</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.StoreCredit" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.Turnaround') && (
      <Accordion.Item value="turnaround">
        <Accordion.Control>Turnaround time for returns</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.Turnaround" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.ReturnsAndRefunds.HappyReturns') && (
      <Accordion.Item value="happy-returns">
        <Accordion.Control>Happy Returns</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.ReturnsAndRefunds.HappyReturns" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQReturnsAndRefunds;
