import React from 'react';

function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8444_21389)">
        <path
          d="M1.39062 3.9375L10.7656 13.4531L20.1406 3.9375M13.8906 10.3688L20.1406 17.0625M1.39062 17.0625L7.64062 10.3688"
          stroke="#202020"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.39062 3.9375H20.1406V17.0625H1.39062V3.9375Z"
          stroke="#202020"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8444_21389">
          <rect width="20" height="21" fill="white" transform="translate(0.765625)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmailIcon;
