import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_UPDATE } from '../../documents/mutations';
import type { CustomerUpdateMutation, CustomerUpdateMutationVariables } from '../../__generated__/graphql';

export async function CustomerUpdate({
  customerAccessToken,
  customerUpdateInput,
}: CustomerUpdateMutationVariables): Promise<{
  data: CustomerUpdateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_UPDATE, {
    customerAccessToken: customerAccessToken,
    customerUpdateInput: customerUpdateInput,
  });

  return { data, error };
}
