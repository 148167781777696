import React from 'react';

export default function PopularQuestionsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6841_89305)">
        <path
          d="M16.5156 7.77734C14.7906 9.30234 15.0156 11.7773 15.0156 11.7773C15.0156 11.7773 12.4656 7.90234 13.5156 4.77734C12.4906 6.87734 8.01562 7.42734 10.0156 13.2773C7.49062 12.3273 7.51562 9.27734 7.51562 9.27734C7.51562 9.27734 6.01562 10.7773 6.01562 13.7773C6.01563 15.3686 6.64777 16.8948 7.77298 18.02C8.8982 19.1452 10.4243 19.7773 12.0156 19.7773C13.6069 19.7773 15.133 19.1452 16.2583 18.02C17.3835 16.8948 18.0156 15.3686 18.0156 13.7773C18.0156 10.7273 15.8406 10.7523 16.5156 7.77734Z"
          fill="white"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_89305">
          <rect width="16" height="16" fill="white" transform="translate(4.01562 4.27734)" />
        </clipPath>
      </defs>
    </svg>
  );
}
