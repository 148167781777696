import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_ADDRESS_DELETE } from '../../documents/mutations';
import type {
  CustomerAddressDeleteMutation,
  CustomerAddressDeleteMutationVariables,
} from '../../__generated__/graphql';

export async function CustomerAddressDelete({
  customerAccessToken,
  addressId,
}: CustomerAddressDeleteMutationVariables): Promise<{
  data: CustomerAddressDeleteMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_ADDRESS_DELETE, {
    customerAccessToken: customerAccessToken,
    addressId: addressId,
  });

  return { data, error };
}
