import type { MantineTheme } from '@mantine/core';
import { NO_HOVER_CLASS_NAME } from '../../../utils/constants';

const button = {
  styles: {
    root: {
      fontSize: '1rem',
      fontWeight: 500,
      borderRadius: '0.13rem',
    },
    centerLoader: {
      zIndex: 1,
    },
  },
  variants: {
    filled: (theme: MantineTheme) => ({
      root: {
        color: theme.white,
        backgroundColor: theme.black,
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          color: theme.black,
          backgroundColor: theme.white,
          border: `0.0625rem solid ${theme.black}`,
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: theme.black,
        },
      },
    }),
    outline: (theme: MantineTheme) => ({
      root: {
        color: theme.black,
        backgroundColor: theme.white,
        border: `0.0625rem solid ${theme.black}`,
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          backgroundColor: '#F8DBE6',
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: '#F8DBE6',
        },
        '&[data-disabled]': {
          color: '#9E9E9E',
          backgroundColor: theme.white,
          border: `0.0625rem solid #9E9E9E`,
        },
      },
    }),
    outlineGhost: (theme: MantineTheme) => ({
      root: {
        fontSize: '0.563rem !important',
        color: theme.black,
        backgroundColor: 'transparent',
        border: `0.08rem solid ${theme.black}`,
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          color: theme.white,
          backgroundColor: theme.black,
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: theme.black,
        },
        '&[data-disabled]': {
          backgroundColor: 'transparent',
          color: '#A5A5A5',
        },
      },
    }),
    tertiary: (theme: MantineTheme) => ({
      root: {
        color: theme.black,
        backgroundColor: theme.colors.brand[4],
        border: `0.0625rem solid ${theme.black}`,
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          color: theme.black,
          backgroundColor: theme.white,
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: theme.white,
        },
      },
    }),
    tertiaryDark: (theme: MantineTheme) => ({
      root: {
        color: theme.black,
        backgroundColor: theme.colors.brand[8],
        border: `0.0625rem solid ${theme.black}`,
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          color: theme.black,
          backgroundColor: theme.white,
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: theme.white,
        },
      },
    }),
    ghost: (theme: MantineTheme) => ({
      root: {
        color: theme.black,
        backgroundColor: theme.fn.rgba(theme.white, 0.7),
        [`&:not(.${NO_HOVER_CLASS_NAME}):hover`]: {
          color: theme.black,
          backgroundColor: theme.white,
        },
        '&[data-loading]::before': {
          border: `0.0625rem solid ${theme.black}`,
          borderRadius: '0.13rem',
          backgroundColor: theme.white,
        },
      },
    }),
  },
};

export default button;
