import React from 'react';

export default function AccountSignUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5817 13C16.6592 14.1419 17.0138 17.5167 16.9996 19.1265C16.9952 19.6277 16.5753 20 16.074 20H5.93993C5.4159 20 4.98616 19.5961 5.01579 19.0729C5.10612 17.478 5.66302 14.2715 8.60163 13.0761"
        stroke="black"
        strokeWidth="0.8"
      />
      <circle cx="11" cy="11" r="3" stroke="black" strokeWidth="0.8" />
      <path
        d="M16.5 4V9M19 6.5H14"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
