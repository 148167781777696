import React from 'react';

const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.7273 4.5H11.2727V11.7727H4V13.2273H11.2727V20.5H12.7273V13.2273H20V11.7727H12.7273V4.5Z"
      fill="black"
    />
  </svg>
);

export default PlusIcon;
