import RunQuery from '../../utils/RunQuery.ts';
import { GET_CART } from '../../documents/queries';
import { type GetCartQuery, type GetCartQueryVariables } from '../../__generated__/graphql';
import env from '@client-shopify/env.ts';

export async function GetCart({ cartId, buyerIp }: GetCartQueryVariables & { buyerIp: string | undefined }) {
  const { data, error } = await RunQuery<GetCartQuery>(GET_CART, {
    cartId: cartId,
    buyerIP: buyerIp || '',
  });

  const freeShippingThreshold = env.FREE_SHIPPING_AMOUNT;

  if (!cartId) return null;

  if (error) {
    return { cart: undefined, error };
  }

  const cart = {
    id: data?.cart?.id,
    checkoutUrl: data?.cart?.checkoutUrl,
    buyerIdentity: data?.cart?.buyerIdentity,
    cartLines: data?.cart?.lines?.edges.map((cartLineEdge) => ({
      id: cartLineEdge.node.id,
      productId: cartLineEdge.node.merchandise.product.id,
      variantId: cartLineEdge.node.merchandise.id,
      title: cartLineEdge.node.merchandise.product.title,
      handle: cartLineEdge.node.merchandise.product.handle,
      tags: cartLineEdge.node.merchandise.product.tags,
      productAvailableForSale: cartLineEdge.node.merchandise.product.availableForSale,
      variantAvailableForSale: cartLineEdge.node.merchandise.availableForSale,
      featuredImage: {
        id: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.id
          : '',
        url: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.url
          : '',
        altText: cartLineEdge.node.merchandise.product.featuredImage
          ? cartLineEdge.node.merchandise.product.featuredImage.altText
          : '',
      },
      size: cartLineEdge.node.merchandise.title,
      costPerQuantity: {
        amount: String(parseFloat(cartLineEdge.node.cost.amountPerQuantity.amount).toFixed(2)),
        currencyCode: cartLineEdge.node.cost.amountPerQuantity.currencyCode,
      },
      quantity: cartLineEdge.node.quantity,
      quantityAvailable: cartLineEdge.node.merchandise.quantityAvailable,
      attributes: cartLineEdge.node.attributes,
      sku: cartLineEdge.node.merchandise.sku,
    })),
    subtotalAmount: {
      amount: data?.cart?.cost.subtotalAmount.amount,
      currencyCode: data?.cart?.cost.subtotalAmount.currencyCode,
    },
    totalAmount: {
      amount: data?.cart?.cost.totalAmount.amount,
      currencyCode: data?.cart?.cost.totalAmount.currencyCode,
    },
    bnplAmount: {
      amount: (parseFloat(data?.cart?.cost.subtotalAmount.amount) / 4).toFixed(2),
      currencyCode: data?.cart?.cost.subtotalAmount.currencyCode,
    },
    shipping: {
      free: parseFloat(data?.cart?.cost.subtotalAmount.amount) >= freeShippingThreshold,
      amountTillFree:
        parseFloat(data?.cart?.cost.subtotalAmount.amount) < freeShippingThreshold
          ? (freeShippingThreshold - parseFloat(data?.cart?.cost.subtotalAmount.amount)).toFixed(2)
          : '0.00',
    },
    updatedAt: data?.cart?.updatedAt,
    createdAt: data?.cart?.createdAt,
  };

  return { cart, error };
}

type CartLineAttribute = {
  key: string;
  value: string;
};

type Price = {
  amount: string;
  currencyCode: string;
};

type ProductImage = {
  id: string | null | undefined;
  url: string;
  altText: string | null | undefined;
};

export type CartLine = {
  __typename?: 'CartLine';
  attributes: CartLineAttribute[];
  costPerQuantity: Price;
  featuredImage: ProductImage;
  handle: string;
  id: string;
  productAvailableForSale: boolean;
  productId: string;
  quantity: number;
  quantityAvailable: number;
  sku: string;
  size: string;
  tags: string[];
  title: string;
  variantAvailableForSale: boolean;
  variantId: string;
};

const cartTypeGuard = async (id: string) => {
  const res = await GetCart({ cartId: id, buyerIp: '' });
  if (!res?.cart) {
    throw new Error('Cart not found');
  }
  return res.cart;
};

export type Cart = Awaited<ReturnType<typeof cartTypeGuard>>;
