import React from 'react';
import { ActionIcon, CompactCloseIcon, NextLink } from '../../../core';
import WishlistProductCard from '../../cards/WishlistProductCard/WishlistProductCard';
import { WishlistProduct } from '@ui/types/contextObjects';
import Link from 'next/link';
import { Drawer, ScrollArea } from '@mantine/core';
import { Button } from '../../buttons/Button/Button';
import { authSelectors } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';

const slideLeft = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(100%)' },
  transitionProperty: 'transform',
};

type WishlistDrawerProps = {
  opened: boolean;
  onClose: () => void;
  className?: string;
};

const WishlistDrawer = ({ opened, onClose, className }: WishlistDrawerProps): React.ReactElement => {
  const { auth } = authSelectors;
  const { wishlistStore } = wishlistSelectors;
  const customerId = auth.use.customerId();
  const wishlist = wishlistStore.use.wishlist();
  const [isGuest, setIsGuest] = React.useState(true);
  const [wishlistItems, setWishlistItems] = React.useState<WishlistProduct[]>([]);

  React.useEffect(() => {
    if (!opened) return;
    if (customerId) setIsGuest(false);
    setWishlistItems(
      isWishlistFrontEnd(wishlist) ? Object.keys(wishlist.products).map((prod) => wishlist.products[prod]) : [],
    );
  }, [opened, wishlist, customerId]);

  function handleClose() {
    onClose();
  }

  return (
    <Drawer
      withOverlay
      withCloseButton={false}
      opened={opened}
      onClose={handleClose}
      transitionProps={{
        duration: 300,
        timingFunction: 'ease-in-out',
        transition: slideLeft,
      }}
      position="right"
      className={className}
      shadow="md"
      classNames={{
        overlay: 'bg-black/20',
        body: 'p-0 flex-1 isolate flex flex-col h-full',
        content: 'basis-[28.125rem]',
      }}
    >
      <div className="divide-y divide-black bg-white border-b border-black z-10">
        <div className="p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="space-x-2 flex items-center">
              <div className="text-[14px] font-bold tracking-[0.01em]">MY WISHLIST</div>
              <div className="text-xs">
                {wishlistItems.length} {wishlistItems.length === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className="flex items-center">
              <ActionIcon variant="transparent" onClick={handleClose} data-autofocus>
                <CompactCloseIcon height={10} width={10} />
              </ActionIcon>
            </div>
          </div>
        </div>

        {isGuest && (
          <div className="px-4 py-6 min-h-[68px]">
            <div className="flex items-center">
              <div className="text-[13px] leading-[1.5] text-center uppercase">
                {`DON'T LOSE YOUR FAVES! `}
                <NextLink href="/account/login/" className="underline" onClick={onClose}>
                  SIGN IN
                </NextLink>{' '}
                TO SAVE YOUR WISHLIST NOW
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex-1 min-h-0">
        <ScrollArea className="h-full">
          {wishlistItems.length > 0 ? (
            <div className="px-4 py-0 divide-y-[0.6px] divide-black">
              {wishlistItems.map((wishlistItem) => (
                <div key={wishlistItem.handle} className="py-6">
                  <WishlistProductCard product={wishlistItem} onClose={onClose} />
                </div>
              ))}
            </div>
          ) : (
            <div className="px-4 py-6">
              <div className="text-[13px] uppercase text-center">Your wishlist is currently empty.</div>
              <div className="space-y-3 my-6">
                <Button className="w-full" asChild>
                  <Link href="/collections/dresses/">Shop Dresses</Link>
                </Button>
                <Button className="w-full" asChild>
                  <Link href="/collections/new/">Shop New In</Link>
                </Button>
                <Button className="w-full" asChild>
                  <Link href="/pages/gift-cards/">Shop Gift Cards</Link>
                </Button>
              </div>
            </div>
          )}
        </ScrollArea>
      </div>
    </Drawer>
  );
};

export default WishlistDrawer;
