import React from 'react';

const RulerIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2879_16662)">
      <path
        d="M0.700195 11.7501L4.2252 15.2751L15.3002 4.1751L11.8502 0.725098L0.700195 11.7501Z"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.9502 9.54995L4.7002 11.3M5.6502 6.84995L7.4002 8.59995M8.3252 4.22495L10.0502 5.94995M4.2752 8.22495L5.5252 9.49995M6.9252 5.59995L8.1752 6.84995M9.6002 2.94995L10.8252 4.19995"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2879_16662">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RulerIcon;
