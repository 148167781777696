import React from 'react';

export default function AccountHelpCentreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M10.813 14.818v.875c0 .5.15 1.25-2.6 1.75a3.65 3.65 0 0 0-2.9 2.75h14.75a3.575 3.575 0 0 0-2.85-2.75c-2.65-.5-2.4-1.25-2.4-1.75v-1.25M16.413 12.943h1.15a.826.826 0 0 0 .75-.75v-1.75a.825.825 0 0 0-.75-.75h-1.25m-7 0h-1.5a.825.825 0 0 0-.75.75v1.75a.825.825 0 0 0 .75.75h1.3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M16.313 9.193c-5 0-5-3.5-5-3.5s.25 3-2 3.75m4.5 10.75 2.4-3m-4.4 3-2.526-3a2.7 2.7 0 0 1-1.225-1c1-1 1.25-5 1.25-5m7 0s0 4 1 5c-.284.442-.693.79-1.175 1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M11.313 15.193c-1.176-.725-2-2.2-2-4v-2.5c0-2.6 1.85-3.5 3.5-3.5s3.5.9 3.5 3.5v4c0 1-1 1.75-1.5 1.75"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth=".8"
        d="M14.812 14.418a.725.725 0 0 0-.725-.725h-.55a.725.725 0 0 0-.725.725.749.749 0 0 0 .725.775h.525a.751.751 0 0 0 .75-.775Z"
      />
    </svg>
  );
}
