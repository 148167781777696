import React from 'react';

export default function ProductsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.7656 11.9629L19.1168 15.832C19.6711 16.1237 19.4638 16.9629 18.8374 16.9629H5.0811C4.46198 16.9629 4.24851 16.1391 4.78971 15.8384L11.7656 11.9629ZM11.7656 11.9629C11.7656 10.4632 14.3967 9.59273 12.7656 7.96193C12.0527 7.24915 10.2656 7.46292 10.2656 9.46291"
        stroke="#0D0005"
        strokeWidth="0.8"
      />
    </svg>
  );
}
