import { type CountryCode } from '@client-shopify/gql/storefront/__generated__/graphql';
import env from '@ui/env';
import React from 'react';

export default function useCustomerCountry() {
  const [customerCountry, setCustomerCountry] = React.useState<CountryCode>();

  React.useEffect(() => {
    setCustomerCountry(getCustomerCountry());
  }, []);

  return customerCountry;
}

export function getCustomerCountry(): CountryCode {
  const countryFromURL = new URLSearchParams(location.search).get('country')?.toUpperCase();
  const countryFromStorage = localStorage.getItem('customerCountry')?.toUpperCase();
  const countryFromEnv = env.NEXT_PUBLIC_REGION;

  return (countryFromURL || countryFromStorage || countryFromEnv) as CountryCode;
}
