import type { MantineTheme } from '@mantine/core';

const avatar = {
  styles: (theme: MantineTheme) => ({
    placeholder: {
      color: theme.white,
      backgroundColor: theme.black,
    },
  }),
};

export default avatar;
