import axios from 'axios';
import { getCookie } from '@ui/hooks/useCookie';
import env from '@ui/env';
import { v4 as uuidv4 } from 'uuid';

type beaconProps = {
  type:
    | 'profile.render'
    | 'profile.impression'
    | 'profile.click'
    | 'profile.product.render'
    | 'profile.product.impression'
    | 'profile.product.click';
  id: string;
  pid?: string;
  tag: string;
  placement: string;
  productSku?: string;
  skus?: Array<string>;
  seed?: Array<{ sku: string }>;
};

const beacon = async ({ type, id, pid, tag, placement, productSku, skus, seed }: beaconProps) => {
  const context = createBeaconContext();

  const batchData: Array<any> = [];

  if (skus) {
    skus.forEach((sku) => {
      batchData.push(
        createBeaconEvent({
          context,
          id,
          pid: pid || undefined,
          type,
          tag,
          placement,
          seed: seed?.map((s) => s.sku),
          product: { id: sku },
        }),
      );
    });
  }
  try {
    const { data } = await post(
      batchData.length > 0
        ? batchData
        : createBeaconEvent({
            context,
            id,
            pid: pid || undefined,
            type,
            tag,
            placement,
            seed: seed?.map((s) => s.sku),
            product: productSku ? { id: productSku } : undefined,
          }),
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export default beacon;

function createBeaconContext(): BeaconEvent['context'] {
  return {
    userId: getCookie('ssUserId'),
    sessionId: getCookie('ssSessionIdNamespace'),
    pageLoadId: getCookie('pageLoadId'),
    website: {
      trackingCode: env.NEXT_PUBLIC_SEARCHSPRING_SITEID,
    },
  };
}

function post(data: any) {
  return axios.request({
    method: 'POST',
    url: env.NEXT_PUBLIC_SEARCHSPRING_BEACON_URL,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    data,
  });
}

export type BeaconProduct = {
  id: string;
  mappings?: {
    core: object;
  };
};

type BeaconEvent = {
  category: string;
  context: {
    pageLoadId: string;
    userId: string;
    sessionId: string;
    website: {
      trackingCode: string;
    };
  };
  event: {
    context: {
      type: string;
      tag: string;
      placement: string;
    };
    profile?: {
      tag: string;
      placement: string;
      seed: string[];
    };
    product?: BeaconProduct;
  };
  id: string;
  pid?: string;
  type: beaconProps['type'];
};

type CreateBeaconEventArgs = Pick<BeaconEvent, 'context' | 'id' | 'pid' | 'type'> & {
  tag: string;
  placement: string;
  seed?: string[];
  product?: BeaconProduct;
};

export function createBeaconEvent({
  context,
  id,
  pid,
  tag,
  type,
  placement,
  product,
  seed,
}: CreateBeaconEventArgs): BeaconEvent {
  return {
    category: 'searchspring.recommendations.user-interactions',
    context,
    event: {
      context: {
        type: 'product-recommendation',
        tag,
        placement,
      },
      ...(!type.startsWith('profile.product')
        ? { profile: { tag, placement, seed: seed?.length ? seed : [''] } }
        : { product }),
    },
    id,
    pid,
    type,
  };
}

export type BeaconProfile = {
  id?: string;
  tag: string;
  placement: string;
};

export function createBeaconProfile({ tag, placement }: { tag: string; placement: string }) {
  return { id: uuidv4(), tag, placement };
}

export async function sendBeaconRenderEvent(beaconProfile: BeaconProfile, beaconProducts: Array<BeaconProduct>) {
  try {
    const context = createBeaconContext();

    await post([
      createBeaconEvent({
        context,
        type: 'profile.render',
        id: uuidv4(),
        pid: beaconProfile.id,
        tag: beaconProfile.tag,
        placement: beaconProfile.placement,
      }),
      ...beaconProducts.map((product) =>
        createBeaconEvent({
          context,
          type: 'profile.product.render',
          id: uuidv4(),
          pid: beaconProfile.id,
          tag: beaconProfile.tag,
          placement: beaconProfile.placement,
          product,
        }),
      ),
    ]);
  } catch {
    //
  }
}

export async function sendBeaconImpressionEvent(beaconProfile: BeaconProfile, beaconProducts: Array<BeaconProduct>) {
  try {
    const context = createBeaconContext();

    await post([
      createBeaconEvent({
        context,
        type: 'profile.impression',
        id: uuidv4(),
        pid: beaconProfile.id,
        tag: beaconProfile.tag,
        placement: beaconProfile.placement,
      }),
      ...beaconProducts.map((product) =>
        createBeaconEvent({
          context,
          type: 'profile.product.impression',
          id: uuidv4(),
          pid: beaconProfile.id,
          tag: beaconProfile.tag,
          placement: beaconProfile.placement,
          product,
        }),
      ),
    ]);
  } catch {
    //
  }
}

export async function sendBeaconClickImpressionEvent(
  beaconProfile: BeaconProfile,
  beaconProducts: Array<BeaconProduct>,
) {
  try {
    const context = createBeaconContext();

    await post([
      createBeaconEvent({
        context,
        type: 'profile.click',
        id: uuidv4(),
        pid: beaconProfile.id,
        tag: beaconProfile.tag,
        placement: beaconProfile.placement,
      }),
      ...beaconProducts.map((product) =>
        createBeaconEvent({
          context,
          type: 'profile.product.impression',
          id: uuidv4(),
          pid: beaconProfile.id,
          tag: beaconProfile.tag,
          placement: beaconProfile.placement,
          product,
        }),
      ),
    ]);
  } catch {
    //
  }
}

export async function sendBeaconClickEvent(beaconProfile: BeaconProfile, product: BeaconProduct, seed?: string[]) {
  try {
    const context = createBeaconContext();

    await post([
      createBeaconEvent({
        context,
        type: 'profile.click',
        id: uuidv4(),
        pid: beaconProfile.id,
        tag: beaconProfile.tag,
        placement: beaconProfile.placement,
        seed,
      }),
      createBeaconEvent({
        context,
        type: 'profile.product.click',
        id: uuidv4(),
        pid: beaconProfile.id,
        tag: beaconProfile.tag,
        placement: beaconProfile.placement,
        product,
      }),
    ]);
  } catch {
    //
  }
}
