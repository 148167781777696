import RunMutation from '../../utils/RunMutation.ts';
import { CART_LINES_UPDATE } from '../../documents/mutations';
import { CurrencyCode, CartLinesUpdateMutation, CartLinesUpdateMutationVariables } from '../../__generated__/graphql';
import { mapCartData } from '../../utils/MapCartData.ts';

export async function CartLinesUpdate({ cartId, cartLinesUpdateInput }: CartLinesUpdateMutationVariables) {
  const { data, error } = await RunMutation<CartLinesUpdateMutation>(CART_LINES_UPDATE, {
    cartId: cartId,
    cartLinesUpdateInput: cartLinesUpdateInput,
  });

  if (error) {
    return { cart: undefined, error };
  }

  if (!data?.cartLinesUpdate?.cart) {
    return { cart: undefined, error: { message: 'Cart not found' } };
  }

  const cartData = data?.cartLinesUpdate?.cart;

  const cart = mapCartData(cartData);

  return { cart, error };
}
