/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react';
import { GetCart, GetCustomerOrders, GetCustomer } from '@client-shopify/gql/storefront/api/queries';
import { Cart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import { getCookie } from './useCookie';
import env from '@ui/env';

interface UserProperties {
  visitor_type: 'logged_in' | 'guest';
  customer_email?: string;
  customer_address_1?: string;
  customer_address_2?: string;
  customer_city?: string;
  customer_country?: string;
  customer_first_name?: string;
  customer_id?: string;
  customer_last_name?: string;
  customer_total_spend?: string;
  customer_order_count?: string;
  customer_phone?: string;
  customer_province?: string;
  customer_province_code?: string;
  customer_tags?: string;
  customer_total_spent?: string;
  customer_zip?: string;
}

interface RemoveFromCartEvent {
  event: 'dl_remove_from_cart';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    remove: {
      actionField: {
        list: string;
      };
      products: Product[];
    };
  };
}

interface ViewCartEvent {
  event: 'dl_view_cart';
  user_properties: UserProperties;
  cart_total: string;
  ecommerce: {
    currencyCode: string;
    actionField: {};
    impressions: Product[];
  };
}

interface Product {
  url?: string;
  id: string;
  name: string;
  brand: string;
  category: string;
  variant: string;
  price: string;
  quantity: string | null;
  list: string;
  product_id: string;
  variant_id: string;
  compare_at_price?: string;
  position: number;
  image?: string;
}

interface ElevarSelectItemEvent {
  event: 'dl_select_item';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    click: {
      actionField: {
        list: string;
        action: 'click';
      };
      products: Product[];
    };
  };
}

interface SubscribeEvent {
  event: 'dl_subscribe';
  lead_type: 'email' | 'phone';
  user_properties: {
    customer_email: string;
    customer_phone: string;
  };
}

interface BaseEvent {
  event: 'dl_user_data';
  cart_total: string;
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    cart_contents: {
      products: Product[] | undefined;
    };
  };
}

interface LoginEvent {
  event: 'dl_login';
  user_properties: UserProperties;
}

interface SignUpEvent {
  event: 'dl_sign_up';
  user_properties: UserProperties;
}

interface ViewItemListEvent {
  event: 'dl_view_item_list';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    impressions: Product[];
  };
}

interface ViewItem {
  event: 'dl_view_item';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    detail: {
      actionField: {
        list: string;
        action: 'detail';
      };
      products: Product[];
    };
  };
}

interface ViewSearchResultsEvent {
  event: 'dl_view_search_results';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    actionField: {
      list: string;
    };
    impressions: Product[];
  };
}

interface ElevarAddToCartEvent {
  event: 'dl_add_to_cart';
  user_properties: UserProperties;
  ecommerce: {
    currencyCode: string;
    add: {
      actionField: {
        list: string;
      };
      products: Product[];
    };
  };
}

type Events = Array<
  | BaseEvent
  | SignUpEvent
  | LoginEvent
  | ViewItemListEvent
  | ViewSearchResultsEvent
  | ElevarSelectItemEvent
  | ViewItem
  | ElevarAddToCartEvent
  | ViewCartEvent
  | RemoveFromCartEvent
  | SubscribeEvent
>;

declare global {
  interface Window {
    ElevarDataLayer: Events;
    ElevarInvalidateContext: () => void;
  }
}

type CartData = Awaited<Cart>;

export function useElevarDataLayer() {
  const baseEventRef = useRef<(cartData?: CartData | undefined) => void>(() => {});

  const generateUserProperties = useCallback(
    async (customerAccessToken?: string): Promise<UserProperties | undefined> => {
      if (typeof window !== 'undefined') {
        const token = customerAccessToken
          ? customerAccessToken
          : window.localStorage.getItem('auth')
            ? JSON.parse(window.localStorage.getItem('auth') as string)?.state.token
            : '';
        if (!token) {
          return {
            visitor_type: 'guest',
          };
        } else {
          try {
            const customer = (await GetCustomer({ customerAccessToken: token })).data?.customer;
            const orders = (await GetCustomerOrders({ customerAccessToken: token })).data;
            if (!customer) {
              return {
                visitor_type: 'guest',
              };
            }
            return {
              visitor_type: 'logged_in',
              customer_email: customer.email ?? '',
              customer_address_1: customer.defaultAddress?.address1 ?? '',
              customer_city: customer.defaultAddress?.city ?? '',
              customer_country: customer.defaultAddress?.country ?? '',
              customer_first_name: customer.firstName ?? '',
              customer_id: customer.id.split('/').at(-1) ?? '',
              customer_last_name: customer.lastName ?? '',
              customer_order_count: orders?.customer?.orders.edges.length.toString() ?? '',
              customer_phone: customer.defaultAddress?.phone ?? '',
              customer_province: customer.defaultAddress?.province ?? '',
              customer_province_code: customer.defaultAddress?.zip ?? '',
              customer_tags: '',
              customer_total_spent: (
                orders?.customer?.orders.edges.reduce(
                  (acc, order) => acc + parseFloat(order.node.totalPrice.amount),
                  0,
                ) ?? 0
              ).toString(),
              customer_zip: customer.defaultAddress?.zip ?? '',
            };
          } catch (error) {
            return undefined;
          }
        }
      }
    },
    [],
  );

  const baseEventCallback = useCallback(() => {
    if (!window) return;
    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    const shopifyBuyerIp = getCookie('shopifyBuyerIP');
    const cartId = window.localStorage.getItem('cart-state')
      ? JSON.parse(window.localStorage.getItem('cart-state') as string).state.cartId
      : '';
    if (baseEventRef.current) {
      if (cartId) {
        GetCart({ cartId, buyerIp: shopifyBuyerIp ?? '' }).then((res) => baseEventRef.current(res?.cart));
      } else {
        baseEventRef.current();
      }
    }
  }, []);

  useEffect(() => {
    baseEventRef.current = async (cartData) => {
      if (!window) return;
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_user_data',
          cart_total: cartData?.subtotalAmount.amount ?? '0',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            cart_contents: {
              products:
                cartData?.cartLines?.map(
                  (cartLine) =>
                    ({
                      sku: cartLine.sku,
                      id: cartLine.id,
                      name: cartLine.title ?? '',
                      brand: 'brand',
                      category: 'category',
                      variant: cartLine?.sku?.split('-').at(-1) ?? '',
                      price: cartLine.costPerQuantity.amount ?? '',
                      quantity: cartLine.quantity.toString(),
                      position: 0,
                      list: 'cart',
                      product_id: cartLine.productId.split('/').at(-1) ?? '',
                      variant_id: cartLine?.variantId.split('/').at(-1) ?? '',
                      compare_at_price: cartLine.costPerQuantity.amount ?? '',
                      image: cartLine.featuredImage.url ?? '',
                      url: '',
                    }) as Product,
                ) ?? [],
            },
          },
        });
      })();
      window.ElevarInvalidateContext?.();
    };
  }, []);

  const elevarUserLoginEvent = useCallback(async (customerAccessToken: string) => {
    if (typeof window !== 'undefined') {
      if (!customerAccessToken) return;
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      baseEventCallback();
      (async () => {
        const userProperties = await generateUserProperties(customerAccessToken);
        window.ElevarDataLayer.push({
          event: 'dl_login',
          user_properties: userProperties as UserProperties,
        });
      })();
    }
  }, []);

  const elevarSearchListEvent = useCallback(async (products: Product[]) => {
    if (typeof window !== 'undefined') {
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_view_search_results',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            actionField: {
              list: products[0].list.toLowerCase(),
            },
            impressions: products,
          },
        });
        baseEventCallback();
      })();
    }
  }, []);

  const userSignUpElevarEvent = useCallback(async (customerAccessToken: string) => {
    if (typeof window !== 'undefined') {
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      if (!customerAccessToken) return;
      const { data } = await GetCustomer({ customerAccessToken });
      const orders = await GetCustomerOrders({ customerAccessToken: customerAccessToken }).then((res) => res?.data);
      if (!data?.customer) return;
      baseEventCallback();
      const customer = data?.customer;
      const userProperties: UserProperties = {
        visitor_type: 'logged_in',
        customer_email: customer.email ?? '',
        customer_address_1: customer.defaultAddress?.address1 ?? '',
        customer_address_2: customer.defaultAddress?.address2 ?? '',
        customer_city: customer.defaultAddress?.city ?? '',
        customer_country: customer.defaultAddress?.country ?? '',
        customer_first_name: customer.firstName ?? '',
        customer_id: customer.id ?? '',
        customer_last_name: customer.lastName ?? '',
        customer_order_count: '',
        customer_phone: customer.defaultAddress?.phone ?? '',
        customer_province: customer.defaultAddress?.province ?? '',
        customer_province_code: customer.defaultAddress?.zip ?? '',
        customer_tags: '',
        customer_total_spent: (
          orders?.customer?.orders.edges.reduce((acc, order) => acc + parseFloat(order.node.totalPrice.amount), 0) ?? 0
        ).toString(),
        customer_zip: customer.defaultAddress?.zip ?? '',
      };
      window.ElevarDataLayer.push({
        event: 'dl_sign_up',
        user_properties: userProperties,
      });
    }
  }, []);

  const elevarViewItemListEvent = useCallback(async (products: Product[]) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_view_item_list',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            impressions: products.map((product) => ({
              id: product.id,
              name: product.name,
              brand: product.brand,
              category: product.category,
              variant: product.variant,
              price: product.price,
              quantity: null,
              position: product.position,
              list: product.list,
              product_id: product.variant_id.split('/').at(-1) ?? '',
              variant_id: product.variant_id.split('/').at(-1) ?? '',
              compare_at_price: product.compare_at_price,
            })),
          },
        });
      })();
    }
  }, []);

  const elevarSelectItemEvent = useCallback(async (products: Product[]) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_select_item',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            click: {
              actionField: {
                list: products[0].list,
                action: 'click',
              },
              products: products,
            },
          },
        });
      })();
    }
  }, []);

  const elevarViewItemEvent = useCallback(async (products: Product, pathname: string) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_view_item',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            detail: {
              actionField: {
                list: pathname,
                action: 'detail',
              },
              products: [products],
            },
          },
        });
      })();
    }
  }, []);

  const elevarAddToCartEvent = useCallback(async (products: Product[], pathname: string) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_add_to_cart',
          user_properties: userProperties as UserProperties,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            add: {
              actionField: {
                list: pathname,
              },
              products: products,
            },
          },
        });
      })();
    }
  }, []);

  const elevarViewCartEvent = useCallback(async (cart: Cart) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      (async () => {
        const userProperties = await generateUserProperties();
        window.ElevarDataLayer.push({
          event: 'dl_view_cart',
          user_properties: userProperties as UserProperties,
          cart_total: cart.subtotalAmount.amount,
          ecommerce: {
            currencyCode: env.NEXT_PUBLIC_REGION + 'D',
            actionField: {},
            impressions: Array.isArray(cart.cartLines)
              ? cart.cartLines?.map(
                  (cartLine) =>
                    ({
                      sku: cartLine.sku,
                      id: cartLine.id,
                      name: cartLine.title,
                      brand: 'brand',
                      category: 'category',
                      variant: cartLine?.sku?.split('/').at(-1) ?? '',
                      price: cartLine.costPerQuantity.amount,
                      quantity: cartLine.quantity.toString(),
                      list: 'cart',
                      product_id: cartLine.id.split('/').at(-1),
                      variant_id: cartLine.variantId.split('/').at(-1),
                      compare_at_price: cartLine.costPerQuantity.amount,
                      position: 0,
                    }) as Product,
                )
              : [],
          },
        });
      })();
    }
  }, []);

  const elevarRemoveFromCartEvent = useCallback(async (products: Product[], pathname: string) => {
    if (typeof window !== 'undefined') {
      baseEventCallback();
      const userProperties = await generateUserProperties();
      window.ElevarDataLayer.push({
        event: 'dl_remove_from_cart',
        user_properties: userProperties as UserProperties,
        ecommerce: {
          currencyCode: env.NEXT_PUBLIC_REGION + 'D',
          remove: {
            actionField: {
              list: pathname,
            },
            products: products,
          },
        },
      });
    }
  }, []);

  const elevarSubscribeEvent = useCallback(async (email: string) => {
    if (typeof window !== 'undefined') {
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push({
        event: 'dl_subscribe',
        lead_type: 'email',
        user_properties: {
          customer_email: email,
          customer_phone: '',
        },
      });
    }
  }, []);

  return {
    elevarSubscribeEvent,
    elevarRemoveFromCartEvent,
    elevarViewCartEvent,
    elevarAddToCartEvent,
    elevarViewItemEvent,
    elevarSelectItemEvent,
    elevarSearchListEvent,
    elevarViewItemListEvent,
    userSignUpElevarEvent,
    elevarUserLoginEvent,
  };
}
