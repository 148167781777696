import React from 'react';
import { useRouter } from 'next/router';
import {
  AUFlagIcon,
  Divider,
  Flex,
  FlexProps,
  Group,
  NZFlagIcon,
  Text,
  USFlagIcon,
  UnstyledButton,
} from '../../../core';
import env from '@ui/env';

const CountrySelector = (props: FlexProps & React.RefAttributes<HTMLDivElement>): React.ReactElement => {
  const router = useRouter();

  return (
    <Flex {...props}>
      <Text fz="12px" lts="0.05em" tt="uppercase">
        You&apos;re shopping in:
      </Text>
      <Group align="center" spacing="1rem">
        <UnstyledButton
          onClick={() => router.push('https://nz.hellomolly.com/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'NZ' || undefined}
          className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
        >
          <Group>
            <NZFlagIcon height={30} width={30} />
            <Text fz="12px" lts="0.05em">
              NZ
            </Text>
          </Group>
        </UnstyledButton>
        <Divider my="0.125rem" orientation="vertical" color="black" />
        <UnstyledButton
          onClick={() => router.push('https://hellomolly.com.au/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'AU' || undefined}
          className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
        >
          <Group>
            <AUFlagIcon height={30} width={30} />
            <Text fz="12px" lts="0.05em">
              AU
            </Text>
          </Group>
        </UnstyledButton>
        <Divider my="0.125rem" orientation="vertical" color="black" />
        <UnstyledButton
          onClick={() => router.push('https://www.hellomolly.com/')}
          data-selected={env.NEXT_PUBLIC_REGION === 'US' || undefined}
          className="opacity-30 hover:opacity-100 data-[selected]:opacity-100"
        >
          <Group>
            <USFlagIcon height={30} width={30} />
            <Text fz="12px" lts="0.05em">
              USA
            </Text>
          </Group>
        </UnstyledButton>
      </Group>
    </Flex>
  );
};

export default CountrySelector;
