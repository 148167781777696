import React from 'react';

export default function AccountMyAddressIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.3125 11.4434L12.8125 5.44336L20.3125 11.4434"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1943 19.6932V15.7373H14.3525V19.6932"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 9.84336V19.6934H11.19M16.0625 8.05461V6.19336H18.3125V19.6934H14.4143"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
