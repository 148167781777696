import { gql } from '../../__generated__/gql';

export const GET_CHECKOUT_URL = gql(`
  query getCheckoutUrl ($cartId: ID!) {
    cart (id: $cartId) {
      id
      checkoutUrl
      updatedAt
      createdAt
    }
  }
`);
