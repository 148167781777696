import cn from '@ui/utils/cn';
import React from 'react';

type LabeLProps = React.HTMLAttributes<HTMLElement> & {
  component?: any;
  htmlFor?: string;
  required?: boolean;
  disabled?: boolean;
};

const Label = React.forwardRef<HTMLElement, LabeLProps>(
  ({ className, component: Component = 'label', required, disabled, children, ...props }, ref) => {
    return (
      <Component
        ref={ref}
        {...props}
        data-disabled={disabled || undefined}
        className={cn(
          'text-[14px] inline-flex font-bold tracking-[0.0015em]',
          'data-[disabled]:text-[#9E9E9E]',
          className,
        )}
      >
        {children}
        {required && <span className="text-[#FF5E69] font-bold ml-1">*</span>}
      </Component>
    );
  },
);

Label.displayName = 'Label';

export default Label;
