import { gql } from '../../__generated__/gql';

export const CUSTOMER_UPDATE = gql(`
  mutation customerUpdate ($customerAccessToken: String!, $customerUpdateInput: CustomerUpdateInput!) {
    customerUpdate (customerAccessToken: $customerAccessToken, customer: $customerUpdateInput) {
      customer {
        id
        email
        displayName
        firstName
        lastName
        phone
        acceptsMarketing
        updatedAt
        createdAt
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
