import axios from 'axios';
import env from '@ui/env';

type SuggestArgs = {
  query: string;
  suggestionCount?: number;
  productCount?: number;
};

type SuggestResponse = {
  'corrected-query': string;
  alternatives: Array<{ text: string; popularity?: number }>;
  suggested: {
    completed: Array<{ token: string }>;
    text: string;
  };
};

const suggest = async ({ query, suggestionCount, productCount }: SuggestArgs) => {
  const { data } = await axios.request<SuggestResponse>({
    method: 'GET',
    url: `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}api/suggest/query?q=${query}&siteId=${
      env.NEXT_PUBLIC_SEARCHSPRING_SITEID
    }&q=${query}&language=en&suggestionCount=${suggestionCount || 12}&productCount=${productCount || 24}`,
    headers: {
      accept: 'application/json',
    },
  });
  // sort alternatives by popularity and let auto-complete be the first item
  if (data.alternatives) {
    data.alternatives.sort((a, b) => {
      if (a.popularity === undefined) return -1;
      if (b.popularity === undefined) return 1;
      return (b.popularity || 0) - (a.popularity || 0);
    });
  }

  return { data };
};

export default suggest;
