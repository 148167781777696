import env from '@ui/env';
import axios from 'axios';

type CreateSubscriberArgs = {
  listId: number;
  account: string;
  source: string;
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
};

const createSubscriber = async ({
  listId,
  account,
  source,
  email,
  phone,
  firstName,
  lastName,
}: CreateSubscriberArgs) => {
  await axios
    .post(
      env.NEXT_PUBLIC_SUBSCRIBER_API,
      {
        list_id: listId,
        account: account,
        source: source,
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    )
    .catch(() => {
      // continue even on failure
    });
};

export default createSubscriber;
