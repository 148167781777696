import { gql } from '../../__generated__/gql';

export const GET_SIMPLIFIED_PRODUCT = gql(`
  query getSimplifiedProduct($handle: String!) {
    product(handle: $handle) {
      id
      handle
      title
      availableForSale
    }
  }
`);
