import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_CUSTOMER_ORDERS } from '../../documents/queries';
import type { GetCustomerOrdersQuery, GetCustomerOrdersQueryVariables } from '../../__generated__/graphql';

export async function GetCustomerOrders({
  customerAccessToken,
  first,
  cursor,
}: GetCustomerOrdersQueryVariables): Promise<{
  data: GetCustomerOrdersQuery | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunQuery(GET_CUSTOMER_ORDERS, {
    customerAccessToken: customerAccessToken,
    first: first || 32,
    cursor: cursor || null,
  });
  return { data, error };
}
