import parsePhoneNumber, { type CountryCode } from 'libphonenumber-js/mobile';
import isMobilePhone from 'validator/lib/isMobilePhone';

export default function validatePhone(phone: string, defaultCountry: CountryCode) {
  if (phone.length > 0) {
    const parsedPhoneNumber = parsePhoneNumber(phone, defaultCountry);

    if (
      parsedPhoneNumber &&
      parsedPhoneNumber.isValid() &&
      isMobilePhone(phone) &&
      isMobilePhone(parsedPhoneNumber.number)
    ) {
      return null;
    }

    return 'Invalid phone number';
  }

  return null;
}
