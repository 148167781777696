import { useCallback, useState } from 'react';
import { GetCustomer } from '@client-shopify/gql/storefront/api/queries';
import { Customer } from '@client-shopify/gql/storefront/__generated__/graphql.ts';

export function useAuthCustomer() {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [error, setError] = useState<string | null>(null);

  const fetchAuthCustomer = useCallback(async (token: string) => {
    if (!token) {
      return null;
    }
    try {
      const customerResult = await GetCustomer({
        customerAccessToken: token,
      });

      if (customerResult.data) {
        setCustomer(customerResult.data.customer as Customer);
        return { customerResult };
      }
    } catch (e) {
      setError(String(e));
    }
  }, []);

  return {
    fetchAuthCustomer,
    customer,
    error,
  };
}
