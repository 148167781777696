import { auContent, nzContent, ukContent, usContent } from '@content/data';

const staticContentRegion = (region: 'AU' | 'NZ' | 'GB' | 'US') => {
  switch (region) {
    case 'AU':
      return auContent;
    case 'NZ':
      return nzContent;
    case 'GB':
      return ukContent;
    case 'US':
      return usContent;
    default:
      return auContent;
  }
};

export default staticContentRegion;
