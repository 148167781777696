import { DownChevronIcon, Menu, AUFlagCircleIcon, NZFlagCircleIcon, USFlagCircleIcon } from '@ui/components/core';
import { Text } from '@ui/components/shared/typography';
import env from '@ui/env';
import cn from '@ui/utils/cn';

const BannerCurrencyDropdown = ({ className }: { className?: string }) => (
  <div className={`shrink-0 flex ${className}`}>
    <Menu
      zIndex={9999}
      withinPortal
      classNames={{
        dropdown: `p-2 rounded-lg shadow-none border-[0.5px] border-black bg-white shrunk:!opacity-0`,
        item: 'p-0 hover:bg-btn-secondary-hover active:bg-btn-secondary-selected',
      }}
    >
      <Menu.Target>
        <button
          className={cn(
            'flex items-center justify-center',
            'text-xs font-normal space-x-1 group',
            'focus:outline-none',
          )}
        >
          {env.NEXT_PUBLIC_REGION === 'NZ' && (
            <>
              <NZFlagCircleIcon width={16} height={16} />
              <span>$NZD</span>
            </>
          )}
          {env.NEXT_PUBLIC_REGION === 'AU' && (
            <>
              <AUFlagCircleIcon width={16} height={16} />
              <span>$AUD</span>
            </>
          )}
          {env.NEXT_PUBLIC_REGION === 'US' && (
            <>
              <USFlagCircleIcon width={16} height={16} />
              <span>$USD</span>
            </>
          )}
          <DownChevronIcon className="group-data-[expanded]:rotate-180 transition" width={16} height={16} />
        </button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component="a"
          href="https://www.hellomolly.co.nz/"
          data-selected={env.NEXT_PUBLIC_REGION === 'NZ' || undefined}
        >
          <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
            <NZFlagCircleIcon width={16} height={16} />
            <span>$NZD</span>
          </div>
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://hellomolly.com.au/"
          data-selected={env.NEXT_PUBLIC_REGION === 'AU' || undefined}
        >
          <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
            <AUFlagCircleIcon width={16} height={16} />
            <span>$AUD</span>
          </div>
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://www.hellomolly.com/"
          data-selected={env.NEXT_PUBLIC_REGION === 'US' || undefined}
        >
          <div className="flex flex-start items-center gap-x-[0.625rem] py-1 px-[0.375rem]">
            <USFlagCircleIcon width={16} height={16} />
            <span>$USD</span>
          </div>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  </div>
);

export default BannerCurrencyDropdown;
