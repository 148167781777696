import { type Region } from '@ui/providers/static-content-provider';
import { Stack, Center, ShippingIcon, Text } from '@ui/components/core';

export const regionData: Array<Region> = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'APAC', label: 'Asia Pacific' },
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'OTHER', label: 'All Other Countries' },
];

export const auBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      AUSTRALIA
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Please shop on our $AUD website for shipping to Australia. Access the shipping page{' '}
      <a href="https://www.hellomolly.com.au/pages/shipping">HERE</a>
    </Text>
  </Stack>
);

export const usBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      NEXT DAY DELIVERY AVAILABLE*! AMAZING!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      We offer 3 shipping options within the USA.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Next Day and 2-Day delivery (from the date of dispatch) is available if you need your order ASAP!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you&apos;re happy to wait a little longer, our Standard delivery option takes up to 6 business days. Please
      note this is not a signature required service and it will be left if deemed safe by the courier.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Orders received before 2pm Pacific/California time on a business day will be shipped the same day from our Los
      Angeles warehouse, otherwise they are shipped the next business day.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Our express shipping options are not available for PO Boxes.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      *Next Day delivery is not available for Alaska or Hawaii.
    </Text>
  </Stack>
);

export const nzBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      NEW ZEALAND
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Please shop on our $NZD website for shipping to New Zealand. Access the shipping page{' '}
      <a href="https://www.hellomolly.co.nz/pages/shipping">HERE</a>
    </Text>
  </Stack>
);

export const caBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      GET IT IN 2 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      We offer an Express shipping option to Canada which takes 1-2 business days for delivery.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you&apos;re happy to wait a little longer, our Standard shipping option takes up to 15 business days.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
  </Stack>
);

export const gbBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      GET IT IN 3 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      We offer an Express shipping option to the United Kingdom which takes 2-3 business days for delivery.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you&apos;re happy to wait a little longer, our Standard shipping option takes up to 15 business days.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
  </Stack>
);

export const apacBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      GET IT IN 6 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      For orders going to the Asia Pacific region, you can expect delivery within 6 business days!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you don&apos;t see your country listed, prices and more specific transit times will be shown at checkout.
    </Text>
  </Stack>
);

export const otherBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      DELIVERY TIMES VARY
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Hello Molly ships worldwide! Orders going to the Rest Of The World should be with you within 2 weeks of dispatch.
      However we do ask that you allow up to a month for delivery in case of unforeseen delays. Thank you for your
      understanding.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you don&apos;t see your country listed, prices and more specific transit times will be shown at checkout.
    </Text>
  </Stack>
);
