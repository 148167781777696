const container = {
  defaultProps: {
    px: {
      base: '0.75rem',
      md: '2rem',
    },
    w: '100%',
    maw: '1800px', // '1264px',
  },
};

export default container;
