import React from 'react';

const BagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 25 25"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.40047 9.41315C9.40047 9.41315 8.87156 4.29272 12.3398 4.29272C15.808 4.29272 15.251 9.41315 15.251 9.41315M9.40047 9.41315C8.87684 9.41315 8.45308 9.80462 8.45308 10.2876C8.45308 10.7706 8.87726 11.1622 9.40088 11.1622C9.9245 11.1622 10.3487 10.7706 10.3487 10.2876C10.3487 9.80462 9.92408 9.41315 9.40047 9.41315ZM17.2218 20.2927H7.42966C5.26124 20.2927 4.33984 18.9227 4.33984 16.9223L5.45926 11.2576C6.05768 9.34337 7.23253 7.62088 9.40006 7.62088H15.2515C17.419 7.62088 18.4899 9.1942 19.1922 11.2576L20.3398 16.8371C20.3398 18.8366 19.3894 20.2927 17.2218 20.2927ZM16.2316 10.2876C16.2316 10.7706 15.8073 11.1622 15.2837 11.1622C14.7601 11.1622 14.3359 10.7706 14.3359 10.2876C14.3359 9.80462 14.7601 9.41306 15.2837 9.41306C15.8073 9.41306 16.2316 9.80462 16.2316 10.2876Z"
      stroke="black"
    />
  </svg>
);

BagIcon.defaultProps = {
  onClick: undefined,
};

export default BagIcon;
