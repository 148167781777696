import type { MantineTheme } from '@mantine/core';

const themeIcon = {
  styles: (theme: MantineTheme) => ({
    root: {
      color: theme.black,
      backgroundColor: theme.white,
    },
  }),
};

export default themeIcon;
