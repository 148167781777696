import type { ActionMap } from './ActionMap.type';
import type { User } from '../contextObjects';

export enum UserAction {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export type UserReducerPayload = User;

export type UserReducer = {
  [UserAction.Login]: UserReducerPayload;
  [UserAction.Logout]: UserReducerPayload;
};

export type UserActions = ActionMap<UserReducer>[keyof ActionMap<UserReducer>];
