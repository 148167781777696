import React from 'react';

export default function AccountMyOrdersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5690_23670)">
        <path
          d="M40.875 14.6063L24 7.125L7.125 14.6063V33.3938L24 40.875L40.875 33.3938V14.6063Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 14.6063L24 22.0875M24 22.0875L40.875 14.6063M24 22.0875V40.875M16.35 10.5L33.225 17.9812"
          stroke="currentColor"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.375 23.7754L29.625 26.7566V33.1129L36.375 30.1316V23.7754Z"
          stroke="currentColor"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5690_23670">
          <rect width="36" height="36" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}
