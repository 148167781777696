import axios from 'axios';
import env from '@ui/env';

type AutocompleteArgs = {
  query: string;
  resultsPerPage?: number;
  ssUserId: string | null;
  ssSessionIdNamespace: string | null;
  pageLoadId: string | null;
  shopper: string | null;
  cart: string | null;
  lastViewed: string | null;
  signal?: AbortSignal;
};

type AutocompleteResponse = {
  results: any[];
  pagination: {
    totalResults: number;
  };
  merchandising?: {
    redirect: string;
  };
};

const autocomplete = async ({
  query,
  resultsPerPage,
  ssUserId,
  ssSessionIdNamespace,
  pageLoadId,
  shopper,
  cart,
  lastViewed,
  signal,
}: AutocompleteArgs) => {
  let shopperString = '';
  let cartString = '';
  let lastViewedString = '';

  if (shopper) {
    shopperString += `&shopper=${shopper}`;
  }

  if (cart) {
    cartString += `&cart=${cart}`;
  }

  if (lastViewed) {
    lastViewedString += `&lastViewed=${lastViewed}`;
  }

  const { data } = await axios.request<AutocompleteResponse>({
    method: 'GET',
    url: `${env.NEXT_PUBLIC_BASE_SEARCHSPRING_URL}api/search/autocomplete.json?siteId=${
      env.NEXT_PUBLIC_SEARCHSPRING_SITEID
    }&userId=${ssUserId}${shopperString}${cartString}${lastViewedString}&sessionId=${ssSessionIdNamespace}&domain=${
      env.NEXT_PUBLIC_BASE_URL
    }&pageLoadId=${pageLoadId}&resultsFormat=json&q=${query}&resultsPerPage=${
      resultsPerPage || 24
    }&page=1&redirectResponse=full`,
    headers: {
      accept: 'application/json',
    },
    signal,
  });

  return { data };
};

export default autocomplete;
