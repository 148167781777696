import { MantineTheme, createStyles, keyframes } from '@mantine/core';

const grow = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '50%': {
    transform: 'scale(1.5)',
  },
});

type NotificationBadgeStylesProps = {
  show: boolean;
  animate: boolean;
};

const NotificationBadgeStyles = createStyles(
  (theme: MantineTheme, { show, animate }: NotificationBadgeStylesProps) => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      fontWeight: 900,
      padding: 0,
      fontSize: '10px',
      border: `2px solid ${theme.white}`,
      translate: show ? '50% -50%' : '40% -50%',
      animation: animate ? `${grow} 0.5s ease-in-out` : 'none',
      opacity: show ? 1 : 0,
      transition: 'all 150ms ease',
      pointerEvents: 'none',
    },
  }),
);

export default NotificationBadgeStyles;
