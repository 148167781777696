import { create } from 'zustand';
import { persist, createJSONStorage, subscribeWithSelector } from 'zustand/middleware';

export type CartProduct = {
  productVariantId: string;
  ssId?: string | undefined;
  ssUid?: string | undefined;
  ssSku?: string | undefined;
  ssVariantSku?: string | undefined;
  intellisuggestData?: string;
  intellisuggestSignature?: string;
  quantity: number;
  price: string;
  attributes?: Array<{
    key: string;
    value: string;
  }>;
};

type Cart = {
  id: string;
  checkoutUrl: string;
  products: CartProduct[] | [];
};

export type PartialCart = Omit<Partial<Cart>, 'products'> & {
  products: Record<string, CartProduct>;
};

interface CartState {
  cartId: string | null;
  cartUpdatedKey: string | null;
  setCartId: (cartId: string | null) => void;
  setCartUpdatedKey: () => void;
}

const useCartStore = create(
  subscribeWithSelector(
    persist<CartState | undefined>(
      (set) => ({
        cartId: null,
        cartUpdatedKey: null,
        setCartId: (cartId) => set({ cartId }),
        setCartUpdatedKey: () => set({ cartUpdatedKey: Math.random().toString(20).substring(2, 15) }),
      }),
      {
        name: 'cart-state',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export default useCartStore;
