import React from 'react';

const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 16.7165C15.0405 17.7843 13.4516 18.4322 11.7113 18.4322C7.92225 18.4322 4.85059 15.3605 4.85059 11.5715C4.85059 7.78236 7.92225 4.71069 11.7113 4.71069C15.5004 4.71069 18.5721 7.78236 18.5721 11.5715C18.5721 13.3117 17.9241 14.9006 16.8564 16.1101L20.5474 19.8011C20.7148 19.9685 20.7148 20.24 20.5474 20.4075C20.3799 20.5749 20.1084 20.5749 19.941 20.4075L16.25 16.7165ZM17.7145 11.5715C17.7145 14.8869 15.0268 17.5746 11.7113 17.5746C8.39591 17.5746 5.70818 14.8869 5.70818 11.5715C5.70818 8.25599 8.39591 5.56829 11.7113 5.56829C15.0268 5.56829 17.7145 8.25599 17.7145 11.5715Z"
      fill="black"
      stroke="black"
      strokeWidth="0.2"
    />
  </svg>
);

export default SearchIcon;
