import { create } from 'zustand';
import { createSelectors } from './selectors';

interface ParentCollectionState {
  parentCollection: string;
  setParentCollection: (collectionHandle: string) => void;
}

const useParentCollectionStore = create<ParentCollectionState>((set) => ({
  parentCollection: '',
  setParentCollection: (collectionHandle: string) => set({ parentCollection: collectionHandle }),
}));

export const parentCollectionSelectors = {
  parentCollectionStore: createSelectors(useParentCollectionStore),
};
