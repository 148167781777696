export const clothingSizes = [
  ['SIZE', 'BUST', 'WAIST', 'HIPS'],
  ['US0/AU4/XXS', '76cm/30in', '58cm/23in', '86cm/34in'],
  ['US2/AU6/XS', '81cm/32in', '63cm/25in', '91cm/36in'],
  ['US4/AU8/S', '86cm/34in', '68cm/27in', '96cm/38in'],
  ['US6/AU10/M', '91cm/36in', '73cm/29in', '101cm/40in'],
  ['US8/AU12/L', '96cm/38in', '78cm/30.5in', '106cm/41.5in'],
  ['US10/AU14/XL', '101cm/40in', '83cm/32.5in', '111cm/43.5in'],
];

export const sizeConversions = [
  ['SIZE', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
  ['AU', '4', '6', '8', '10', '12', '14', '16'],
  ['US', '0', '2', '4', '6', '8', '10', '12'],
  ['UK', '4', '6', '8', '10', '12', '14', '16'],
  ['EU', '32', '34', '36', '38', '40', '42', '44'],
];

export const shoeSizes = [
  ['US', '4', '5', '6', '7', '8', '9', '10', '11'],
  ['AU', '4', '5', '6', '7', '8', '9', '10', '11'],
  ['UK', '2', '3', '4', '5', '6', '7', '8', '9'],
  ['EU', '35', '36', '37', '38', '39', '40', '41', '42'],
];
