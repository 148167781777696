import React from 'react';

export default function ReturnsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68229 4.27734H17.349L20.0156 8.27734V20.2773H4.01562V8.27734L6.68229 4.27734Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0146 4.27734V8.27734" stroke="#0D0005" strokeWidth="0.8" />
      <path d="M4.01562 8.27734H20.0156" stroke="#0D0005" strokeWidth="0.8" />
      <path
        d="M13.3721 17.7197L10.9886 15.3359L13.3721 12.9492"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9885 15.3359L15.5275 15.3359C16.1827 15.3359 16.8111 15.5962 17.2744 16.0596C17.7378 16.5229 17.998 17.1513 17.998 17.8065C17.9978 18.4617 17.7375 19.09 17.2742 19.5533C16.8109 20.0165 16.1826 20.2769 15.5275 20.2771"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
