import { MantineTheme } from '@mantine/core';

const passwordInput = {
  styles: (theme: MantineTheme) => ({
    wrapper: {
      border: 'none',
      '&:focus': {
        border: `0.0625rem solid ${theme.black}`,
      },
    },
    input: {
      background: 'transparent',
      border: 'none',
    },
    root: {
      input: {
        letterSpacing: '0.05rem',
        '&::placeholder': {
          letterSpacing: '0.05rem',
        },
      },
    },
  }),
};

export default passwordInput;
