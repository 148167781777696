import React from 'react';
import { useRouter } from 'next/router';
import { setCookie } from '@ui/hooks/useCookie';
import { Cart } from '@client-shopify/gql/storefront/api/queries/GetCart';
import SSIntelliSuggestCartView from '@ui/axios/searchSpring/intellisuggestCartView';
import { ActionIcon, BagIcon, CompactCloseIcon, LockIcon, Progress } from '../../../core';
import CartProductCard from '../../cards/CartProductCard/CartProductCard';
import env from '@ui/env';
import useCartStore from '@ui/store/cartStore';
import Link from 'next/link';
import { Drawer, ScrollArea } from '@mantine/core';
import CartUpsellCarousel from '../../carousels/CartUpsellCarousel/CartUpsellCarousel';
import { Button } from '../../buttons/Button/Button';
import cn from '@ui/utils/cn';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { useCartBuyerUpdate } from '@ui/hooks/useCartMutations';
import { useAuthCustomer } from '@ui/hooks/useAuthCustomer';
import { useCookie } from '@ui/hooks/useCookie';
import { authSelectors } from '@ui/store/authStore';
import { fetchMultipassToken } from '@ui/utils/multipass';
import { useStaticContent } from '@ui/providers/static-content-provider';
import Money from '../../formatters/Money/Money';
import useCustomerCountry from '@ui/hooks/useCustomerCountry';

const slideLeft = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(100%)' },
  transitionProperty: 'transform',
};

type CartDrawerProps = {
  cart: Cart | null | undefined;
  cartItemCount: number;
  opened: boolean;
  onClose: () => void;
  className?: string;
};

const CartDrawer = ({ cartItemCount, opened, onClose, className }: CartDrawerProps): React.ReactElement => {
  const router = useRouter();
  const [loading, setLoading] = React.useState(false);
  const token = authSelectors.persistantAuth.use.token();
  const isLoggedIn = authSelectors.auth.use.isLoggedIn();
  const shopifyBuyerIp = useCookie('shopifyBuyerIp');
  const { fetchAuthCustomer } = useAuthCustomer();
  const { CartBuyerIdentityUpdateMutation } = useCartBuyerUpdate();
  const cartId = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);

  const { data: getCartQueryResults, isFetching } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  const cartLineItems = getCartQueryResults?.cart?.cartLines || [];
  const cart = getCartQueryResults?.cart;

  React.useEffect(() => {
    if (!cartUpdatedKey || !getCartQueryResults?.cart?.cartLines || !getCartQueryResults?.cart?.cartLines?.length)
      return;

    let cartString = getCartQueryResults.cart.cartLines.map((cartProduct) => cartProduct.sku).join(',');

    setCookie('cart', cartString);
  }, [cartUpdatedKey, getCartQueryResults?.cart?.cartLines]);

  function handleClose() {
    onClose();
  }

  const customerCountry = useCustomerCountry();

  return (
    <Drawer
      withOverlay
      withCloseButton={false}
      opened={opened}
      onClose={() => {
        onClose();
      }}
      transitionProps={{
        duration: 300,
        timingFunction: 'ease-in-out',
        transition: slideLeft,
      }}
      position="right"
      className={className}
      shadow="md"
      classNames={{
        overlay: 'bg-black/20',
        body: 'p-0 isolate flex flex-col h-full overflow-hidden',
        content: 'basis-[28.125rem]',
      }}
    >
      <div className="divide-y divide-black bg-white border-b border-black z-10">
        <div className="p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="space-x-2 flex items-center">
              <div className="text-[14px] font-bold tracking-[0.01em]">MY BAG</div>
              <div className="text-xs">
                {cartItemCount} {cartItemCount === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className="flex items-center">
              <ActionIcon variant="transparent" onClick={handleClose} data-autofocus>
                <CompactCloseIcon height={16} width={16} />
              </ActionIcon>
            </div>
          </div>
        </div>

        {cart && cartLineItems.length > 0 && customerCountry === env.NEXT_PUBLIC_REGION && (
          <div className="p-4 py-3">
            <div className="space-y-1.5">
              {cart.shipping.free ? (
                <div className="text-xs text-center uppercase">
                  <b>YAY!</b> You get free {env.NEXT_PUBLIC_REGION} shipping.
                </div>
              ) : (
                <div className="text-xs text-center uppercase">
                  You are{' '}
                  <b>
                    {env.NEXT_PUBLIC_REGION}${cart.shipping.amountTillFree}
                  </b>{' '}
                  away from {env.NEXT_PUBLIC_REGION} free shipping.
                </div>
              )}

              <div className="border border-black rounded-full bg-white p-0.5">
                <Progress
                  value={cart.shipping.free ? 100 : (1 - parseFloat(cart.shipping.amountTillFree) / 150) * 100}
                  className="transition-all ease-in-out duration-1000 bg-white w-full h-1"
                  color="#EA98B8"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex-1 min-h-0">
        {cartLineItems.length > 0 ? (
          <ScrollArea className="h-full">
            <div className="p-4 py-0 divide-y-[0.6px] divide-black">
              {cartLineItems.map((product) => (
                <div key={product.variantId} className="py-4 md:py-6">
                  <CartProductCard product={product} onClose={onClose} />
                </div>
              ))}
            </div>
          </ScrollArea>
        ) : (
          <>
            {!isFetching && (
              <div className="h-full px-4 py-8 space-y-[26px] flex flex-col items-center justify-center">
                <div className="space-y-2 text-center">
                  <BagIcon className="inline-block" width={72} height={72} />
                  <div className="font-normal tracking-[0.004em]">YOUR BAG IS EMPTY</div>
                  <div className="tracking-[0.4px]">{`Don't know where to start?`}</div>
                </div>
                <div className="space-y-2 w-[174px]">
                  <Button className="w-full" asChild>
                    <Link href="/collections/dresses">Dresses</Link>
                  </Button>
                  <Button className="w-full" asChild>
                    <Link href="/collections/shoes">Shoes</Link>
                  </Button>
                  <Button className="w-full" asChild>
                    <Link href="/collections/swim">Swim</Link>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className={cn('shrink-0 bg-white', cartLineItems.length > 0 && 'shadow-[0px_-4px_8px_0px_#00000014]')}>
        {cartLineItems.length > 0 && (
          <div className="p-4 space-y-3">
            <div className="flex justify-between">
              <div className="font-normal text-[14px] uppercase">Subtotal</div>
              <div className="font-normal text-[14px]">
                <Money value={cart?.subtotalAmount.amount} currency={cart?.subtotalAmount.currencyCode} />
              </div>
            </div>
            <Button
              asChild
              loading={loading}
              className="w-full inline-flex"
              onClick={async (e) => {
                e.preventDefault();
                let cartUrl;
                try {
                  cartUrl = new URL(cart?.checkoutUrl);
                } catch (err) {
                  cartUrl = cart?.checkoutUrl;
                }
                if (!cart) return;
                setLoading(true);
                if (isLoggedIn) {
                  try {
                    const authData = await fetchAuthCustomer(token ?? '');

                    if (!authData) {
                      router.push(cartUrl.href);
                      setLoading(false);
                      return;
                    }

                    try {
                      const cartBuyerUpdate = await CartBuyerIdentityUpdateMutation({
                        buyerIP: shopifyBuyerIp,
                        cartId: cart.id ?? '',
                        buyerIdentity: {
                          customerAccessToken: token ?? '',
                          countryCode: customerCountry,
                          email: authData.customerResult.data?.customer?.email || '',
                          phone: authData.customerResult.data?.customer?.phone || undefined,
                          deliveryAddressPreferences: [
                            {
                              deliveryAddress: {
                                firstName: authData.customerResult.data?.customer?.firstName,
                                lastName: authData.customerResult.data?.customer?.lastName,
                                phone: authData.customerResult.data?.customer?.phone,
                                address1: authData.customerResult.data?.customer?.defaultAddress?.address1,
                                city: authData.customerResult.data?.customer?.defaultAddress?.city,
                                country: authData.customerResult.data?.customer?.defaultAddress?.country,
                                province: authData.customerResult.data?.customer?.defaultAddress?.province,
                                zip: authData.customerResult.data?.customer?.defaultAddress?.zip,
                              },
                            },
                          ],
                        },
                      });

                      if (!cartBuyerUpdate || !cartBuyerUpdate?.cart.id) {
                        router.push(cartUrl.href);
                        setLoading(false);
                        return;
                      }

                      try {
                        const multipassToken = await fetchMultipassToken({
                          userToken: token ?? '',
                          checkoutUrl: cartUrl.href ?? '',
                        });

                        if (!multipassToken || !multipassToken?.redirectURL) {
                          router.push(cartUrl.href);
                          setLoading(false);
                          return;
                        }

                        setLoading(false);
                        return (window.location.href = multipassToken.redirectURL);
                      } catch (err) {
                        throw new Error(JSON.stringify(err));
                      }
                    } catch (err) {
                      throw new Error(JSON.stringify(err));
                    }
                  } catch (err) {
                    throw new Error(JSON.stringify(err));
                  }
                } else {
                  router.push(cartUrl.href);
                  setLoading(false);
                  return;
                }
              }}
            >
              <a className="hover:cursor-pointer">
                <LockIcon className="size-4 mr-2" />
                <span>Checkout</span>
              </a>
            </Button>
          </div>
        )}
        <div className="bg-[#FAF1F5] space-y-2 border-y border-black">
          <CartUpsellCarousel
            beaconPlacement="cart"
            classNames={{
              root: 'bg-[#FAF1F5]',
              viewport: 'p-4 px-0 mx-6 md:mx-10',
              controls: 'mx-0 md:mx-3',
            }}
          />
        </div>
      </div>

      {cartLineItems.length > 0 && <SSIntelliSuggestCartView cartQueryResults={getCartQueryResults} />}
    </Drawer>
  );
};

export default CartDrawer;
