import { gql } from '../../__generated__/gql';

export const CART_LINES_UPDATE = gql(`
  mutation cartLinesUpdate ($cartId: ID!, $cartLinesUpdateInput: [CartLineUpdateInput!]!) {
    cartLinesUpdate (cartId: $cartId, lines: $cartLinesUpdateInput) {
      cart {
        id
        checkoutUrl
        buyerIdentity {
          countryCode
          email
          phone
          customer {
            id
          }
        }
        lines(first: 32) {
          edges {
            node {
              id
              quantity
              cost {
                amountPerQuantity {
                  amount
                  currencyCode
                }
              }
              attributes {
                key
                value
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  availableForSale
                  quantityAvailable
                  price {
                    amount
                    currencyCode
                  }
                  product {
                    id
                    handle
                    title
                    vendor
                    availableForSale
                    tags
                    description
                    images (first: 1) {
                      edges {
                        node {
                          id
                          url
                          altText
                        }
                      }
                    }
                    featuredImage {
                      id
                      url
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
        }
        createdAt
        updatedAt
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`);
