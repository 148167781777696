import { DownChevronIcon } from '@ui/components/core';
import env from '@ui/env';
import cn from '@ui/utils/cn';
import { Accordion, type AccordionProps } from '@mantine/core';
import { useRouter } from 'next/router';
import React from 'react';

type FAQAccordionProps = AccordionProps & {
  handle: string;
};

const FAQAccordion = ({ value, handle, ...props }: FAQAccordionProps) => {
  const router = useRouter();

  return (
    <Accordion
      {...props}
      defaultValue={value || router.query.slug?.[1]}
      onChange={(newValue) => {
        if (!newValue) return;
        const slug = [handle, newValue].join('/');
        router.replace(`/pages/faq/${slug}/`, undefined, { shallow: true });
      }}
      chevron={<DownChevronIcon width={16} height={16} />}
      classNames={{
        item: cn(
          env.FAQ_PREVIEW &&
            'border-b-[0.6px] border-b-solid border-b-black px-0 py-6 md:py-[1.625rem] last:border-b-0 scroll-mt-32',
          env.FAQ_PREVIEW && 'first:pt-3 md:first:pt-[1.625rem] last:pb-3 md:last:pb-[1.625rem]',
          !env.FAQ_PREVIEW && 'border-b-[0.6px] border-b-solid border-b-black px-0 py-6 last:border-b-0 scroll-mt-32',
        ),
        control: 'p-0 hover:bg-white',
        label: 'p-0 text-[13px] uppercase font-normal',
        content: 'p-0 pt-1.5',
      }}
    />
  );
};

export default FAQAccordion;
