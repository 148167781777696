import { Client, OperationResult, cacheExchange, createClient, fetchExchange } from '@urql/core';
import { DocumentNode, print } from 'graphql';
import fetch from 'cross-fetch';
import env from '../../../env';

const RunMutation = async <T = any>(
  mutation: DocumentNode,
  variables: object,
  buyerIP?: string | undefined,
): Promise<OperationResult<T, object>> => {
  const endpoint = `https://${env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/${env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_API_VERSION}/graphql.json`;
  const token = env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

  const graphQLClient: Client = createClient({
    url: endpoint,
    requestPolicy: 'cache-and-network',
    exchanges: [cacheExchange, fetchExchange],
    fetch: fetch,
    fetchOptions: () => ({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
        'Shopify-Storefront-Buyer-IP': buyerIP !== undefined ? buyerIP : '',
      },
      body: JSON.stringify({ Mutation: print(mutation), variables }),
    }),
  });

  return await graphQLClient.mutation(mutation, variables).toPromise();
};

export default RunMutation;
