import React from 'react';

export default function AccountLogOutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.3125 8.19336L14.3125 6.19336L6.3125 6.19336L6.3125 19.1934L14.3125 19.1934L14.3125 17.1934"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3125 12.9434L19.3125 12.9434M19.3125 12.9434L16.3125 15.1934M19.3125 12.9434L16.3125 10.1934"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
