import React from 'react';
import NextImage from '../../NextImage/NextImage';

type NZFLagIconProps = {
  height: number;
  width: number;
};

const NZFlagIcon = ({ height, width }: NZFLagIconProps) => (
  <NextImage src={'/region/NZFlag.svg'} alt="Hello Molly NZ Flag Icon" height={height} width={width} />
);

export default NZFlagIcon;
