import { MantineThemeOverride } from '@mantine/core';
import '../../fonts.css';
import {
  avatar,
  button,
  card,
  chip,
  carousel,
  checkbox,
  container,
  drawer,
  hovercard,
  modal,
  notification,
  numberInput,
  passwordInput,
  tabs,
  text,
  textInput,
  themeIcon,
} from '../styles';

const themeOverride: MantineThemeOverride = {
  fontFamily: '"Lato", sans-serif',
  colorScheme: 'light',
  colors: {
    brand: [
      '#FEFBFC',
      '#FDF7F9',
      '#FCF3F6',
      '#FAE6EE',
      '#F7D6E3',
      '#F3C5D7',
      '#F2BDD2',
      '#EEADC6',
      '#EA98B8',
      '#CB004F',
    ],
    // prettier-ignore
    black: [
      '#F5F5F5',
      '#EBEBEB',
      '#D6D6D6',
      '#C2C2C2',
      '#6F6F6F',
      '#707070',
      '#5C5C5C',
      '#333333',
      '#000000'
    ],
    // prettier-ignore
    red: [
      '#FFF5F5',
      '#FFE3E3',
      '#FFC9C9',
      '#FFA8A8',
      '#FF8787',
      '#FF6B6B',
      '#FA5252',
      '#F03E3E',
      '#E03131',
      '#C90000'
    ],
    // prettier-ignore
    grey: [
      '#FDFDFD',
      '#E2E2E2',
      '#C6C6C6'
    ],
  },
  primaryColor: 'brand',
  components: {
    Avatar: avatar,
    Button: button,
    Card: card,
    Carousel: carousel,
    Checkbox: checkbox,
    Chip: chip,
    Container: container,
    Drawer: drawer,
    HoverCard: hovercard,
    Modal: modal,
    Notification: notification,
    NumberInput: numberInput,
    PasswordInput: passwordInput,
    Tabs: tabs,
    Text: text,
    TextInput: textInput,
    ThemeIcon: themeIcon,
  },
};

export default themeOverride;
