import {
  AccountIcon,
  AccountContactPreferencesIcon,
  AccountMyAddressIcon,
  AccountMyDetailsIcon,
  AccountMyOrdersIcon,
  AccountMyReturnsIcon,
  AccountWishlistIcon,
  AccountStoreCreditIcon,
  AccountHelpCentreIcon,
  AccountLogInIcon,
  AccountSignUpIcon,
  AccountChangePasswordIcon,
} from '@ui/components/core';
import React from 'react';

type AccountNavLink = {
  href: string;
  label: string;
  description?: string;
  IconComponent: () => React.ReactElement;
};

export const AccountNavLinks = {
  header: {
    loggedIn: [
      {
        href: '/account/',
        label: 'My Account',
        IconComponent: () => <AccountIcon width={24} height={24} />,
      },
      {
        href: '/account/orders/',
        label: 'My Orders',
        IconComponent: () => <AccountMyOrdersIcon width={24} height={24} />,
      },
      {
        href: '/account/details/',
        label: 'My Details',
        IconComponent: () => <AccountMyDetailsIcon width={20} height={20} />,
      },
      {
        href: '/pages/contact-us/',
        label: 'Help Centre',
        IconComponent: () => <AccountHelpCentreIcon height={24} width={24} />,
      },
    ],
    loggedOut: {
      top: [
        {
          href: '/account/login/',
          label: 'Log in',
          IconComponent: () => <AccountLogInIcon height={24} width={24} />,
        },
        {
          href: '/account/signup/',
          label: 'Sign up',
          IconComponent: () => <AccountSignUpIcon height={24} width={24} />,
        },
      ],
      bottom: [
        {
          href: '/pages/contact-us/',
          label: 'Help Centre',
          IconComponent: () => <AccountHelpCentreIcon height={24} width={24} />,
        },
      ],
    },
  },
  mobile: [
    {
      href: '/account/profile/',
      label: 'My Account',
      IconComponent: () => <AccountIcon width={24} height={24} />,
    },
    {
      href: '/account/orders/',
      label: 'My Orders',
      IconComponent: () => <AccountMyOrdersIcon width={24} height={24} />,
    },
    {
      href: '/account/wishlist/',
      label: 'Wishlist',
      IconComponent: () => <AccountWishlistIcon width={24} height={24} />,
    },
    {
      href: '/account/store-credits/',
      label: 'Store Credits',
      IconComponent: () => <AccountStoreCreditIcon width={24} height={24} />,
    },
    {
      href: '/account/details/',
      label: 'Personal Details',
      IconComponent: () => <AccountMyDetailsIcon width={24} height={24} />,
      subItems: [
        {
          href: '/account/details/',
          label: 'My Details',
          IconComponent: () => <AccountMyDetailsIcon width={24} height={24} />,
        },
        {
          href: '/account/addresses/',
          label: 'Address Book',
          IconComponent: () => <AccountMyAddressIcon width={24} height={24} />,
        },
        // {
        //   href: '/account/contact-preferences/',
        //   label: 'Contact Preferences',
        //   IconComponent: () => <AccountContactPreferencesIcon width={24} height={24} />,
        // },
      ],
    },
    {
      href: '/pages/contact-us?fromAccount=true',
      label: 'Help Centre',
      IconComponent: () => <AccountHelpCentreIcon height={24} width={24} />,
    },
  ],
  desktop: {
    top: [
      {
        href: '/account/profile/',
        label: 'My Account',
        IconComponent: () => <AccountIcon width={24} height={24} />,
      },
      {
        href: '/account/orders/',
        label: 'My Orders',
        IconComponent: () => <AccountMyOrdersIcon width={24} height={24} />,
      },
      {
        href: '/account/wishlist/',
        label: 'Wishlist',
        IconComponent: () => <AccountWishlistIcon width={24} height={24} />,
      },
      {
        href: '/account/store-credits/',
        label: 'Store Credits',
        IconComponent: () => <AccountStoreCreditIcon width={24} height={24} />,
      },
    ],
    center: [
      {
        href: '/account/details/',
        label: 'My Details',
        IconComponent: () => <AccountMyDetailsIcon width={24} height={24} />,
      },
      {
        href: '/account/addresses/',
        label: 'Address Book',
        IconComponent: () => <AccountMyAddressIcon width={24} height={24} />,
      },
      // {
      //   href: '/account/contact-preferences/',
      //   label: 'Contact Preferences',
      //   IconComponent: () => <AccountContactPreferencesIcon width={24} height={24} />,
      // },
    ],
    bottom: [
      {
        href: '/pages/contact-us?fromAccount=true',
        label: 'Help Centre',
        IconComponent: () => <AccountHelpCentreIcon height={24} width={24} />,
      },
    ],
  },
};
