import React from 'react';
import { Accordion, NextLink, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQPopularQuestions = (): React.ReactElement => (
  <FAQAccordion handle="popular-questions">
    {useStaticContent('FAQ.PopularQuestions.Collaboration') && (
      <Accordion.Item value="collaboration">
        <Accordion.Control>Can I collaborate with Hello Molly on Social Media?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.Collaboration" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.PhysicalStore') && (
      <Accordion.Item value="physical-store">
        <Accordion.Control>Do you have a store I can visit?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.PhysicalStore" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.StoreCredit') && (
      <Accordion.Item value="store-credit">
        <Accordion.Control>How can I access my store credit?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.StoreCredit" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.ShippingSchedule') && (
      <Accordion.Item value="shipping-schedule">
        <Accordion.Control>How long will it take for me to receive my order?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.ShippingSchedule" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.MissingConfirmationEmail') && (
      <Accordion.Item value="missing-confirmation-email">
        <Accordion.Control>
          I didn&apos;t receive an order or shipping confirmation. What should I do?
        </Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.MissingConfirmationEmail" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.BusinessHours') && (
      <Accordion.Item value="business-hours">
        <Accordion.Control>I want to call you. What are your working hours?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.BusinessHours" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.StoreLocation') && (
      <Accordion.Item value="store-location">
        <Accordion.Control>Where are you located?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.StoreLocation" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.PopularQuestions.ReturnAddress') && (
      <Accordion.Item value="return-address">
        <Accordion.Control>Where do I send returns?</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.PopularQuestions.ReturnAddress" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQPopularQuestions;
