import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_ACCESS_TOKEN_CREATE } from '../../documents/mutations';
import type {
  CustomerAccessTokenCreateMutation,
  CustomerAccessTokenCreateMutationVariables,
} from '../../__generated__/graphql';

export async function CustomerAccessTokenCreate({
  customerAccessTokenCreateInput,
}: CustomerAccessTokenCreateMutationVariables): Promise<{
  data: CustomerAccessTokenCreateMutation | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunMutation(CUSTOMER_ACCESS_TOKEN_CREATE, {
    customerAccessTokenCreateInput: customerAccessTokenCreateInput,
  });
  return { data, error };
}
