import React from 'react';

const LeftArrowIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.40792 11.4092C7.40841 11.4097 7.40925 11.4097 7.40975 11.4092L15.2956 3.12828C15.4566 2.95919 15.7263 2.95919 15.8873 3.12828C16.0376 3.28607 16.0376 3.53401 15.8873 3.6918L8.62974 11.313C8.26197 11.6992 8.26197 12.306 8.62974 12.6922L15.8837 20.3096C16.0339 20.4674 16.0339 20.7153 15.8837 20.8731C15.7226 21.0422 15.4529 21.0422 15.2919 20.8731L7.11114 12.2825C6.96087 12.1247 6.96087 11.8767 7.11114 11.7189L7.40608 11.4092C7.40658 11.4087 7.40742 11.4087 7.40792 11.4092Z"
      fill="black"
    />
    <path
      d="M7.40608 11.4092L7.04399 11.0644L7.40608 11.4092ZM7.11114 12.2825L6.74905 12.6273L7.11114 12.2825ZM7.11114 11.7189L7.47322 12.0637L7.11114 11.7189ZM15.2919 20.8731L15.654 20.5283L15.2919 20.8731ZM15.8837 20.3096L16.2457 19.9648L15.8837 20.3096ZM8.62974 11.313L8.99183 11.6578L8.62974 11.313ZM8.62974 12.6922L8.99183 12.3474L8.62974 12.6922ZM7.40975 11.4092L7.04767 11.0644L7.40975 11.4092ZM15.6576 3.47309L7.77184 11.754L7.04767 11.0644L14.9335 2.78347L15.6576 3.47309ZM8.26765 10.9682L15.5252 3.34699L16.2494 4.03661L8.99183 11.6578L8.26765 10.9682ZM15.5216 20.6544L8.26765 13.0371L8.99183 12.3474L16.2457 19.9648L15.5216 20.6544ZM7.47322 11.9377L15.654 20.5283L14.9298 21.2179L6.74905 12.6273L7.47322 11.9377ZM7.76816 11.754L7.47322 12.0637L6.74905 11.3741L7.04399 11.0644L7.76816 11.754ZM7.04399 11.0644C7.24152 10.857 7.5724 10.8569 7.76996 11.0643L7.04587 11.7541C7.24243 11.9604 7.57163 11.9604 7.76816 11.754L7.04399 11.0644ZM6.74905 12.6273C6.4149 12.2764 6.4149 11.725 6.74905 11.3741L7.47322 12.0637C7.50684 12.0284 7.50684 11.973 7.47322 11.9377L6.74905 12.6273ZM16.2457 21.2179C15.8877 21.594 15.2879 21.594 14.9298 21.2179L15.654 20.5283C15.618 20.4905 15.5576 20.4905 15.5216 20.5283L16.2457 21.2179ZM16.2457 19.9648C16.5799 20.3157 16.5799 20.8671 16.2457 21.2179L15.5216 20.5283C15.4879 20.5636 15.4879 20.6191 15.5216 20.6544L16.2457 19.9648ZM8.99183 11.6578C8.80794 11.8509 8.80794 12.1543 8.99183 12.3474L8.26765 13.0371C7.716 12.4578 7.716 11.5475 8.26765 10.9682L8.99183 11.6578ZM16.2494 2.78347C16.5835 3.13436 16.5835 3.68572 16.2494 4.03661L15.5252 3.34699C15.4916 3.3823 15.4916 3.43778 15.5252 3.47309L16.2494 2.78347ZM7.77184 11.754C7.57411 11.9616 7.24323 11.9612 7.04587 11.7541L7.76996 11.0643C7.5736 10.8582 7.24439 10.8578 7.04767 11.0644L7.77184 11.754ZM14.9335 2.78347C15.2915 2.40746 15.8913 2.40746 16.2494 2.78347L15.5252 3.47309C15.5612 3.51092 15.6216 3.51092 15.6576 3.47309L14.9335 2.78347Z"
      fill="black"
    />
  </svg>
);

LeftArrowIcon.defaultProps = {
  className: undefined,
};

export default LeftArrowIcon;
