import RunBlogQuery from '../../utils/RunBlogQuery.ts';
import { GET_ARTICLE } from '../../documents/queries/GetArticle.ts';
import { type GetArticleQuery, type GetArticleQueryVariables } from '../../__generated__/graphql';

export async function GetArticle({ handle }: GetArticleQueryVariables) {
  const { data, error } = await RunBlogQuery<GetArticleQuery>(GET_ARTICLE, {
    handle,
  });

  if (!handle || !data?.blog || !data?.blog?.articleByHandle) return null;

  const articleData = data.blog.articleByHandle;

  if (!articleData) return null;

  const article = {
    id: articleData.id,
    handle: articleData.handle,
    title: articleData.title,
    contentHtml: articleData.contentHtml,
    content: articleData.content,
    publishedAt: articleData.publishedAt,
    image: {
      url: articleData.image?.url || null,
      altText: articleData.image?.altText || null,
    },
    seo: {
      title: articleData.seo?.title || null,
      description: articleData.seo?.description || null,
    },
    tags: articleData.tags,
  };

  return { article, error };
}
