const modal = {
  styles: {
    overlay: {
      zIndex: 1001,
    },
    inner: {
      zIndex: 1002,
    },
  },
};

export default modal;
