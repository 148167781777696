import React from 'react';

const SwimClassicIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.0031 60.2557C36.2097 60.2557 35.4622 59.9344 34.9179 59.3705L34.8195 59.259C32.813 56.9574 27.8163 51.0885 24.8261 46.3869C21.1802 40.6754 14.4064 40.3017 14.3343 40.2951L14.0195 40.282V31.5607H65.9671V40.282L65.6523 40.2951C65.5867 40.2951 58.813 40.6689 55.1605 46.3869C52.1704 51.0885 47.1736 56.9508 45.1671 59.259L45.1277 59.3049L45.1081 59.318C44.5638 59.9148 43.7966 60.2557 42.99 60.2557H37.0031ZM54.1966 44.2164C48.2687 45.5213 45.8294 53.3902 45.0753 56.5836C46.2097 55.1344 47.1212 53.9476 47.9212 52.9049C49.7638 50.5049 50.9835 48.918 52.9638 46.682C54.59 44.8394 54.4392 44.341 54.413 44.2885C54.3867 44.2295 54.2622 44.2164 54.2031 44.2164V43.8885L54.19 44.2164H54.1966ZM25.79 44.2164C25.731 44.2164 25.613 44.2361 25.5867 44.2885C25.5671 44.341 25.4097 44.8328 27.0359 46.682C29.0294 48.9443 30.2622 50.5508 32.1245 52.9836C32.9113 54.0066 33.8031 55.1738 34.9113 56.5836C34.1572 53.3902 31.7244 45.5476 25.7835 44.2164H25.79ZM23.6458 33.5148C29.5212 33.823 34.249 33.9803 38.9769 33.9803C43.7048 33.9803 48.2884 33.8295 54.9769 33.5017C49.8949 33.4164 44.7474 33.3771 39.5933 33.3771C34.4392 33.3771 28.9048 33.423 23.6458 33.5082V33.5148Z"
      fill="currentColor"
    />
    <path
      d="M65.6458 31.8885V39.9672C65.6458 39.9672 58.6818 40.2754 54.8917 46.2098C51.8818 50.9377 46.8589 56.8262 44.931 59.0426L44.9114 59.0557C44.4327 59.6131 43.731 59.9344 42.9966 59.9344H37.0097C36.2753 59.9344 35.5671 59.6197 35.0818 59.0557C35.0818 59.0557 35.0818 59.0426 35.0687 59.0426C33.1409 56.8328 28.1179 50.9443 25.1081 46.2098C21.3179 40.2754 14.354 39.9672 14.354 39.9672V31.8885H65.6458ZM38.9769 34.3148C45.7638 34.3148 52.5769 33.9869 63.9146 33.3705C55.9474 33.1607 47.7704 33.0557 39.5933 33.0557C31.4163 33.0557 23.2392 33.1607 15.2851 33.3705C25.8753 34.0131 32.4196 34.3148 38.9769 34.3148ZM44.472 57.882C48.8392 52.3541 50.1507 50.3607 53.2064 46.8984C55.7573 44.0066 54.4392 43.8885 54.2032 43.8885C54.1835 43.8885 54.1704 43.8885 54.1704 43.8885C46.3868 45.5934 44.472 57.882 44.472 57.882ZM35.5146 57.882C35.5146 57.882 33.6064 45.6 25.8228 43.8885C25.8228 43.8885 25.8097 43.8885 25.79 43.8885C25.554 43.8885 24.2359 44.0066 26.7868 46.8984C29.8294 50.3607 31.154 52.3475 35.5146 57.882ZM66.3015 31.2328H13.6982V40.5967L14.3278 40.623C14.3933 40.623 21.0032 40.9967 24.5573 46.5639C27.5605 51.2787 32.5638 57.1607 34.5769 59.4754L34.7737 59.6984H34.7933C35.3835 60.2623 36.1769 60.5836 37.0097 60.5836H42.9966C43.8753 60.5836 44.7146 60.223 45.3179 59.5869L45.4228 59.4754C47.4359 57.1672 52.4392 51.2852 55.4425 46.5639C59.0032 40.9836 65.5999 40.6295 65.672 40.6295L66.3015 40.6033V31.2393V31.2328ZM45.8032 55.1148C46.8458 51.5672 49.2458 45.8295 54.0458 44.5902C53.9409 44.8656 53.6261 45.4295 52.7212 46.459C50.7343 48.7082 49.4491 50.3869 47.6655 52.7016C47.1015 53.4361 46.4982 54.2164 45.8097 55.1082L45.8032 55.1148ZM34.19 55.1148C33.5212 54.2557 32.9376 53.4885 32.3933 52.7803C30.5245 50.3475 29.2851 48.7344 27.2917 46.4656C26.3802 45.4361 26.072 44.8656 25.9605 44.5902C30.7605 45.8295 33.154 51.5607 34.19 55.1148Z"
      fill="#231F20"
    />
  </svg>
);

export default SwimClassicIcon;
