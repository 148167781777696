import { Carousel, LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import React, { useState } from 'react';
import ProductCardImageLink from './ProductCardImageLink';
import cn from '@ui/utils/cn';
import { Embla } from '@mantine/carousel';
import CarouselIndicators from '../../carousels/CarouselIndicators/CarouselIndicators';

type ProductCardImageCarousel = {
  imageHeight: number;
  imageWidth: number;
  onImageClick?: React.MouseEventHandler<HTMLAnchorElement>;
  productHandle: string;
  productImages: Array<string>;
  productUrl: string;
  className?: string;
  draggable?: boolean;
  startingSlide?: number;
};

export default function ProductCardImageCarousel({
  imageHeight,
  imageWidth,
  onImageClick,
  productHandle,
  productImages,
  productUrl,
  className,
  draggable,
  startingSlide = 0,
}: ProductCardImageCarousel) {
  const [activeSlide, setActiveSlide] = React.useState(startingSlide);

  // Set the active slide to the starting slide
  const [embla, setEmbla] = useState<Embla | null>(null);
  React.useEffect(() => {
    if (embla) {
      embla.scrollTo(startingSlide, true);
    }
  }, [embla, startingSlide]);

  return (
    <div className={cn('relative', className)}>
      <Carousel
        draggable={draggable}
        nextControlIcon={<RightChevronIcon className="translate-x-2" height={24} width={24} />}
        previousControlIcon={<LeftChevronIcon className="-translate-x-2" height={24} width={24} />}
        onSlideChange={setActiveSlide}
        getEmblaApi={setEmbla}
        classNames={{
          controls: 'opacity-0 transition duration-300 ease-out md:group-hover:opacity-100',
          control: 'bg-transparent size-12 max-w-none',
        }}
        loop
      >
        {productImages.map((image) => (
          <Carousel.Slide key={image} className="relative">
            <ProductCardImageLink
              href={productUrl}
              src={image}
              alt={`${productHandle}-product-image`}
              height={imageHeight}
              width={imageWidth}
              onClick={onImageClick}
              productHandle={productHandle}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      <CarouselIndicators
        className={cn(draggable === false && 'hidden', 'absolute bottom-2 md:opacity-0')}
        activeSlide={activeSlide}
        totalSlides={productImages.length}
      />
    </div>
  );
}
