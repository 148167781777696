import { type Region } from '@ui/providers/static-content-provider';
import { Stack, Center, ShippingIcon, Text } from '@ui/components/core';

export const regionData: Array<Region> = [
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  { value: 'APAC', label: 'Asia Pacific' },
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'OTHER', label: 'All Other Countries' },
];

export const auBody = (
  <Stack spacing="0.75rem">
    <Center>
      <ShippingIcon />
    </Center>
    <Stack spacing={0}>
      <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
        YAY!
      </Text>
      <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
        NEXT DAY DELIVERY!
      </Text>
    </Stack>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      You reside within Australia Post&apos;s Express Post Network (most major metropolitan areas). You can expect next
      business day delivery from the date of dispatch! Order before 2pm local Sydney time and we&apos;ll ship it out the
      same day!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are an estimate only. See chart below for shipping prices and further shipping details.
    </Text>
  </Stack>
);

export const usBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      YAY! EXPRESS 2-4 DAY DELIVERY AVAILABLE!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      We offer a super fast Express shipping option to USA-wide using DHL. Expect to get your order in only 2-4 business
      days from date of dispatch!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you&apos;re not in a big hurry, you can opt for our Standard option. Your order will be delivered by the US
      Postal Service and can take up to 9 days to reach you. Please note this is not a signature required service.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      DHL does not deliver to PO Boxes. We&apos;ll have to ship your order using our Standard service if your shipping
      address is a PO Box.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only. See chart below for shipping prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      International orders may be subject to taxes and duties. See below for more details.
    </Text>
  </Stack>
);

export const nzBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      YAY! EXPRESS 2-4 DAYS DELIVERY AVAILABLE!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      We offer super fast shipping to New Zealand using DHL or FedEx. Expect to get your order in only 1-2 business days
      from date of dispatch!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you&apos;re not in a rush, you can opt for our Standard option for delivery within 5 business days. Please note
      this is not a signature required service.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Express delivery is not available to PO Boxes. We&apos;ll have to ship your order using our Standard service,
      which could take a little longer to deliver, if your shipping address is a PO Box.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are an estimate only. See chart below for shipping prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      International orders may be subject to taxes and duties. See below for more details.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Please note shipping to New Zealand is only available from our AUD and NZD websites.
    </Text>
  </Stack>
);

export const caBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      DELIVERY IN 7-12 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Orders going to Canada are shipped via our local post carrier and delivered by Canada Post. It may take up to 12
      business days from the date of dispatch for your order to be delivered.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are an estimate only. See chart below for shipping prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      International orders may be subject to taxes and duties. See below for more details.
    </Text>
  </Stack>
);

export const gbBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      DELIVERY IN 7-14 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      For orders to the UK, please allow up to 14 business days from date of dispatch for your order to be delivered.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are an estimate only. See chart below for shipping prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      International orders may be subject to taxes and duties. See below for more details.
    </Text>
  </Stack>
);

export const apacBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      DELIVERY IN 5-15 DAYS!
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      For orders to Asia Pacific, please allow up to 15 business days from the date of dispatch for your order to be
      delivered.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are an estimate only. See chart below for shipping prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      International orders may be subject to taxes and duties. See below for more details.
    </Text>
  </Stack>
);

export const otherBody = (
  <Stack spacing="0.75rem">
    <Text fz="1rem" fw={700} lts="0.055" ta="center" tt="uppercase">
      DELIVERY TIMES VARY
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Hello Molly ships worldwide! Orders going to the Rest Of The World should be with you within 2 weeks of dispatch.
      However we do ask that you allow up to a month for delivery in case of unforeseen delays. Thank you for your
      understanding.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      Delivery times are estimates only and may be subject to delays out of our control. See chart below for shipping
      prices.
    </Text>
    <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" ta="center" lh={2}>
      If you don&apos;t see your country listed, prices and more specific transit times will be shown at checkout.
    </Text>
  </Stack>
);
