import { MantineTheme, createStyles, keyframes } from '@mantine/core';

const grow = keyframes({
  '20%': {
    transform: 'scale(1)',
  },
  '40%': {
    transform: 'scale(1.25)',
  },
  '60%': {
    transform: 'scale(1.5)',
  },
  '80%': {
    transform: 'scale(1.25)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const FilledIcon = createStyles((theme: MantineTheme, { filled, animate }: { filled: boolean; animate: boolean }) => ({
  themeColor: {
    position: 'absolute',
    zIndex: 2,
    fill: filled ? theme.colors.brand[4] : 'rgba(0,0,0,0)',
    transition: 'all ease-in 0.25s',
    animation: animate ? `${grow} 0.5s ` : 'none',
  },
  hoverMagnify: {
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
  themeColorAnimate: {
    fill: filled ? theme.colors.brand[4] : 'rgba(0,0,0,0)',
    transition: 'all ease-in 0.25s',
    animation: animate ? `${grow} 0.5s ` : 'none',
  },
  themeHeart: {
    fill: filled ? theme.colors.brand[4] : 'rgba(0,0,0,0)',
  },
  transparent: {
    fill: 'none',
    '&:hover': {
      fill: theme.colors.brand[4],
    },
  },
}));

export default FilledIcon;
