import React from 'react';
import { Accordion, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';
import { StaticContent } from '@ui/providers';
import { useStaticContent } from '@ui/providers/static-content-provider';

const FAQTechnical = (): React.ReactElement => (
  <FAQAccordion handle="technical">
    {useStaticContent('FAQ.Technical.ChangingCurrency') && (
      <Accordion.Item value="changing-currency">
        <Accordion.Control>Changing Currency</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.ChangingCurrency" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.ChangingEmail') && (
      <Accordion.Item value="changing-email">
        <Accordion.Control>Changing your email address</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.ChangingEmail" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.EmailNotAccepted') && (
      <Accordion.Item value="email-not-accepted">
        <Accordion.Control>Email address is not being accepted</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.EmailNotAccepted" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.NotReceivingEmails') && (
      <Accordion.Item value="not-receiving-emails">
        <Accordion.Control>Not receiving emails</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.NotReceivingEmails" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.OrderConfirmationEmails') && (
      <Accordion.Item value="order-confirmation-emails">
        <Accordion.Control>Order confirmation emails</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.OrderConfirmationEmails" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.LoginProblems') && (
      <Accordion.Item value="login-problems">
        <Accordion.Control>Trouble logging in</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.LoginProblems" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
    {useStaticContent('FAQ.Technical.Unsubscribe') && (
      <Accordion.Item value="unsubscribe">
        <Accordion.Control>Unsubscribe</Accordion.Control>
        <Accordion.Panel>
          <Prose size="small">
            <StaticContent id="FAQ.Technical.Unsubscribe" />
          </Prose>
        </Accordion.Panel>
      </Accordion.Item>
    )}
  </FAQAccordion>
);

export default FAQTechnical;
