import RunMutation from '../../utils/RunMutation.ts';
import { CombinedError } from '@urql/core';
import { CUSTOMER_RESET } from '../../documents/mutations';
import type { CustomerResetMutationVariables } from '../../__generated__/graphql';

export async function CustomerReset({ customerId, input }: CustomerResetMutationVariables) {
  const { data, error } = await RunMutation(CUSTOMER_RESET, {
    customerId: customerId,
    input: input,
  });

  if (error) {
    return { customerResponseData: undefined, error };
  }

  if (!data?.customerReset?.customer) {
    return {
      customerResponseData: undefined,
      error: { message: 'Customer not found' },
    };
  }

  const customerData = data.customerReset.customer;

  const customerResponseData = {
    user: {
      id: customerData.id,
      email: customerData.email,
      displayName: customerData.displayName,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      phone: customerData.phone,
      acceptsMarketing: customerData.acceptsMarketing,
      updatedAt: customerData.updatedAt,
      createdAt: customerData.createdAt,
    },
    userAuth: {
      accessToken: data.customerReset.customerAccessToken.accessToken,
      expiresAt: data.customerReset.customerAccessToken.expiresAt,
    },
  };

  return { customerResponseData, error };
}
