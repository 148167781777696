/**
 * TODO remove all refereceses to tbis component and use the Next.js Image component directly
 */

import React from 'react';
import Image, { ImageLoader } from 'next/image';

type NextImageProps = {
  loader?: ImageLoader | undefined;
  src: string;
  alt: string;
  height?: number | `${number}`;
  width?: number | `${number}`;
  quality?: number | `${number}`;
  fill?: boolean;
  sizes?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  loading?: 'eager' | 'lazy' | undefined;
};

const NextImage = ({
  loader,
  src,
  alt,
  height,
  width,
  quality,
  fill,
  sizes,
  style,
  className,
  onClick,
  loading,
}: NextImageProps): React.ReactElement => (
  <Image
    loader={loader}
    src={src}
    alt={alt}
    height={height}
    width={width}
    quality={quality}
    fill={fill}
    sizes={sizes}
    style={style}
    className={className}
    onClick={onClick}
    priority={loading === 'eager'}
    loading={loading}
  />
);

NextImage.defaultProps = {
  loader: undefined,
  height: undefined,
  width: undefined,
  quality: undefined,
  fill: undefined,
  sizes: undefined,
  style: undefined,
  className: undefined,
  onClick: undefined,
  loading: 'eager',
};

export default NextImage;
