export enum CatalogRoutes {
  'NEW' = '/collections/new/',
  'DRESSES' = '/collections/dresses/',
  'PLAYSUITS' = '/collections/playsuits/',
  'BACK IN STOCK' = '/collections/back-in-stock/',
  'TOPS' = '/collections/tops/',
  'BOTTOMS' = '/collections/bottoms/',
  'SALE' = '/collections/sale/',
  'SWIM' = '/collections/swim/',
  'ACTIVEWEAR' = '/collections/activewear/',
  'ACCESSORIES' = '/collections/accessories/',
  'SELF LOVE' = '/collections/self-love/',
  'SHOES' = '/collections/shoes/',
  'EXCLUSIVE' = '/collections/exclusive/',
}
