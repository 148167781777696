export default function SearchContactIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8168 10.825L11.0835 9.69164C10.9097 9.58153 10.7002 9.5426 10.4984 9.58295C10.2967 9.6233 10.1182 9.73982 10.0002 9.90831C9.60016 10.3916 9.11683 11.1916 7.30016 9.37498C5.4835 7.55831 6.26683 7.05831 6.75016 6.65831C6.91865 6.54024 7.03518 6.36179 7.07553 6.16005C7.11588 5.9583 7.07695 5.74877 6.96683 5.57498L5.8335 3.84164C5.6835 3.62498 5.4835 3.27498 5.01683 3.34164C4.55016 3.40831 3.3335 4.09164 3.3335 5.59164C3.3335 7.09164 4.51683 8.92498 6.1335 10.5416C7.75016 12.1583 9.5835 13.325 11.0668 13.325C12.5502 13.325 13.2668 11.9916 13.3168 11.6583C13.3668 11.325 13.0335 10.975 12.8168 10.825Z"
        stroke="#0D0005"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
