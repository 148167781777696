import React from 'react';
import { defaultState } from './defaultState';
import { Reducer } from './reducer';
import { State, ContextProps } from '../types/context';
import { SSViewedProductActions, UserActions } from '../types/actions';

function instanceOfState(object: any): object is State {
  return 'user' in object && 'wishlist' in object && 'recentlyViewed' in object && 'ssViewedProduct' in object;
}

function migrateState(): State {
  const state = JSON.parse(localStorage.getItem('state') || '');
  if (!instanceOfState(state)) {
    if (!state.user) {
      state.user = { id: '' };
    }

    if (!state.recentlyViewed) {
      state.recentlyViewed = [];
    }

    if (!state.ssViewedProduct) {
      state.ssViewedProduct = {};
    }

    return state;
  }

  return state;
}

const initialState: State =
  typeof window !== 'undefined' && localStorage.getItem('state') !== null ? migrateState() : defaultState;

const AppContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<UserActions | SSViewedProductActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const ContextProvider = ({ children }: ContextProps): React.ReactElement => {
  // @ts-ignore
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  React.useEffect(() => {
    localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  const values = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export { AppContext, ContextProvider };
