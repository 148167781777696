import { MantineTheme } from '@mantine/core';

const carousel = {
  styles: (theme: MantineTheme) => ({
    // root: {
    //   '&:hover': {
    //     [`& .${getStylesRef('controls')}`]: {
    //       opacity: 1,
    //     },
    //   },
    // },
    controls: {
      // ref: getStylesRef('controls'),
      height: '100%',
      padding: 0,
      top: 0,
      // transition: 'opacity 150ms ease',
      // opacity: 0,
    },
    // viewport: {
    //   overflow: 'visible',
    // },
    control: {
      minWidth: '1.5rem',
      maxWidth: '1.5rem',
      height: '100%',
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      opacity: 1,
      transition: 'none',
      '&[data-inactive]': {
        opacity: 0,
        cursor: 'default',
      },
    },
    // indicators: {
    //   paddingTop: '2rem',
    //   position: 'initial',
    // },
    indicator: {
      width: '12px',
      height: '4px',
      transition: 'width 250ms ease',
      backgroundColor: theme.colors.brand[5],
      '&[data-active]': {
        width: '40px',
      },
    },
  }),
};

export default carousel;
