export const GiftCardTemplates = [
  // {
  //   label: "Every Gal's Dream Gift",
  //   code: 'HM-Gift-Card-Dream',
  //   color: '#fedce8',
  //   image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream.png',
  //   preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream-trimmed.webp',
  //   icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Dream-icon.png',
  // },
  // {
  //   label: 'The Gift of Hot New Fits',
  //   code: 'HM-Gift-Card-Hot',
  //   color: '#ff88d5',
  //   image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot.png',
  //   preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot-trimmed.webp',
  //   icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Hot-icon.png',
  // },
  // {
  //   label: "Babe You're Going to Slay",
  //   code: 'HM-Gift-Card-Slay',
  //   color: '#fe97ca',
  //   image_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay.png',
  //   preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay-trimmed.webp',
  //   icon_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Slay-icon.png',
  // },
  {
    label: 'A Gift as Fabulous as You',
    code: 'HM-Gift-Card-Fabulous',
    color: '#f5dadf',
    image_url: '',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Banner-Fabulous.jpg?v=1733453622',
    icon_url: '',
  },
  {
    label: 'Your Holiday fits wrapped with Love',
    code: 'HM-Gift-Card-Holiday-Fits',
    color: '#f6dbe0',
    image_url: '',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Banner-Holiday-Fits.jpg?v=1733453622',
    icon_url: '',
  },
  {
    label: 'The Perfect Holiday Treat',
    code: 'HM-Gift-Card-Holiday-Treat',
    color: '#f05098',
    image_url: '',
    preview_url: 'https://assets.hellomolly.com/banner/giftcards/HM-Gift-Card-Banner-Holiday-Treat.jpg?v=1733453622',
    icon_url: '',
  },
];

export const GiftCardDenominations = [
  { variantId: 'gid://shopify/ProductVariant/40146812534870', value: '25' },
  { variantId: 'gid://shopify/ProductVariant/40146812469334', value: '50' },
  { variantId: 'gid://shopify/ProductVariant/40146812403798', value: '100' },
  { variantId: 'gid://shopify/ProductVariant/40146812371030', value: '150' },
  { variantId: 'gid://shopify/ProductVariant/40146812338262', value: '200' },
  { variantId: 'gid://shopify/ProductVariant/40146812272726', value: '250' },
  { variantId: 'gid://shopify/ProductVariant/40146812239958', value: '300' },
  { variantId: 'gid://shopify/ProductVariant/40146812174422', value: '350' },
  { variantId: 'gid://shopify/ProductVariant/40146812141654', value: '400' },
  { variantId: 'gid://shopify/ProductVariant/40146812076118', value: '450' },
  { variantId: 'gid://shopify/ProductVariant/40146812043350', value: '500' },
];
