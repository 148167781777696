import { StaticContentType } from '@ui/providers';
import { innerScriptFunc } from './forter';
import { emarsysInnerScriptFunc, emarsysInnerScarabScriptFunc } from './emarsys';
import {
  CategoryFilterMapping,
  CollectionFilterMapping,
  DesktopMenuLinkTree,
  MegaMenuDropdownLabels,
  MegaMenuHeaderDropdownHeadingLabels,
  MegaMenuHeadingLabels,
  MobileMenuLinkTree,
  MegaMenuRoutes,
  ParentCollectionAssociations,
} from './menuMappings';
import { FilterImageCarouselMappings } from './filterImageCarouselMappings';
import { GiftCardDenominations, GiftCardTemplates } from './giftcards';
import { clothingSizes, sizeConversions, shoeSizes } from './sizeGuideMappings';
import { FaqMenuMappings } from './faqMenuMappings';
import { SearchReturnsIcon, SearchDeliveryIcon, SearchContactIcon } from '@ui/components/core';
import { regionData, auBody, usBody, nzBody, caBody, gbBody, apacBody, otherBody } from './shippingData.tsx';
import { ContactFAQLinks, ContactVia } from './ContactPageMappings.type';

export const usContent: StaticContentType = {
  'HTML.lang': 'en-US',
  'Tag.TikTokTrack': `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
      var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
      ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


        ttq.load('CQ3NFD3C77UAOAV5CPV0');
        ttq.page();
      }(window, document, 'ttq');
`,
  'Tag.YotpoPixel': 'https://d18eg7dreypte5.cloudfront.net/browse-abandonment/v2/generic.js',
  'Tag.YotpoDataLayer': `window.wtba = window.wtba || []`,
  'Tag.ElevarConfig': `const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/f22b9eaaeb17824eee1d11189ea640a5f8cf24a1/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }`,
  'Tag.ElevarDataLayer': `if (typeof window !== "undefined") {
            function setCookie(name, value, days) {
              const date = new Date();
              date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
              const expires = "expires=" + date.toUTCString();
              document.cookie = name + "=" + value + ";" + expires + ";path=/";
            }
            function getCookie(name) {
              const nameEQ = name + "=";
              const ca = document.cookie.split(';');
              for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
              }
              return null;
            }
            window.ElevarUserIdFn = () => {
              let userId = getCookie('user_id');
              if (!userId) {
                userId = crypto.randomUUID();
                setCookie('user_id', userId, 365);
              }
              return userId;
            };
          }`,
  'Tag.ElevarGTMSetup': `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NJWP92Q7');`,
  'Tag.emarsysInnerScarabScriptFunc': emarsysInnerScarabScriptFunc,
  'Tag.Emarsys.WebExtend': emarsysInnerScriptFunc,
  'Tag.GTM.Linker.Domain': 'https://checkout.hellomolly.com/',
  'Forter.Script': innerScriptFunc,
  'Tag.GTM.ID': 'GTM-NJWP92Q7',
  'Seo.Home.canonical': 'https://www.hellomolly.com/',
  'Seo.Home.url': 'https://www.hellomolly.com/',
  'Banner.FreeShipping': '<b>FREE SHIPPING</b> ON ORDERS OVER $100',
  'Terms.Website': 'https://www.hellomolly.com/',
  'Shipping.FreeShipping': 'Free shipping on US orders over <b>$100</b>',
  'Shipping.ShippingStatus':
    '<p>We try our best to send out your packages on time every day! That means all orders received before <strong>2pm (PT/California time) on a business day</strong> will be shipped out from our Los Angeles warehouse that same day! Otherwise, your order will be shipped the next business day. <strong>Please note that sometimes during high-volume or sale periods, there may be some delay in having your order shipped out.</strong></p><br/><p><strong>INTERNATIONAL CUSTOMERS PLEASE NOTE:</strong> If you select the Standard shipping option, there could be delays up to 3-4 business days from the time of dispatch (when you receive your tracking email) to when the courier collects the parcel. Please consider this when placing your order as transit times are estimated based on the pick up date. Express packages are picked up every day.</p>',
  'Shipping.DeliveryTime':
    '<p>Once a package has left our warehouse we have very little control over it. Please note that delivery times listed above are only estimations. <strong>Hello Molly is not responsible for any delays caused by the carrier, especially during high-volume periods.</strong> Hello Molly can never guarantee a delivery date.</p><br/><p>If you are not home to accept your package, the carrier will assess whether it is safe to leave. If not, a calling card will be left in your letter box detailing the steps for arranging re-delivery or picking up your delivery at the local post office.</p><br/><p>If you have not received your package within 7 business days (USA or Australia) or 30 business days (all other countries) of placing the order, please contact us <a href="/pages/contact-us">HERE</a> and our team will investigate.</p>',
  'Shipping.TaxesAndDuties':
    '<p><strong>Please note taxes and duties may be charged for international orders.</strong></p><br/><p>We aim to display all applicable customs and import duties and fees or notices of possible fees at checkout. If you have any questions, please contact us <a href="/pages/contact-us" target="_blank">HERE</a>.</p>',
  'Shipping.Regions': regionData,
  'Shipping.auBody': auBody,
  'Shipping.usBody': usBody,
  'Shipping.nzBody': nzBody,
  'Shipping.caBody': caBody,
  'Shipping.gbBody': gbBody,
  'Shipping.apacBody': apacBody,
  'Shipping.otherBody': otherBody,
  'Product.FreeShipping': '<b>FREE</b> shipping on US orders over $100',
  'Product.DeliveryAndReturns':
    '<p>Hello Molly offers FREE standard shipping USA-wide on all orders over $100. Expect delivery within 6 business days. We also offer express Next Day and 2-Day services within the USA. International customers, please check our <a href="/pages/shipping"><strong>Shipping Page</strong></a> for more details. We accept returns for store credit within 30 days of order delivery for full priced items only.</p>',
  'Home.HeroBannerUrl': '/collections/holiday-workshop',
  'Home.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Festive-Event-Homepages-Desktop.jpg?v=1733699047',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Home.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Festive-Event-Homepages-Mobile_Main.jpg?v=1733699047',
    alt: 'Hello Molly Hero Banner',
    width: 1440,
    height: 1433,
  },
  // 'Home.HeroBannerVideoDesktop': {
  //   src: 'https://cdn.shopify.com/videos/c/o/v/2ca836af823a49bbb92cdeac5110e7d3.mp4',
  //   poster: {
  //     src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_3x4_3ee58c31-6a89-4496-a46d-066260d02c24.jpg?v=1727662479',
  //     alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
  //     width: 611,
  //     height: 814,
  //   },
  // },
  // 'Home.HeroBannerVideoMobile': {
  //   src: 'https://cdn.shopify.com/videos/c/o/v/f87bb70380ca4cc0bc68e4cdc3b23d6f.mp4',
  //   poster: {
  //     src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/THUMBNAIL_1x1_af81d690-35ca-4c14-b150-1355d9a07159.png?v=1727662480',
  //     alt: 'Video panning from a road to a billboard, showing HELLOMOLLY with two models on a green and pink background. The model wearing a black mini dress blows a bubble and the model wearing a pink maxi dress takes the crown off her head and throws it to the left. A CGI version of the bubble glows and captures the crown as it floats close to the camera.',
  //     width: 811,
  //     height: 811,
  //   },
  // },
  'Home.FeaturedCategories': [
    {
      src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/1-US-Shop-New.jpg?v=1733699739',
      alt: 'Shop New Feature Card',
      label: 'Shop New',
      href: '/collections/new',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/2-US-Shop-Maxi-Dresses.jpg?v=1733699739',
      alt: 'Party Dresses Feature Card',
      label: 'Shop Maxi Dresses',
      href: '/collections/maxi-dress',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/3-US-Shop-Accessories.jpg?v=1733699739',
      alt: 'Shop Accessories Feature Card',
      label: 'Shop Accessories',
      href: '/collections/accessories',
      height: 1227,
      width: 820,
    },
    {
      src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/4-US-Shop-Printed.jpg?v=1733699739',
      alt: 'SHOP PRINTED DRESSES Feature Card',
      label: 'Shop Printed Dresses',
      href: '/collections/printed-dresses',
      height: 1227,
      width: 820,
    },
  ],
  'Home.MiddleBannerDesktop': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Desktop-Festive-Banner-01.jpg?v=1733179137',
    alt: 'vote to win a hello molly wardrobe',
    height: 847,
    width: 2883,
  },
  'Home.MiddleBannerMobile': {
    href: '/pages/competitions/',
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile-Festive-Mobile.jpg?v=1733179137',
    alt: 'vote to win a hello molly wardrobe',
    height: 1175,
    width: 1437,
  },
  'Home.HeroVideoDesktop': '',
  'Home.HeroVideoMobile': '',
  'Menu.DesktopMenuLinkTree': DesktopMenuLinkTree,
  'Menu.MegaMenuHeadingLabels': MegaMenuHeadingLabels,
  'Menu.MegaMenuDropdownLabels': MegaMenuDropdownLabels,
  'Menu.MegaMenuHeaderDropdownHeadingLabels': MegaMenuHeaderDropdownHeadingLabels,
  'Menu.MobileMenuLinkTree': MobileMenuLinkTree,
  'Menu.CategoryFilterMapping': CategoryFilterMapping,
  'Menu.MegaMenuRoutes': MegaMenuRoutes,
  'Campaign.EOFY.HeroBannerUrl': '/collections/sale',
  'Campaign.EOFYWeek1.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-EOFY-Sale-Homepages.jpg?v=1718323377',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek1.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Mobile-AU-EOFY-Sale-Homepages.jpg?v=1718323389',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'Campaign.EOFYWeek2.HeroBannerImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Phase-2-Sale-Homepages.jpg?v=1718866458',
    alt: 'Hello Molly Hero Banner',
    width: 2880,
    height: 1432,
  },
  'Campaign.EOFYWeek2.HeroBannerImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Headless-AU-Mobile-Phase-2-Sale-Homepages.jpg?v=1718866457',
    alt: 'Hello Molly Hero Banner',
    width: 1280,
    height: 1413,
  },
  'GiftCard.Templates': GiftCardTemplates,
  'GiftCard.Denominations': GiftCardDenominations,
  'WearNowPayLater.Channels': ['afterpay', 'klarna'],
  'WearNowPayLater.Requirements': [
    'To be 18+ years old',
    'A valid US credit or debit card',
    'To live in the United States',
  ],
  'WearNowPayLater.ExampleImageDesktop': {
    src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/Desktop-US_1.jpg?v=1729821537',
    alt: '',
    width: 1856,
    height: 1293,
  },
  'WearNowPayLater.ExampleImageMobile': {
    src: 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/Mobile-US_1.jpg?v=1729821537',
    alt: '',
    width: 1129,
    height: 1181,
  },
  'Collection.FilterImageCarouselMappings': FilterImageCarouselMappings,
  'Collection.BestSellerId': 269132824662,
  'Collection.CollectionFilterMapping': CollectionFilterMapping,
  'Collection.ParentCollectionAssociations': ParentCollectionAssociations,
  'Yotpo.SmsBumpAccount': 'us',
  'Yotpo.SmsBumpListId': 3342639,
  'Search.TrendingLinks': [
    {
      title: 'Maxi Dress',
      href: '/search/products/maxi%20dress',
    },
    {
      title: 'Formal Dress',
      href: '/search/products/formal%20dress',
    },
    {
      title: 'Weddings',
      href: '/search/products/bridesmaid%20&%20wedding%20guest',
    },
    {
      title: 'Long Sleeve Dress',
      href: '/search/products/long%20sleeve%20dress',
    },
    {
      title: 'Black Dress',
      href: '/search/products/black%20dress',
    },
    {
      title: 'Pink Dress',
      href: '/search/products/pink%20dress',
    },
    {
      title: 'Midi Dress',
      href: '/search/products/midi%20dress',
    },
    {
      title: 'Swim',
      href: '/search/products/swim',
    },
  ],
  'Search.HelpLinks': [
    {
      title: 'Returns',
      href: '/pages/returns',
      icon: SearchReturnsIcon,
    },
    {
      title: 'Shipping',
      href: '/pages/shipping',
      icon: SearchDeliveryIcon,
    },
    {
      title: 'Contact Us',
      href: '/pages/contact-us',
      icon: SearchContactIcon,
    },
  ],
  'Cart.PaymentMethods': ['paypal', 'visa', 'mastercard', 'afterpay', 'apple-pay', 'amex'],
  'Returns.Preamble':
    '<p>We hope you love your order, but if you are not completely satisfied with your purchase <b>we accept returns for store credit only.</b></p>',
  'Returns.ReturnRequirements':
    '<ul><li>Merchandise must be returned within <strong>30 days</strong> of the order delivery date.</li><li>Merchandise must be unworn, unwashed, unstained and unperfumed.</li><li>Merchandise must have all tags, including any Hello Molly hangtags, attached.</li><li>Any products marked &apos;<strong>FINAL SALE</strong>&apos; cannot be returned.</li><li>All footwear returns must include the original shoebox in its original condition, without postal labels.</li><li>Handbags sold with a dust bag or other packaging must be returned with the original dust bag and packaging.</li><li>Due to hygiene concerns the following cannot be returned: <strong>intimates, stockings, hair accessories, hats, earrings, personal massagers, cosmetic/beauty products including self-tanners, Undercover Style Helpers.</strong></li><li>Swimwear can be returned as long as all hygiene stickers are intact.</li></ul><br/><p>Please note if you paid for shipping at the time of your original order, you will not be credited the cost of shipping when we receive your return. If your return does not adhere to the requirements it may be sent back at additional cost to the customer.</p>',
  'Returns.ProcessingReturns':
    '<p>We aim to process returns within 7 business days of receiving them in our warehouse, but high volume periods, especially around the holidays, could cause delays. We apologise for any inconvenience. If you&apos;re concerned that your return has been delivered but not processed in a timely fashion, please contact us <a href="/pages/contact-us" target="_blank">HERE</a></p>',
  'Returns.StoreCredit':
    '<ul><li>Store credit can only be used on the Hello Molly website.</li><li>Please treat store credit like cash.</li><li>Any purchase amounts that exceed the value of the store credit will require an additional method of payment for the remaining balance due.</li><li>Store credit is valid for 12 months after the date of issue.</li><li>Your use of store credit constitutes your agreement to our Terms & Conditions.</li><li>You can view your store credit balance by logging into your Hello Molly account <a href="/account">HERE</a>.</li></ul>',
  'Returns.FaultyItems':
    '<p>The Hello Molly team thoroughly inspects goods before they are shipped out. We stand behind the quality of our products, but if you come across a manufacturing fault, please contact us <a href="/pages/contact-us" target="_blank">HERE</a> as soon as possible. Refunds cannot be guaranteed without prior photographic evidence of the fault.</p>',
  'SizeGuide.SizeGuideMessage':
    '<p>Our models typically wear US Size 2-4 or Australian Size 6-8, X-Small, Small, or One-Size garments.</p><br/><p>On each product page, we provide the model&apos;s measurements and what size she is wearing to help guide you.</p><br/><p>You can refer to the charts when selecting sizes but please note that sizes cannot be guaranteed and might vary slightly between brands. If you need any advice about sizing, please feel free to drop us a line at: <a href="mailto:info@hellomolly.com">info@hellomolly.com</a></p><br/><p>The measurements in the description of individual products are taken only across the front side of the garment, not around the circumference (e.g. chest and waist).</p>',
  'SizeGuide.ClothingSizes': clothingSizes,
  'SizeGuide.SizeConversions': sizeConversions,
  'SizeGuide.ShoeSizes': shoeSizes,
  'FAQ.MenuMappings': FaqMenuMappings,
  'FAQ.PopularQuestions.Collaboration':
    'If you have a wicked social media presence, Instagram page or are just interested in collaborating with the Hello Molly brand we&apos;d LOVE to hear from you! Please contact our Publicity Coordinator at <a href="mailto:pr@hellomolly.com">pr@hellomolly.com</a> for all PR, Marketing and collaboration enquiries.',
  'FAQ.PopularQuestions.PhysicalStore':
    'Hello Molly is exclusively online! This means we can serve customers all across the world but unfortunately we don&apos;t have a physical location where you can come and try on garments before buying. Our office and warehouses are closed to the public.',
  'FAQ.PopularQuestions.StoreCredit':
    'Store credit is issued to customer accounts. If your Hello Molly account has credit on it, make sure you are logged in when you check out. On the checkout page you will have the option to apply your credit to the order! Please ensure you do not use an express checkout method (such as Shop Pay or Afterpay) however as it will bypass the store credit option. If you make a return and don&apos;t have an account with us, we will send you an account activation email to the email address associated with your original order. Also please note, accounts are not transferable between our Australian and US sites.',
  'FAQ.PopularQuestions.ShippingSchedule':
    'We aim to ship all orders received before 2pm Pacific Time on a business day that same day. Otherwise they&apos;re sent out the next business day. We have three domestic USA delivery options including next business day and 2 business day! Orders shipped with the standard delivery option typically take up to 6 business days.<br/>Please refer to our <a href="/pages/shipping">Shipping Page</a> for full details and transit times to other destinations.<br/>Please note that these shipping times may not be accurate as a result of courier delays out of our control. All shipping times are estimates only.',
  'FAQ.PopularQuestions.MissingConfirmationEmail':
    'We&apos;re sorry to hear that. It&apos;s possible the email has gone to your junk folder or maybe we have an incorrect email address for you. If this happens, please contact us straight away so we can verify whether the order has been placed and resend your confirmation. You can reach us <a href="/pages/contact-us">HERE</a>',
  'FAQ.PopularQuestions.BusinessHours':
    'We&apos;re in the office from 9.30am-5pm pacific time Monday-Friday (excluding public holidays). You can reach us on +1 (818) 900-7796.',
  'FAQ.PopularQuestions.StoreLocation':
    'Head office is in sunny Sydney, Australia! We also have a warehouse in Los Angeles, USA.',
  'FAQ.PopularQuestions.ReturnAddress':
    '<p>Please send your returns to:</p><br/><p>Hello Molly<br>8738 E 9th Street<br>Rancho Cucamonga, CA 91730<br>United States</p><br/><p>Refer to our <a href="/pages/returns" class="faq-a">Returns Page</a> for full details and information on international returns.</p>',
  'FAQ.Delivery.USADelivery':
    '<p>All orders received before 2pm Pacific Time on a business day are shipped out that same day. Otherwise they&apos;re sent out the next business day. We have three domestic USA delivery options including next business day and 2 business day! Orders shipped with the standard delivery option typically take up to 6 business days. Please note that these shipping times may not be accurate as a result of courier delays out of our control. All shipping times are estimates only.</p><br/><p>Please refer to our <a href="/pages/shipping">Shipping Page</a> for full details.</p>',
  'FAQ.Delivery.Customs':
    '<p>Please note additional taxes and duties may be charged for international orders. All applicable customs and import duties, fees, taxes, and any other charges are the responsibility of the customer, even if you refuse the shipment upon delivery. If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care <a href="/pages/contact-us" target="_blank">HERE</a> so that we can help you receive your order as quickly as possible.</p>',
  'FAQ.Delivery.USADomesticOrders':
    '<p>Orders shipped from Hello Molly within the USA by default are sent with authority to leave.</p><br/><p>Packages shipped with the standard delivery option will be left in a safe spot in your mailbox or on your property. If no safe spot is available, your order will be taken back to the post office and redelivery or pick-up can be arranged.</p><br/><p>Express packages are shipped with UPS. A signature may be required upon delivery. Please note that UPS does not deliver to PO Boxes.</p><br/><p>Hello Molly is not responsible for any loss of orders or delivery errors made by a courier in accordance with our <a href="/terms-and-conditions">Terms &amp; Conditions</a>.</p>',
  'FAQ.Delivery.InternationalDelivery':
    '<p>We ship internationally using our partner Global-E. Global-E is a trusted global eCommerce vendor whose solution allows Hello Molly to provide the best possible user experience to our international customers.</p><br/><p>Standard parcels can be delayed up to 4 business days from time of dispatch (when you receive your tracking email) to when Global-E collects the parcel.</p><br/><p>Please refer to our <a href="/pages/shipping">Shipping Page</a> for full details.</p>',
  'FAQ.Delivery.MissingOrder':
    '<p>If your expected delivery date has passed and you still haven&apos;t received it, please check your tracking. If the parcel is not moving or tracking hasn&apos;t updated within the estimated delivery time frame, please contact the courier company (details will be available in your tracking email). If they&apos;re unhelpful, contact us <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> and we&apos;ll start investigating straight away!</p>',
  'FAQ.Delivery.UPSDeliveryUSA':
    '<p>In the US, we offer 1 business day and 2 business day delivery options via UPS. We aim to ship all orders received before 2pm Pacific time on a business day the same day. Please note this excludes weekends.</p><br/><p>For example, if we receive your order on Thursday after 2pm and you have selected next business day delivery. Your order will be shipped on Friday and delivered Monday.</p><br/><p>Please note UPS does not deliver to PO Boxes.</p><br/><p>For more information please see our <a href="/pages/shipping">Shipping Policy</a></p>',
  'FAQ.Delivery.Tracking':
    '<p>Once your order is dispatched from our warehouse, you&apos;ll receive a tracking email from the courier. Please follow their instructions to track your parcel. If you haven&apos;t received a tracking email, please check your junk mailbox before contacting us.</p>',
  'FAQ.OrderIssues.GiftVouchersCancel':
    '<p>If you wish to cancel a gift voucher, please contact us <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> as soon as possible. As long as the voucher hasn&apos;t been used, we can cancel it for you.</p>',
  'FAQ.OrderIssues.OrderCancel':
    '<p>Packing and shipping orders as soon as possible is our goal. Contact us <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> to enquire about cancelling your order. We will do all we can to cancel your order, but despite our best efforts, we can not guarantee that your order will be cancelled.</p>',
  'FAQ.OrderIssues.OrderChange':
    '<p>We aim to pack and ship out your order as quickly as possible. If you need to make a change to your order, contact us <a href="https://www.hellomolly.com/pages/contact-us">HERE</a>.</p><br/><p>We&apos;ll try our best to accommodate the change but unfortunately we cannot guarantee that every change will be possible.</p>',
  'FAQ.OrderIssues.Customs':
    '<p>If you&apos;re experiencing issues with Customs in your country, please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us" class="faq-a">HERE</a> so that we can help you receive your order as quickly as possible.</p><br/><p>Please note taxes and duties may be charged for international duties. All applicable customs and import duties and fees, taxes and any other charges are the responsibility of the customer, even if you refuse the shipment upon delivery.</p><br/><p>Customs authorities require by law that we state the value of your order directly on the package. Customs agents have the right to release or deny release of your package and, in rare cases, they may also delay delivery.</p>',
  'FAQ.OrderIssues.IncorrectItem':
    '<p>Unfortunately, our warehouse may occasionally send out an incorrect item. In order to resolve this quickly, kindly reach out to our customer service <a href="https://www.hellomolly.com/pages/contact-us">HERE</a>. To expedite the process, you can include your order number and a photo of the item you received, as well as the tag/barcode of the item.</p>',
  'FAQ.OrderIssues.MissingItem':
    '<p>If an item is missing from your order, please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> with your order number and the name of the missing item. We will resolve this issue as quickly as possible.</p>',
  'FAQ.OrderIssues.OrderVerification':
    '<p>Hello Molly takes every measure against credit card fraud. If we suspect or our fraud system flags that your order may be fraudulent, your order will be automatically cancelled. You will receive a notice of the cancellation with the option to contact us to validate the order and have future transactions approved without hassle!</p><p>We understand this can be frustrating, but it is a rare occurrence and important for the protection of our customers and brand. Thank you for understanding.</p>',
  'FAQ.OrderIssues.OutOfStock':
    '<p>Hello Molly strives to provide high-quality products and service to its customers at an affordable price, but from time to time during the order processing stage, we may discover a manufacturing fault or inventory error with some products during dispatch.</p><p>If this happens and we are unable to fulfill the order, we reserve the right to cancel the product and issue a full refund of the product price to the customer&apos;s original payment method. The customer will be notified via email as soon as possible.</p>',
  'FAQ.OrderIssues.FaultyItem':
    '<p>If you believe your item is faulty, please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> straight away with your order number, the item name, and a photo of the fault. We will sort this out as quickly as possible!</p><p>Please note that loose or excess threads and irregular prints are not considered faults as these are often natural results of the manufacturing process. Please read the description of each item carefully before purchasing.</p>',
  'FAQ.OrderIssues.PriceDifferenceRefund':
    '<p>If you see that we&apos;ve reduced the price of an item you recently ordered, unfortunately, we cannot refund the difference. Our prices change according to stock and demand from customers as well as occasional promotions. We apologize for any inconvenience.</p>',
  'FAQ.OrderIssues.ScamAndFraud':
    '<p>Unfortunately, there are a number of scam/"copycat" websites illegally using our images and pretending to sell our clothes. Please be wary of where you buy from. These sites often use our photographs and copy our names/descriptions. They are scams and if you do opt to shop with them you are likely to receive a low-quality copy of our clothing, an item that is different from what you ordered, or nothing at all. While we actively work to have such websites removed, it can be a slow process.</p><p>If you are unsure if a website is affiliated with Hello Molly, please contact our Customer Care team via <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> for advice.</p>',
  'FAQ.PaymentsAndPromos.PaymentsAndPromos':
    '<p>If your card is authorised, payment will be taken straight away and you&apos;ll receive an email confirmation for your order. If your card is not authorised, your order will not go through. You may need to contact your card issuer.</p><p>Some payment methods occasionally have additional validations and are not authorised straight away. This is most common with Paypal or Klarna. In these cases, while you would have received an order confirmation, your order will not be shipped until the payment is fully authorised. If you have concerns about the payment for your order please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us">HERE</a> straight away so we can check for you.</p>',
  'FAQ.PaymentsAndPromos.UsStateSalesTax':
    'Your order may be subject to State Sales Tax. This is calculated at checkout and is based on your delivery address. Some states also apply sales tax to shipping so you may notice the total tax amount adjusts depending on which shipping method you select.',
  'FAQ.PaymentsAndPromos.Ordering':
    '<p>Once your shopping bag is full of all the items you want to purchase, you can check out by accessing your bag in the top-right corner of the screen. You will also be given the option to &apos;View Bag&apos; in a pop-up window after you have successfully added an item to your cart.</p><p>After you click &apos;Submit Order&apos; during the checkout process and your payment is successfully received, you will get a confirmation email from Hello Molly. If you checked out with your account you can also check the status of your order by logging into your account dashboard.</p>',
  'FAQ.PaymentsAndPromos.OrderPayment':
    '<p>We accept various payment methods including Visa, MasterCard, American Express, PayPal, AfterPay and Hello Molly store credit. We take your payment security very seriously so be assured that your details are safe with us.</p><p>We do not accept payment via cheque or Cash On Delivery.</p><p>We take fraud very seriously, so all credit and debit card holders are subject to validation and authorisation by both us and your card issuer.</p><p>If you&apos;re paying with a valid credit card and experience an error, please double-check that you have entered the credit card details correctly.</p><p>If you have entered the correct details and still see the error, you may wish to consider checking out with an alternative payment method or contacting your card issuer.</p>',
  'FAQ.PaymentsAndPromos.InstallmentPayments':
    '<p>We offer various payment options that allow you to pay in interest-free installments including AfterPay, Klarna and Shop Pay. If you select one of those options, you are subject to the terms and conditions of the respective payment method. In most cases, you will be charged the first instalment at the time of purchase and the remainder will be debited overtime per your agreed schedule with the payment processor.</p><p>Payment is arranged between you and the third-party payment processor. They will provide you with the full payment schedule as you complete the purchase (late fees apply if you fail to make a payment).</p>',
  'FAQ.PaymentsAndPromos.StoreCreditPayments':
    '<p>Store credit is issued to customer accounts. To apply your store credit, make sure you are signed in to your Hello Molly account before you checkout.</p><p>Store credit will not appear if you are using an express checkout service such as Shop Pay. You must proceed through our standard checkout.</p><p>If you forget to apply your store credit to your order, please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us">HERE</a>.</p><p>Accounts are non-transferable between our Australian, New Zealand and US sites. If you have credit in Australian Dollars that you wish to use on the US site (or vice versa), please contact our Customer Care team <a href="https://www.hellomolly.com/pages/contact-us">HERE</a>.</p>',
  'FAQ.PaymentsAndPromos.PayPalPayments':
    '<p>Payments and e-cheques made through PayPal may be subject to delays caused by the processing times between your bank or financial institution and PayPal.</p><br/><p>In accordance with the recommendations issued by PayPal, we don&apos;t ship out orders until we have received confirmation from PayPal that the payment has been cleared.</p><br/><p>Orders where payments are delayed or not cleared (e-cheques) will be placed on hold until payment has been received by Hello Molly.</p>',
  'FAQ.PaymentsAndPromos.PromoCodes':
    '<p>Occasionally we may do promotions which require you to put in the code at the shopping cart. Please note that most promotions come with exclusions which may vary but most typically promo codes cannot be applied to sale items, beauty & wellness, self love, belts, blankets, stockings, jewellery, headwear, homewares, and gift card categories. You need to enter the code exactly as it was given to you, without any spaces. If it still doesn&apos;t work, it&apos;s possible that the code is expired. If you continue to have trouble, please reach out via our <a href="/pages/contact-us">Contact Form</a>.</p>',
  'FAQ.PaymentsAndPromos.PromoCodeNewCustomers':
    '<p>Sign up to our email newsletter for a one-time use discount code for new customers. If you have any trouble receiving your code, please contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.ReturnsAndRefunds.Exchanges':
    '<p>Hello Molly does not offer holds or "automatic" exchanges upon return so you will receive store credit when your return has been processed which you can use to re-order the correct size/colour you needed or another item from our amazing range if you see something else you like.</p>',
  'FAQ.ReturnsAndRefunds.Returns':
    '<p>To make a return, please follow the instructions on our <a href="/pages/returns/" target="_blank">Returns page</a></p>',
  'FAQ.ReturnsAndRefunds.Refunds':
    '<p>At this stage refunds are only offered in the case of faulty items. Change of mind returns are issued store credit. If you feel your order qualifies for a refund, please email us at <a href="mailto:returns@hellomolly.com" class="faq-a">returns@hellomolly.com</a></p><br/><p>Refunds back to credit/debit cards can take 5-7 business days to process and appear in your account again depending on your financial institution. If you still haven&apos;t received your refund after 7 business days, please first contact your financial institution and request an up to date status on any pending refunds on your account. If there are no pending refunds for your account, please email our Customer Care team to chase up the refund at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a></p><br/><p>Please note that foreign transaction fees and currency conversion fees incurred during the initial order payment transaction are charged by the respective credit card company or bank institutions and not Hello Molly hence we cannot refund such fees. You will need to get in contact with your credit card company or bank if you are enquiring about the refund of such fees.</p><br/><p>Check out our full Return Policy <a href="/pages/returns" class="faq-a">here</a>.</p>',
  'FAQ.ReturnsAndRefunds.StoreCredit':
    '<p>Store credit is issued to accounts. If your Hello Molly account has credit on it, make sure you are logged in when you check out. Under &apos;Payment&apos; on the checkout page, you will have the option to apply your credit to the order!</p><br/><p>If you make a return and don&apos;t have an account with us, we will send an account activation email to the email address associated with your original order. Also please note, accounts are not transferable between our Australian and US sites. If you have credit in Australian Dollars that you wish to use on the US site (or vice versa), please contact our Customer Care team at <a href="mailto:info@hellomolly.com" class="faq-a">info@hellomolly.com</a></p>',
  'FAQ.ReturnsAndRefunds.Turnaround':
    '<p>Our Returns Department tries to process all returns in a timely manner, but please allow up to 7 business days for your return to be processed once delivered to our office, particularly during busy periods such as the holidays.</p><p>Note that the turnaround time for returns may fluctuate from time to time depending on the volume of returns.</p>',
  'FAQ.Technical.ChangingEmail':
    '<p>The email address provided when placing your order is the unique identifier for each individual order, so we are unable to change the email address for orders once they are submitted.</p><p>We suggest that you create an account with your preferred email address and place your orders through that account to keep track of all your new and past Hello Molly orders.</p><p>If you put down an incorrect email address you can still enquire about your order status by contacting our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p>',
  'FAQ.Technical.EmailNotAccepted':
    '<p>Our ordering system is able to accept all email addresses, but in the event that you have tried to order using your email address and our checkout or registration system isn&apos;t accepting it, please email our Customer Care team for assistance at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>We suggest trying an alternate email address if the first email address doesn&apos;t work or email our Customer Care Team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.</p><p>Please do not deliberately misspell your email address (e.g. leaving out the com) to force the system to accept it as this would lead to you not receiving any emails relevant to your order.</p>',
  'FAQ.Technical.NotReceivingEmails':
    '<p>If you are not receiving emails from Hello Molly, please check your spam. If you continue to have trouble, please reach out so we can help troubleshoot the issue. You can reach our Customer Care team <a href="/pages/contact-us" target="_blank">HERE</a></p>',
  'FAQ.Technical.OrderConfirmationEmails':
    '<p>Order confirmation emails are sent to the email address that the customer provides at checkout.</p><p>If you haven&apos;t received your order confirmation email within an hour of placing your order, please check your SPAM/junk mailbox. If it isn&apos;t there, contact our Customer Care team at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> before placing a new order.</p><p>Unfortunately, we cannot redirect order confirmation emails to different addresses once the order has been placed.</p>',
  'FAQ.Technical.LoginProblems':
    '<p>If you&apos;re experiencing issues logging in to your account, the first step is to make sure you are trying to sign in via the correct website. Accounts are not transferable between our AU, NZ, UK and US sites so if you created an account on one, it will not work on the other. You will have to create a new account.</p><p>If you are still having trouble, please message us <a href="/pages/contact-us" target="_blank">HERE</a></p>',
  'FAQ.Technical.Unsubscribe':
    '<p>If you wish to unsubscribe from our newsletter, click the &apos;Unsubscribe&apos; link at the bottom of every email or log into your Hello Molly account and adjust your subscription preferences there.</p>',
  'Contact.FAQLinks': ContactFAQLinks,
  'Contact.ContactVia': ContactVia,
};
