import React from 'react';

const ReturnIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7752 7.66797H33.2196L38.3307 15.3346V38.3346H7.66406L7.66406 15.3346L12.7752 7.66797Z"
      stroke="#0D0005"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23 7.66797V15.3346" stroke="#0D0005" />
    <path d="M7.66406 15.3359H38.3307" stroke="#0D0005" />
    <path
      d="M25.5938 33.4363L21.0255 28.8675L25.5938 24.293"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.026 28.8633L29.7256 28.8633C30.9815 28.8633 32.186 29.3622 33.074 30.2502C33.962 31.1383 34.4609 32.3427 34.4609 33.5986C34.4605 34.8543 33.9615 36.0585 33.0735 36.9465C32.1856 37.8344 30.9814 38.3335 29.7256 38.3339"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReturnIcon;
