import cn from '@ui/utils/cn';

const Spinner = ({ className }: { className?: string }) => (
  <div
    className={cn(
      'size-8 rounded-full align-[-0.125em] text-primary m-auto text-[#EEBFD4]',
      'border-4 border-solid border-current border-r-transparent',
      'animate-spin motion-reduce:animate-[spin_1.5s_linear_infinite]',
      className,
    )}
    role="status"
  >
    <span className="sr-only">Loading...</span>
  </div>
);

export default Spinner;
