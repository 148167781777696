import React from 'react';
import {
  CloseButtonProps,
  DrawerBodyProps,
  DrawerContentProps,
  DrawerHeaderProps,
  DrawerOverlayProps,
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
} from '@mantine/core';

type DrawerProps = Omit<MantineDrawerProps, 'title'> & {
  header?: React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
  overlayProps?: DrawerOverlayProps;
  contentProps?: DrawerContentProps;
  headerProps?: DrawerHeaderProps;
  closeButtonProps?: CloseButtonProps;
  bodyProps?: DrawerBodyProps;
};

const Drawer = ({
  header,
  body,
  footer,
  withOverlay,
  withCloseButton,
  overlayProps,
  contentProps,
  headerProps,
  closeButtonProps,
  bodyProps,
  ...props
}: DrawerProps) => {
  return (
    <MantineDrawer.Root trapFocus={!withOverlay} {...props}>
      {withOverlay && <MantineDrawer.Overlay {...overlayProps} />}
      <MantineDrawer.Content {...contentProps}>
        {(header || withCloseButton) && (
          <MantineDrawer.Header {...headerProps}>
            {header && <MantineDrawer.Title>{header}</MantineDrawer.Title>}
            {withCloseButton && <MantineDrawer.CloseButton {...closeButtonProps} />}
          </MantineDrawer.Header>
        )}
        <MantineDrawer.Body {...bodyProps}>{body}</MantineDrawer.Body>
        {footer}
      </MantineDrawer.Content>
    </MantineDrawer.Root>
  );
};

Drawer.defaultProps = {
  header: undefined,
  footer: undefined,
  overlayProps: undefined,
  contentProps: undefined,
  headerProps: undefined,
  closeButtonProps: undefined,
  bodyProps: undefined,
};

export default Drawer;
