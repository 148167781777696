import { gql } from '../../__generated__/gql';

export const CUSTOMER_ACCESS_TOKEN_DELETE = gql(`
  mutation customerAccessTokenDelete ($customerAccessToken: String!) {
    customerAccessTokenDelete (customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`);
