import RunMutation from '../../utils/RunMutation.ts';
import { CART_CREATE } from '../../documents/mutations';
import { CartCreateMutation, CartCreateMutationVariables } from '../../__generated__/graphql';
import { mapCartData } from '../../utils/MapCartData.ts';

type CartCreateArgs = CartCreateMutationVariables & {
  buyerIP: string | undefined;
};

export async function CartCreate({ cartCreateInput, buyerIP }: CartCreateArgs) {
  const { data, error } = await RunMutation<CartCreateMutation>(
    CART_CREATE,
    {
      cartCreateInput: cartCreateInput,
    },
    buyerIP,
  );

  if (error) {
    return { cart: undefined, error };
  }

  if (!data?.cartCreate?.cart) {
    return { cart: undefined, error: { message: 'Cart not found' } };
  }

  const cartData = data.cartCreate.cart;

  const cart = mapCartData(cartData);

  return { cart, error };
}
