import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { GetCustomer, GetCustomerOrders } from '@client-shopify/gql/storefront/api/queries';
import { createSelectors } from './selectors';
import { useCallback } from 'react';

interface PersistAuthState {
  token: string | null;
  setToken: (token: string | null) => void;
}

interface AuthState {
  customer: Awaited<ReturnType<typeof GetCustomer>>['data'];
  orders: Awaited<ReturnType<typeof GetCustomerOrders>>['data'] | [];
  isLoggedIn: boolean;
  customerId: string | undefined;
  login: (token: string | null) => void;
  logout: () => void;
}

const usePersistantAuthStore = create<PersistAuthState>()(
  persist(
    (set) => ({
      token: null,
      setToken: (token) => set({ token }),
    }),
    { name: 'auth' },
  ),
);

const useAuthStore = create<AuthState>((set) => ({
  customer: {},
  orders: [],
  isLoggedIn: false,
  customerId: undefined,
  logout: () => {
    set({ isLoggedIn: false });
    set({ customerId: undefined });
  },
  login: (token) => {
    GetCustomer({ customerAccessToken: token ?? '' }).then((res) => {
      if (res.error) {
        throw new Error('Invalid token');
      }
      if (token) {
        (async () => {
          const orders = await GetCustomerOrders({ customerAccessToken: token }).then((res) => res?.data);
          if (!orders) {
            set({ orders: [] });
          }
          set({ orders });
        })();
      }
      if (res?.data?.customer?.id) {
        const customerId = res.data.customer.id.split('/', -1).pop();
        set({ isLoggedIn: true });
        set({ customerId });
        set({ customer: res.data });
      }
    });
  },
}));

export const authSelectors = {
  persistantAuth: createSelectors(usePersistantAuthStore),
  auth: createSelectors(useAuthStore),
};

export const useSetToken = () => {
  const setToken = usePersistantAuthStore((state) => state.setToken);
  const setTokenCallback = useCallback(
    (token: string | null) => {
      setToken(token);
    },
    [setToken],
  );
  return { setTokenCallback };
};
