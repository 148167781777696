import { MantineTheme } from '@mantine/core';

const textInput = {
  styles: (theme: MantineTheme) => ({
    input: {
      background: 'transparent',
      borderRadius: '0.13rem',
      border: `0.0625rem solid ${theme.black}`,
      '&:focus': {
        border: `0.0625rem solid ${theme.black}`,
      },
    },
    root: {
      input: {
        // letterSpacing: '0.05rem',
        // '&::placeholder': {
        //   letterSpacing: '0.05rem',
        // },
      },
    },
  }),
};

export default textInput;
