import type { MantineTheme } from '@mantine/core';

const numberInput = {
  styles: (theme: MantineTheme) => ({
    // input: {
    //   textAlign: 'center',
    //   border: `0.0625rem solid ${theme.black}`,
    //   '&:focus': {
    //     border: `0.0625rem solid ${theme.black}`,
    //   },
    // },

    input: {
      // textAlign: 'center',
      // letterSpacing: '0.1rem',
      borderRadius: '0.13rem',
      border: `0.0625rem solid ${theme.black}`,
      '&:placeholder': {
        letterSpacing: '0.05rem',
      },
      '&:focus': {
        border: `0.0625rem solid ${theme.black}`,
      },
    },
  }),
};

export default numberInput;
