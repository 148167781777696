import { gql } from '../../__generated__/gql';

export const GET_COLLECTION = gql(`
  query getCollection ($handle: String!, $first: Int, $last: Int, $after: String, $before: String, $sortKey: ProductCollectionSortKeys) {
    collection (handle: $handle) {
      id
      handle
      title
      description
      descriptionHtml
      image {
        url
        width
        height
        altText
      }
      products (first: $first, last: $last, after: $after, before: $before, sortKey: $sortKey) {
        edges {
          cursor
          node {
            id
            handle
            title
            vendor
            productType
            availableForSale
            tags
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images (first: 6) {
              edges {
                node {
                  id
                  url
                  width
              		height
									altText
								}
							}
						}
						variants (first: 6) {
							edges {
								node {
									id
                  title
                  quantityAvailable
									availableForSale
									price {
                    amount
                    currencyCode
                  }
									compareAtPrice {
										amount
										currencyCode
								}
								}
							}
						}
					}
				}
				pageInfo {
					hasNextPage
					startCursor
					hasPreviousPage
					endCursor
				}
			}
    }
  }
`);
