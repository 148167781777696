import { gql } from '../../__generated__/gql';

export const GET_COLLECTIONS = gql(`
  query getCollections ($first: Int) {
    collections (first: $first) {
      edges {
        cursor
        node {
          id
          title
          handle
          description
          descriptionHtml
          image {
            id
            url
            altText
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        hasPreviousPage
        endCursor
      }
    }
  }
`);
