import React from 'react';

export default function ExpressDeliveryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6841_89315)">
        <path
          d="M20.4531 13.6836V12.2773L18.2031 10.3086H15.9531"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.76562 17.0586C10.3869 17.0586 10.8906 16.5549 10.8906 15.9336C10.8906 15.3123 10.3869 14.8086 9.76562 14.8086C9.1443 14.8086 8.64062 15.3123 8.64062 15.9336C8.64062 16.5549 9.1443 17.0586 9.76562 17.0586Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2031 17.0586C18.8244 17.0586 19.3281 16.5549 19.3281 15.9336C19.3281 15.3123 18.8244 14.8086 18.2031 14.8086C17.5818 14.8086 17.0781 15.3123 17.0781 15.9336C17.0781 16.5549 17.5818 17.0586 18.2031 17.0586Z"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0781 15.9336H10.8906M8.64062 15.9336H7.51562V13.6836M7.51562 13.6836H20.4531V15.9336H19.3281M7.51562 13.6836V7.49609H15.9531V13.6836M3.57812 8.90234H6.10938M5.40625 12.6992H5.96875M4.14062 10.8976H6.10938"
          stroke="#0D0005"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_89315">
          <rect width="18" height="18" fill="white" transform="translate(3.01562 3.27734)" />
        </clipPath>
      </defs>
    </svg>
  );
}
