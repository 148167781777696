import React from 'react';
import Link, { LinkProps } from 'next/link';
import { linkStyles } from '../../styles';

type Variant = 'underline' | 'highlight' | 'none';

type NextLinkProps = LinkProps & {
  children?: React.ReactNode;
  label?: string;
  variant?: Variant;
  className?: string;
  color?: string;
  bold?: boolean;
  uppercase?: boolean;
  style?: React.CSSProperties;
  target?: string;
};

const NextLink = ({
  children,
  label,
  variant,
  className,
  color,
  bold,
  uppercase,
  target,
  ...props
}: NextLinkProps): React.ReactElement => {
  const { classes } = linkStyles({ color, bold, uppercase });

  const formattedClassName = className ? className : '';

  const styles = function getStyles(): string {
    switch (variant) {
      case 'underline': {
        return `${formattedClassName} ${classes.underline}`;
      }
      case 'highlight': {
        return `${formattedClassName} ${classes.highlight}`;
      }
      default: {
        return `${formattedClassName} ${classes.noDecoration}`;
      }
    }
  };

  return (
    <Link className={styles()} target={target} {...props}>
      {children || label}
    </Link>
  );
};

NextLink.defaultProps = {
  label: undefined,
  children: undefined,
  variant: 'none',
  className: undefined,
  color: undefined,
  bold: false,
  uppercase: false,
  style: undefined,
  target: undefined,
};

export default NextLink;
