import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_CHECKOUT_URL } from '../../documents/queries';
import type { GetCheckoutUrlQuery, GetCheckoutUrlQueryVariables } from '../../__generated__/graphql';

export async function GetCheckoutUrl({
  cartId,
}: GetCheckoutUrlQueryVariables): Promise<{ data: GetCheckoutUrlQuery; error: CombinedError | undefined }> {
  const { data, error } = await RunQuery(GET_CHECKOUT_URL, {
    cartId: cartId,
  });

  return { data, error };
}
