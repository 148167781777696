import React from 'react';
import NextImage from '../../NextImage/NextImage';

type AUFlagIconProps = {
  height: number;
  width: number;
};

const AUFlagIcon = ({ height, width }: AUFlagIconProps) => (
  <NextImage src={'/region/AUFlag.svg'} alt="Hello Molly AU Flag Icon" height={height} width={width} />
);

export default AUFlagIcon;
