import React from 'react';
import { Accordion, NextLink, Prose } from '@ui/components/core';
import FAQAccordion from '../FAQAccordion/FAQAccordion';

const FAQProductAndStock = (): React.ReactElement => (
  <FAQAccordion handle="product-and-stock">
    <Accordion.Item value="alterations">
      <Accordion.Control>Alterations</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>Hello Molly offers product sizes as is and does not offer an altering service.</p>
          <p>
            As we stock our products from a range of suppliers and brands we are unfamiliar with whether certain
            products would be suitable for alterations, we advise our customers to seek expert opinion from a skilled
            tailor or dressmaker for information in this area.
          </p>
          <p>
            Please be advised that we do not accept garments which have been physically altered in any way, all returns
            must meet all the conditions of our Returns Policy{' '}
            <NextLink label="here" href="/pages/returns/" target="_blank" /> to be accepted by our Returns Department.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="cancellation">
      <Accordion.Control>Cancellation</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          We reserve the right to cancel any order if we are unable to provide the requested product. In these rare
          instances, you&apos;ll be contacted immediately and given a full refund.
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="item-appearance">
      <Accordion.Control>Item appearance</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            Different computers have different screen resolutions so it&apos;s possible the colours, particularly neon
            tones, will be slightly different in real life from what you see on your screen. That said, we try very hard
            to represent the garments perfectly when taking photos. We emphasise any special features and try to
            actively show how the fabric sits and moves.
          </p>
          <p>
            Please note that loose or excess threads and irregular prints are not considered faults as these are often
            natural results of the manufacturing process. Please read the description of each item carefully before
            purchasing.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="layby-or-hold">
      <Accordion.Control>Lay-by or hold</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          We offer various payment methods to allow you to pay in installments. We do not offer holds. No product is
          reserved for you until you complete the ordering process.
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="new-arrivals">
      <Accordion.Control>New arrivals</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          Hello Molly releases approximately 100 NEW styles every weekend so be sure to keep an eye on (or bookmark) our
          New Arrivals page <NextLink label="here" href="/collections/new/" target="_blank" /> every week for our newest
          additions.
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="restock-notification">
      <Accordion.Control>Restock notification received but out of stock</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            Changes in our inventory levels are automatically reflected on our website and this includes items that have
            been returned to us from our customers so you may have received an e-mail notifying that it&apos;s been
            restocked, however, it does not necessarily mean that we have restocked the item in all sizes.
          </p>
          <p>
            It is possible that the item in the size that you wanted was restocked, for example, 1 was returned so 1 is
            available for purchase on our site, and it may have already been bought by someone else on the same restock
            mailing list before you were able to buy it so it shows that it&apos;s out of stock when you visit the page
            later.
          </p>
          <p>
            If you see an item that is clearly in stock on the product page but you can&apos;t add it to your cart
            contact us straightaway at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> and we&apos;ll help
            as best we can.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="restocking">
      <Accordion.Control>Restocking</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            We restock our items on a regular basis but this depends on the availability from our suppliers, therefore,
            we cannot give you an exact answer as to when and whether we will restock certain items.
          </p>
          <p>
            We suggest subscribing to the item you&apos;re after to be notified when your item or size comes back in
            stock. Remember to act quick when you get the email because everyone else on the notification list also gets
            the email!
          </p>
          <p>
            If you&apos;re looking to purchase the product for an upcoming event, we suggest that you look for an
            alternative to avoid disappointment.
          </p>
          <p>
            In certain instances, we may be able to provide you with a restock date so if you&apos;re particularly
            smitten with an item, email us at <a href="mailto:info@hellomolly.com">info@hellomolly.com</a> and
            we&apos;ll help as best we can.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="sizing">
      <Accordion.Control>Sizing</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            We know it can be quite tough shopping online, particularly when it comes to sizing. You can find sizing
            information on each product page as well as our main size guide{' '}
            <NextLink label="here" href="/pages/size-guide/" target="_blank" />.
          </p>
          <p>
            We also suggest comparing your size to our models for an idea of the size and fit of our products by
            visiting the model information page <NextLink label="here" href="/pages/size-guide/" target="_blank" />.
          </p>
          <p>
            If you have further questions about the sizing of a particular garment, email us at{' '}
            <a href="mailto:info@hellomolly.com">info@hellomolly.com</a>.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="smaller-or-larger-sizes">
      <Accordion.Control>Smaller or larger sizes (XXS, XL, XXL)</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>Our products are based on Australian sizing and we mainly stock sizes 6 (XS) to 12 (L).</p>
          <p>
            We try to stock our clothing to accommodate all sizes but can be limited by supplier availability and the
            design of the products.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="wishlist">
      <Accordion.Control>Wishlist</Accordion.Control>
      <Accordion.Panel>
        <Prose size="small">
          <p>
            If you really like an item but aren&apos;t ready to buy quite yet, please create an account and set up your
            own wishlist. This will let you keep track of your favourite Hello Molly products.
          </p>
          <p>
            Please note this does not save or hold the items and they are still subject to being sold out before you
            purchase them.
          </p>
        </Prose>
      </Accordion.Panel>
    </Accordion.Item>
  </FAQAccordion>
);

export default FAQProductAndStock;
