import React from 'react';
import NextImage from '../../NextImage/NextImage';

type GiftCardIconProps = {
  height: number;
  width: number;
};

const GiftCardIcon = ({ height, width }: GiftCardIconProps): React.ReactElement => (
  <NextImage src={'/common/giftCardIcon.svg'} alt="Hello Molly Gift Card Icon" height={height} width={width} />
);

export default GiftCardIcon;
