const card = {
  styles: {
    root: {
      borderRadius: 0,
    },
  },
};

export default card;

// export const cardStyles = createStyles(() => ({
//   slideWrapper: {
//     position: 'relative',
//     overflow: 'hidden',
//     width: '100%',
//     ':hover *': {
//       bottom: 0,
//     },
//   },
//   slideContent: {
//     position: 'absolute',
//     background: 'rgba(255, 255, 255, 0.8)',
//     width: '100%',
//     padding: '1rem 0.5rem 1rem 0.5rem',
//     bottom: '-100%',
//     transition: '250ms',
//   },
// }));
