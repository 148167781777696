export const CatalogLabels: Array<string> = [
  'NEW',
  'DRESSES',
  'PLAYSUITS',
  'BACK IN STOCK',
  'TOPS',
  'BOTTOMS',
  'SALE',
  'SWIM',
  'ACTIVEWEAR',
  'ACCESSORIES',
  'SELF LOVE',
  'SHOES',
];

export enum CatalogImages {
  'NEW' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/0H1A4060_1721364360.jpg?v=1721605454',
  'DRESSES' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/L4A1166_1652343103_82774b25-7c9b-4fc1-ad97-2306a79a8e80.jpg?v=1721867728',
  'PLAYSUITS' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/0H1A4426_1721363842.jpg?v=1721363873',
  'BACK IN STOCK' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/L4A1166_1652343103_82774b25-7c9b-4fc1-ad97-2306a79a8e80.jpg?v=1721867728',
  'TOPS' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/14ca6a7e9e09949f9553.jpg?v=1721868075',
  'BOTTOMS' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/39A6809_1721621254.jpg?v=1721621289',
  'SALE' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/products/39A3641_1704413296_14c99f7b-d1fe-4978-a82b-a6dd8e69b513.jpg?v=1704665631',
  'SWIM' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/0H1A3119_1720405406_a0d6ea12-1515-46f5-8dbb-12981ce0d714.jpg?v=1721258158',
  'ACTIVEWEAR' = '',
  'ACCESSORIES' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/E00511GLD-goldpinkmix_201_1719989972_c17df722-9fda-4345-8cde-9f06d9a30ffe.jpg?v=1721406419',
  'SELF LOVE' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/surfer_handshot_greybackground_7cc57cb7-2d2d-4730-ab2c-2968c2760b0a.jpg?v=1716960246',
  'SHOES' = 'https://cdn.shopify.com/s/files/1/0281/2071/1254/files/0H1A4731_1720763973.jpg?v=1720796507',
}

export const CatalogLabelsFeatured: Array<string> = ['DRESSES', 'TOPS', 'SHOES', 'ACCESSORIES', 'SWIM', 'EXCLUSIVE'];

export enum CatalogImagesFeatured {
  'DRESSES' = 'https://cdn.shopify.com/s/files/1/0771/4347/4482/files/0L4A0416_1668123148_1080x_bedbbe07-30fc-4730-95c5-e72402f7c7b1.jpg?v=1713165513',
  'TOPS' = 'https://cdn.shopify.com/s/files/1/0771/4347/4482/files/top.jpg?v=1713165570',
  'SHOES' = 'https://cdn.shopify.com/s/files/1/0771/4347/4482/files/Shoes.jpg?v=1713165605',
  'ACCESSORIES' = 'https://cdn.shopify.com/s/files/1/0771/4347/4482/files/Y2A3153_1704156634_1080x_4905113b-ab69-4b48-b459-40157e593656.jpg?v=1713165646',
  'SWIM' = 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Hello_Molly_January_2023_Cathlin_-1871.jpg?v=1720160573',
  'EXCLUSIVE' = 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/AA6I0097.jpg?v=1720160590',
}
