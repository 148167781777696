import { MantineTheme, createStyles } from '@mantine/core';

const backgroundStyles = createStyles((theme: MantineTheme) => ({
  themeLight: {
    background: theme.colors.brand[1],
  },
  themeMedium: {
    background: theme.colors.brand[2],
  },
  theme: {
    background: theme.colors.brand[3],
  },
  themeDark: {
    background: theme.colors.brand[5],
  },
  fadeToLeft: {
    background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))`,
  },
  fadeToBottom: {
    background: `linear-gradient(1.71deg, #FFFFFF 11.15%, rgba(255, 255, 255, 0) 74.12%)`,
  },
  fadeToBottomToLeft: {
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)),
                 linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))`,
  },
}));

export default backgroundStyles;
