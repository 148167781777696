import { MantineTheme } from '@mantine/core';

const checkbox = {
  styles: (theme: MantineTheme) => ({
    label: {
      fontSize: '0.75rem',
    },
    input: {
      border: `0.0625rem solid ${theme.black}`,
    },
  }),
};

export default checkbox;
