import { gql } from '../../__generated__/gql';

export const GET_PRODUCTS_PRICING = gql(`
  query getProductsPricing($first: Int!, $query: String!, $country: CountryCode!) @inContext(country: $country) {
    products(first: $first, query: $query) {
      edges {
        node {
          id
          handle
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`);
