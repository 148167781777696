import { gql } from '../../__generated__/gql';

export const CUSTOMER_RECOVER = gql(`
  mutation customerRecover ($email: String!) {
    customerRecover (email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`);
