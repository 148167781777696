import { gql } from '../../__generated__/gql';

export const GET_BLOG = gql(`
  query getBlog($handle: String!, $first: Int, $cursor: String, $query: String) {
    blog(handle: $handle) {
      id
      handle
      title
      seo {
        title
        description
      }
      articles(first: $first, after: $cursor, reverse: true, query: $query) {
        edges {
          cursor
          node {
            id
            handle
            title
            publishedAt
            excerpt
            content
            tags
            image {
              url
              altText
            }
            seo {
              title
              description
            }
          }
        }
      }
    }
  }
`);
