import React from 'react';

const WishlistIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.177 9.38528C20.1772 10.0643 20.1774 11.6021 19.1595 13.5573C17.8994 15.9781 15.552 18.1573 12.1769 20.0427C8.80185 18.1573 6.45462 15.9777 5.19435 13.5573C4.17663 11.6017 4.17682 10.0641 4.17701 9.40739C4.17701 6.99071 6.117 5.04272 8.50166 5.04272C9.52743 5.04272 10.5218 5.40553 11.3014 6.06427L12.1767 6.80345L13.052 6.06427C13.8321 5.40535 14.8264 5.04272 15.8525 5.04272C18.237 5.04272 20.177 6.99071 20.177 9.38528Z"
      stroke="black"
    />
  </svg>
);

WishlistIcon.defaultProps = {
  onClick: undefined,
};

export default WishlistIcon;
