import React from 'react';

const StoreCreditIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.0678 18.8747C26.9052 17.9135 26.4075 17.0409 25.6629 16.4117C24.9182 15.7825 23.9749 15.4374 23 15.4375C20.722 15.4375 18.875 16.94 18.875 19.2186C18.875 21.4971 20.9375 22.5289 23 23.0002C25.0625 23.4642 27.125 24.5042 27.125 26.7818C27.125 29.0593 25.278 30.5628 23 30.5628C21.906 30.5628 20.8568 30.1282 20.0832 29.3547C19.3096 28.5811 18.875 27.5319 18.875 26.4378"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 15.4384V12.6875M23 33.314V30.5632M23 8.80588L26.0767 7.53125L28.4321 9.88611L31.7625 9.88662L33.0366 12.9634L36.1144 14.2385V17.5684L38.4688 19.9233L37.1946 23L38.4688 26.0772L36.1144 28.4326V31.7625L33.0366 33.0372L31.7625 36.1139L28.4321 36.1144L26.0767 38.4698L23 37.1952L19.9233 38.4698L17.5679 36.1144L14.2375 36.1139L12.9634 33.0372L9.88559 31.7625V28.4326L7.53125 26.0772L8.80536 23L7.53125 19.9233L9.88559 17.5684V14.2385L12.9634 12.9634L14.2375 9.88662L17.5679 9.88611L19.9233 7.53125L23 8.80588Z"
      stroke="#0D0005"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StoreCreditIcon;
